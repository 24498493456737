import React from 'react'
import { FilterOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Collapse, Select, Input, DatePicker, Card, Modal, Table, Pagination, Button, Descriptions, Progress, Tooltip, Popover, Space } from 'antd';
import DataTable from "react-data-table-component";
import { useState, useRef, useEffect, Fragment } from 'react';
import { Link, useLocation } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import { connect } from 'react-redux';
import { ExclamationCircleTwoTone, UpOutlined, PlayCircleOutlined, FullscreenOutlined, ExportOutlined, EyeOutlined } from '@ant-design/icons';
import moment from 'moment';
import { getData, getStatutoryUnitwiseReportData, getStatutoryUnitwiseReportExport } from '../../../Store/Action/Report/StatutoryUnitWiseReports';
import ScrollToTop from "react-scroll-to-top";
import { fullscreenmode, fullscreenexit, handleKeyDown } from './../../../Libs/fullscreenmode';


const mapStateToProps = (state) => ({
    allData: state.StatutoryUnitWiseReport.statutoryUnitWiseReport.data,
    tableData: state.StatutoryUnitWiseReport.statutoryUnitWiseReport.getTableData,
    // downloadedPath:state.RiskReport.riskReport.downloadPath
})

const UnitWiseReports = ({
    getData,
    getStatutoryUnitwiseReportData,
    getStatutoryUnitwiseReportExport,
    allData,
    tableData,
    downloadedPath,
}) => {

    const _ = require("lodash");
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArr = sessionParsedValue.entity_info
    const [getreportlist, setGetReportList] = useState([])
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const location = useLocation();
    const paramid = location.pathname;
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [tableshow, setTableShow] = useState(false)
    const [key, setKey] = useState("1")
    const [statkey, setStatKey] = useState("0")
    const [titledata, Settitledata] = useState()
    const [current, setCurrent] = useState(1);
    const [pageState, setpageState] = useState(false);
    const [pageSize, setPageSize] = useState(25)
    const [childCompliceId, setChildComplinaceId] = useState([])
    let [index, setIndex] = useState(0)
    const [uniqueData, setUniqueData] = useState([])
    const [valueModal, setValueModal] = useState({
        concurrer_name: '',
        Approver: ''
    })
    const [allCheckBoxState, setAllCheckBoxState] = useState(false)
    const [filteredArray, setFilteredArray] = useState([])
    const [filterTaskAcc, setFilterTaskAcc] = useState(true);
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const [exitCollapse, setExitCollapse] = useState(false);
    const [filteredArrayState, setfilteredArrayState] = useState([])
    const [consolidateddata, setConsolidatedDate] = useState({
        country: "",
        category: "",
        user_type: "",
        legel_entity: "",
        legel_entity_name: "",
        unit: '',
        user: "",
        domain: "",
        act: "",
        from_date: "",
        task_category: "",
        compliance_task: "",
        to_date: "",
        task_sub_category: "",
        compliance_frequency: "All",
        compliance_task_status: "All",
        division: "",
        domainName: '',
        countryName: '',
        unit_name: '',
        user_name: '',
        act_name: '',
        division_name: '',
        compliance_frequency_name: '',
        compliance_task_status_name: '',
        category_name: '',
        user_type_name: '',
        task_status: ''

    })
    const validator = useRef(new SimpleReactValidator());
    const [exportButton, setExportButton] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [domainList, setDomainList] = useState([])
    const [divisionList, setDivisionList] = useState([])
    const [unitCodeLabelList, setUnitCodeLabelList] = useState([])
    const [unitLegalEntity, setUnitLegalEntity] = useState([])
    const [complianceUser, setComplianceUser] = useState([])
    const [complianceUserType, setComplianceUserType] = useState([])
    const [complianceTaskStatus, setComplianceTaskStatus] = useState([])
    const [complianceFrequencyList, setComplianceFrequencyList] = useState([])
    const [categoryList, setCategoryList] = useState([])
    const [actLegalEntity, setActLegalEntity] = useState([])
    const [actName, setActName] = useState([])
    const [actNameFilteredState, setActNameFilteredState] = useState([])
    const [finalResult, SetfinalResult] = useState({
        Resultdata: []
    })
    const [showValueModal, setShowValueModal] = useState(false)
    const country_info = sessionParsedValue.country_info
    const SelectedEntity = localStorage.getItem('SelectedEntity')
    const [businessGroup, setBusinessGroup] = useState({
        bg_id: '',
        bg_name: ''
    })
    const [checkboxValue, setCheckBoxValue] = useState([0, 1, 2, 3, 4, 9, 24])
    const setcancelFilter = () => {
        setFilterModalVisible(false);
        setFilterTaskAcc(false);
      };

    const { Panel } = Collapse;
    const { Option } = Select;
    const columns = [
        {
            title: 'User Name',
            ellipsis: true,
            // width: '30px',
            dataIndex: 'user_name',
            key: 'user_name',
            align: 'center'
        },
        {
            title: 'Due Date',
            ellipsis: true,
            // width: '30px',
            dataIndex: 'due_date',
            key: 'due_date',
            align: 'center'

        },


    ];
    useEffect(() => {
        if (pageState == true) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetStatutorySettingsUnitWise",
                        {
                            "c_id": localStorage.getItem('SelectedEntity') !== "All Legal Entity" ? sessionArr && sessionArr[0].c_id : Number(consolidateddata.country),
                            "bg_id": businessGroup.bg_id ? Number(businessGroup.bg_id) : null,
                            "le_id": entityid,
                            "d_id": Number(consolidateddata.domain),
                            "unit_id": consolidateddata.unit ? Number(consolidateddata.unit) : null,
                            "div_id": consolidateddata.division ? Number(consolidateddata.division) : null,
                            "cat_id": consolidateddata.category ? Number(consolidateddata.category) : null,
                            "parent_id": consolidateddata.act ? Number(consolidateddata.act) : 0,
                            "compliance_task": consolidateddata.compliance_task ? Number(consolidateddata.compliance_task) : null,
                            "frequency_id": consolidateddata.compliance_frequency ? Number(consolidateddata.compliance_frequency) : 0,
                            "status_name": consolidateddata.compliance_task_status,
                            "csv": false,
                            "f_count": Math.max(((current - 1) * pageSize), 0) + 1,
                            "t_count": current != 0 ? current * pageSize : pageSize,
                            "count_qry": true,
                            "list_check": null
                        }
                    ]

                }

            ]
            getStatutoryUnitwiseReportData({
                payload: payload,
                paramid: paramid
            })
        }
    }, [current, pageSize, pageState])
    useEffect(() => {
        let tempArr = []
        if (tableData && tableData.statutory_settings_unit_wise_list && tableData.statutory_settings_unit_wise_list.length > 25) {
            filteredArrayState && filteredArrayState.length > 0 && filteredArrayState.map((item, i) => {
                let uniqueObjArray = [...new Map(item && item.length && item.map((items) => [items["compliance_id"], items])).values()];
                const uniqueIds = uniqueObjArray && uniqueObjArray.map((itemData) => {
                    return itemData.compliance_id
                })

                for (let i in uniqueIds) {
                    let filteredArr = _.find(item && item.length && item, { compliance_id: uniqueIds[i] })
                    tempArr.push(filteredArr)
                }
            })

            setUniqueData(tempArr)

        }
    }, [filteredArrayState])

    useEffect(() => {
        if (pageState === true) {
            setIndex(pageSize * (current - 1))
        }
    }, [pageState, pageSize, current])


    useEffect(() => {
        let array = [];
        let finalarray = [];
        let datavalue = [];
        if (tableData && tableData.statutory_settings_unit_wise_list && tableData.statutory_settings_unit_wise_list.length > 25) {
            // setTempeditdata(editdata)
            if (uniqueData && uniqueData.length > 0) {
                const ab = uniqueData && uniqueData.length && uniqueData.map(function (item) {
                    if ((array.indexOf(item.act_name) < 0))
                        array.push(item.act_name)
                })

                array.map((data, i) => {
                    let temp = [];
                    let entityArray = [];
                    let datavalue = [];
                    if (data) {
                        uniqueData && uniqueData.length && uniqueData.map((child, childIndex) => {
                            if (data === child.act_name) {
                                entityArray = {
                                    "act_name": child.act_name,
                                    "approver_name": child.approver_name,
                                    "assignee_name": child.assignee_name,
                                    "category_name": child.category_name,
                                    "compliance_description": child.compliance_description,
                                    "compliance_id": child.compliance_id,
                                    "compliance_task": child.compliance_task,
                                    "concurrer_name": child.concurrer_name,
                                    " criticality_name": child.criticality_name,
                                    "division_name": child.division_name,
                                    " document_name": child.document_name,
                                    "download_url": child.download_url,
                                    "due_date": child.due_date,
                                    " extend_due_date": child.extend_due_date,
                                    "frequency": child.frequency,
                                    "task_status": child.task_status,
                                    " tz_name": child.tz_name,
                                    "unit": child.unit,
                                    "unit_address": child.unit_address,
                                    "unit_id": child.unit_id,
                                    "user_name": child.user_name,
                                    'childId': childIndex,
                                    'parentId': i
                                }
                                temp.push(entityArray)
                            }
                        })
                    }

                    datavalue = {
                        index: i,
                        parent: data,
                        child: temp
                    }
                    finalarray.push(datavalue)
                })
                Settitledata(finalarray)

            }
        }
        else {

            if (tableData && tableData.statutory_settings_unit_wise_list && tableData.statutory_settings_unit_wise_list.length > 0) {
                const ab = tableData && tableData.statutory_settings_unit_wise_list && tableData.statutory_settings_unit_wise_list.length && tableData.statutory_settings_unit_wise_list.map(function (item) {
                    if ((array.indexOf(item.act_name) < 0))
                        array.push(item.act_name)
                })

                array.map((data, i) => {
                    let temp = [];
                    let entityArray = [];
                    let datavalue = [];
                    if (data) {
                        tableData && tableData.statutory_settings_unit_wise_list && tableData.statutory_settings_unit_wise_list.length && tableData.statutory_settings_unit_wise_list.map((child, childIndex) => {
                            if (data === child.act_name) {
                                entityArray = {
                                    "act_name": child.act_name,
                                    "approver_name": child.approver_name,
                                    "assignee_name": child.assignee_name,
                                    "category_name": child.category_name,
                                    "compliance_description": child.compliance_description,
                                    "compliance_id": child.compliance_id,
                                    "compliance_task": child.compliance_task,
                                    "concurrer_name": child.concurrer_name,
                                    " criticality_name": child.criticality_name,
                                    "division_name": child.division_name,
                                    " document_name": child.document_name,
                                    "download_url": child.download_url,
                                    "due_date": child.due_date,
                                    " extend_due_date": child.extend_due_date,
                                    "frequency": child.frequency,
                                    "task_status": child.task_status,
                                    " tz_name": child.tz_name,
                                    "unit": child.unit,
                                    "unit_address": child.unit_address,
                                    "unit_id": child.unit_id,
                                    "user_name": child.user_name,
                                    'childId': childIndex,
                                    'parentId': i
                                }
                                temp.push(entityArray)
                            }
                        })
                    }

                    datavalue = {
                        index: i,
                        parent: data,
                        child: temp
                    }
                    finalarray.push(datavalue)
                })
                Settitledata(finalarray)

            }

        }

    }, [tableData && tableData.statutory_settings_unit_wise_list, uniqueData])
    useEffect(() => {
        if (tableData && tableData.statutory_settings_unit_wise_list && tableData.statutory_settings_unit_wise_list.length > 0) {
            let uniqueObjArray = [...new Map(tableData && tableData.statutory_settings_unit_wise_list && tableData.statutory_settings_unit_wise_list.length && tableData.statutory_settings_unit_wise_list.map((item) => [item["unit_id"], item])).values()];
            const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                return item.unit_id
            })

            let tempArr = []
            for (let i in uniqueIds) {
                let filteredArr = _.filter(tableData && tableData.statutory_settings_unit_wise_list && tableData.statutory_settings_unit_wise_list.length && tableData.statutory_settings_unit_wise_list, { unit_id: uniqueIds[i] })

                tempArr.push(filteredArr)
            }
            setFilteredArray([...filteredArray, tempArr])

        }

    }, [tableData && tableData.statutory_settings_unit_wise_list])
    useEffect(() => {
        if (filteredArray.length) {
            let temp = []
            for (let i in filteredArray) {
                temp = filteredArray[i]
            }
            setfilteredArrayState([...temp])
        }

    }, [filteredArray])
    useEffect(() => {
        if (entityid === null) {

            setCurrentEntity(consolidateddata.legel_entity)
        }
    }, [entityid])
    useEffect(() => {
        validator.current.showMessages()
    }, [])
    useEffect(() => {
        if (entityid === null) {

            setCurrentEntity(consolidateddata.legel_entity)
        }
    }, [entityid])
    useEffect(() => {
        if (SelectedEntity != 'All Legal Entity') {
            let businessName = _.filter(sessionArr, { le_name: SelectedEntity })
            setBusinessGroup({
                ...businessGroup,
                bg_id: businessName && businessName.length > 0 && businessName[0].bg_id,
                bg_name: businessName && businessName.length > 0 && businessName[0].bg_name
            })
        }
    }, [SelectedEntity])

    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetStatutorySettingsUnitWiseFilters",
                        {
                            "le_id": entityid
                        }
                    ]
                }
            ]
            if (entityid != 'null') {
                getData({
                    payload: payload,
                    paramid: paramid
                })
            }
        }
    }, [isAuth, authtoken, businessGroup, paramid, entityid])

    useEffect(() => {
        if (allData) {
            setActLegalEntity(allData && allData.acts)
            setCategoryList(allData && allData.cat_infos)
            setComplianceFrequencyList(allData && allData.compliance_frequency)
            setUnitLegalEntity(allData && allData.units)
            setDivisionList(allData && allData.div_infos)
            setDomainList(allData && allData.domains)
        }

    }, [allData])

    const onshow = () => {
        setExitCollapse(true);
        setFilterModalVisible(false);
        setFilterTaskAcc(false);
        setAddFormSubmit(true)
        Settitledata([])
        setfilteredArrayState([])
        if (validator.current.allValid()) {
            setTableShow(true)
            setKey(0)
            setStatKey(1)
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetStatutorySettingsUnitWise",
                        {
                            "c_id": localStorage.getItem('SelectedEntity') !== "All Legal Entity" ? sessionArr && sessionArr[0].c_id : Number(consolidateddata.country),
                            "bg_id": businessGroup.bg_id ? Number(businessGroup.bg_id) : null,
                            "le_id": entityid,
                            "d_id": Number(consolidateddata.domain),
                            "unit_id": consolidateddata.unit ? Number(consolidateddata.unit) : null,
                            "div_id": consolidateddata.division ? Number(consolidateddata.division) : null,
                            "cat_id": consolidateddata.category ? Number(consolidateddata.category) : null,
                            "parent_id": consolidateddata.act ? Number(consolidateddata.act) : 0,
                            "compliance_task": consolidateddata.compliance_task ? Number(consolidateddata.compliance_task) : null,
                            "frequency_id": consolidateddata.compliance_frequency ? Number(consolidateddata.compliance_frequency) : 0,
                            "status_name": consolidateddata.compliance_task_status,
                            "csv": false,
                            "f_count": 1,
                            "t_count": 25,
                            "count_qry": true,
                            "list_check": null
                        }
                    ]
                }
            ]
            getStatutoryUnitwiseReportData({
                payload: payload,
                paramid: paramid
            })
        }
    }
    const showModal = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            setIsModalOpen(true);
        }
    };
    const handleOk = () => {
        setAddFormSubmit(true)
        setExportButton(true)
        if (validator.current.allValid()) {
            // setTableShow(true)
            setKey(0)
            setStatKey(1)
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetStatutorySettingsUnitWise",
                        {
                            "c_id": localStorage.getItem('SelectedEntity') !== "All Legal Entity" ? sessionArr && sessionArr[0].c_id : Number(consolidateddata.country),
                            "bg_id": businessGroup.bg_id ? Number(businessGroup.bg_id) : null,
                            "le_id": entityid,
                            "d_id": Number(consolidateddata.domain),
                            "unit_id": consolidateddata.unit ? Number(consolidateddata.unit) : null,
                            "div_id": consolidateddata.division ? Number(consolidateddata.division) : null,
                            "cat_id": consolidateddata.category ? Number(consolidateddata.category) : null,
                            "parent_id": consolidateddata.act ? Number(consolidateddata.act) : 0,
                            "compliance_task": consolidateddata.compliance_task ? Number(consolidateddata.compliance_task) : null,
                            "frequency_id": consolidateddata.compliance_frequency ? Number(consolidateddata.compliance_frequency) : 0,
                            "status_name": consolidateddata.compliance_task_status,
                            "csv": true,
                            "f_count": 1,
                            "t_count": 25,
                            "count_qry": true,
                            "list_check": checkboxValue
                        }
                    ]

                }
            ]
            getStatutoryUnitwiseReportExport({
                payload: payload,
                paramid: paramid
            })
        }
        setIsModalOpen(false);
    };
    const chilData = (id, unitId) => {
        setIsModalVisible(true)
        if (tableData && tableData.statutory_settings_unit_wise_list && tableData.statutory_settings_unit_wise_list.length > 25) {
            let tempArr = []
            for (let i in tableData && tableData.statutory_settings_unit_wise_list && tableData.statutory_settings_unit_wise_list.length && tableData.statutory_settings_unit_wise_list) {
                if (tableData.statutory_settings_unit_wise_list && tableData.statutory_settings_unit_wise_list.length && tableData.statutory_settings_unit_wise_list[i].compliance_id == id) {
                    if (tableData.statutory_settings_unit_wise_list && tableData.statutory_settings_unit_wise_list.length && tableData.statutory_settings_unit_wise_list[i].unit_id == unitId) {
                        tempArr.push(tableData.statutory_settings_unit_wise_list && tableData.statutory_settings_unit_wise_list.length && tableData.statutory_settings_unit_wise_list[i])
                    }
                }
            }
            setChildComplinaceId(tempArr)

        }
    }

    console.log(tableData, 'tableData');

    const handleCancel = () => {
        setIsModalOpen(false);
        setShowValueModal(false)
        setIsModalVisible(false)
    };
    const showValues = () => {
        setShowValueModal(true);
    };
    const onChange = (key) => {
        console.log(key);
    };

    const onStatChange = (key) => {
        console.log(key);
    }

    const checkBoxOnChange = (e) => {
        let temp = checkboxValue
        let tempIds = []
        if (temp.includes(Number(e.target.value)) == true) {
            for (let i in temp) {
                if (temp[i] == Number(e.target.value)) {
                    temp.splice(i, 1)
                }
            }
            setCheckBoxValue([...temp])
        } else {
            tempIds.push(Number(e.target.value))
            setCheckBoxValue([...checkboxValue, ...tempIds])
        }
    }

    const selectAllCheckBox = (checked) => {
        var ele = document.getElementsByName('chk');
        let tempAllIds = []
        if (checked == true) {
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox') {
                    ele[i].checked = true;
                    tempAllIds.push(Number(ele[i].value))
                }
            }
            setCheckBoxValue(tempAllIds)
        }
        else {
            var ele = document.getElementsByName('chk');
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox')
                    ele[i].checked = false;

            }
            setCheckBoxValue([])
        }
    }

    const homescreen = () => {
        window.location.href = '/dashboard'
    }

    const [fullscreen, setfullscreen] = useState(false);
    
    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', handleKeyDown, false);
            document.addEventListener('webkitfullscreenchange', handleKeyDown, false);
            return () => {
                document.addEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('webkitfullscreenchange', handleKeyDown);
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode();
            setfullscreen(true);
        } else {
            fullscreenexit();
            setfullscreen(false);
        }
    }
    const filterHandle = () => {
        setFilterTaskAcc(true);
        setFilterModalVisible(true);
      };

    return (
        <div id='page-wrapper' className="page-wrapper">
            <ScrollToTop smooth color="red" component={<Tooltip title="Back to top"><UpOutlined /></Tooltip>} />
            <div className="page-titles pb-0 pt-2">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <span className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <span style={{ fontSize: '14px', fontWeight: "bold" }}>Report</span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: '15px', fontWeight: 'bold' }}>
                                    <span style={{ fontSize: "15px", fontWeight: "bold" }}>Statutory Setting Reports</span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                    <span>Unit Wise Report</span>
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div className="col-lg-4 text-end">
                        <Button
                className="mx-1"
                type="primary"
                shape="round"
                style={{
                  display: filterTaskAcc ? "none" : "initial",
                }}
                onClick={filterHandle}
              >
                <FilterOutlined /> Filter
              </Button>
                            <button style={{ float: 'right', marginTop: '5px' }} onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black ms-2 full-mode fullscreenradius" >
                                <Tooltip placement="left" title="Full Screen Mode"><FullscreenOutlined /></Tooltip>
                            </button>
                        </div>
                </div>
            </div>

            <Modal title="Select Fields" className={'add-service-prv ' + localStorage.getItem('currentTheme')} visible={isModalOpen} footer={null} onOk={handleOk} onCancel={handleCancel} width={100}>
                <div className='row'>
                    <div className='col-lg-3'><input id="select_all" width="100%" type="checkbox" autocomplete="off"
                        checked={checkboxValue.length === 25 ? true : false}
                        onClick={(e) => {
                            setAllCheckBoxState(true)
                            let checked = e.target.checked
                            selectAllCheckBox(checked)
                            // if(allCheckBoxState && checkboxValue === 17){

                            // }
                        }} /> Select All</div>
                </div>
                <br />
                <div className='row'>
                    <div className='col-lg-3'><input id="column_1" name='chk' type="checkbox" checked disabled="disabled" value='1' onChange={(e) => (e) => checkBoxOnChange(e)} /> Business Group</div>
                    <div className='col-lg-3'><input id="column_2" name='chk' type="checkbox" checked disabled="disabled" value='2' onChange={(e) => checkBoxOnChange(e)} /> Legal Entity </div>
                    <div className='col-lg-3'><input id="column_7" name='chk' type="checkbox" value='3' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> Unit Code</div>
                    <div className='col-lg-3'><input id="column_7" name='chk' type="checkbox" value='4' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> Unit Name</div>

                </div>
                <br />
                <div className='row'>
                    <div className='col-lg-3'><input id="column_8" name='chk' type="checkbox" value='5' onChange={(e) => checkBoxOnChange(e)} /> Unit Code 1 </div>
                    <div className='col-lg-3'><input id="column_9" name='chk' type="checkbox" value='6' onChange={(e) => checkBoxOnChange(e)} /> Unit Code 2 </div>
                    <div className='col-lg-3'><input id="column_10" name='chk' type="checkbox" value='7' onChange={(e) => checkBoxOnChange(e)} /> Unit Code 3</div>
                    <div className='col-lg-3'><input id="column_29" name='chk' type="checkbox" value='8' onChange={(e) => checkBoxOnChange(e)} /> Unit Code 4</div>
                </div>
                <br />
                <div className='row'>
                    <div className='col-lg-3'><input id="column_32" name='chk' type="checkbox" value='9' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> Location</div>
                    <div className='col-lg-3'><input id="column_3" name='chk' type="checkbox" value='10' onChange={(e) => checkBoxOnChange(e)} /> Division </div>
                    <div className='col-lg-3'><input id="column_4" name='chk' type="checkbox" value='11' onChange={(e) => checkBoxOnChange(e)} /> Category</div>
                    <div className='col-lg-3'><input id="column_5" name='chk' type="checkbox" value='12' onChange={(e) => checkBoxOnChange(e)} /> Act </div>
                </div>
                <br />
                <div className='row'>
                    <div className='col-lg-3'><input id="column_14" name='chk' type="checkbox" value='13' onChange={(e) => checkBoxOnChange(e)} /> Status</div>
                    <div className='col-lg-3'><input id="column_15" name='chk' type="checkbox" value='14' onChange={(e) => checkBoxOnChange(e)} /> Compliance Task</div>
                    <div className='col-lg-3'><input id="column_16" name='chk' type="checkbox" value='15' onChange={(e) => checkBoxOnChange(e)} /> Frequency</div>
                    <div className='col-lg-3'><input id="column_17" name='chk' type="checkbox" value='16' onChange={(e) => checkBoxOnChange(e)} /> Periodicity</div>
                </div>
                <br />
                <div className='row'>
                    <div className='col-lg-3'><input id="column_18" name='chk' type="checkbox" value='17' onChange={(e) => checkBoxOnChange(e)} /> Criticality</div>
                    <div className='col-lg-3'><input id="column_19" name='chk' type="checkbox" value='18' onChange={(e) => checkBoxOnChange(e)} /> Assignee</div>
                    <div className='col-lg-3'><input id="column_20" name='chk' type="checkbox" value='19' onChange={(e) => checkBoxOnChange(e)} /> Concurrer </div>
                    <div className='col-lg-3'><input id="column_21" name='chk' type="checkbox" value='20' onChange={(e) => checkBoxOnChange(e)} /> Approver</div>
                </div>
                <br />
                <div className='row'>
                    <div className='col-lg-3'><input id="column_22" name='chk' type="checkbox" value='21' onChange={(e) => checkBoxOnChange(e)} /> Start Date </div>
                    <div className='col-lg-3'><input id="column_23" name='chk' type="checkbox" value='22' onChange={(e) => checkBoxOnChange(e)} /> Due Date  </div>
                    <div className='col-lg-3'><input id="column_24" name='chk' type="checkbox" value='23' onChange={(e) => checkBoxOnChange(e)} /> Extend Due Date  </div>
                    <div className='col-lg-3'><input id="column_25" name='chk' type="checkbox" value='24' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> Month</div>
                </div>
                <br />
                <div className='row'>
                    <div className='col-lg-3'><input id="column_26" name='chk' type="checkbox" value='25' onChange={(e) => checkBoxOnChange(e)} /> Task Completion Date / Document Issued Date</div>
                </div>
                <br />

                <div className="form-actions popupbtncolour text-center ">
                    <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')}
                        icon={<PlayCircleOutlined />} size='default' onClick={handleOk}> Submit
                    </Button>
                </div>

            </Modal>
            <Modal visible={isModalVisible} onCancel={handleCancel} onOk={() => {
                setIsModalVisible(false)
            }} >
                <Table style={{ marginTop: '1%' }}
                    className='userprivclass'
                    columns={columns}
                    dataSource={childCompliceId}
                    bordered
                    pagination={false} />

            </Modal>
            <div className="container-fluid pt-1">
                <div className="row">
                    <div className="col-12">
                        <div className="card-body" id='only-restricted' style={{ position: 'relative', padding: "10px 3px" }}>
                            <div className={localStorage.getItem('currentTheme')}>

                                <Collapse defaultActiveKey={["1"]} className='report-collapse' style={{ display: exitCollapse ? "none" : "block"}} onChange={onChange} >
                                    <Panel header="Unit Wise Report" key={key}>
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-4">
                                                    <label><b>Country : </b><span style={{ color: "red" }}> *</span></label>
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?

                                                        <div className="form-group">

                                                            <Select
                                                                // showSearch
                                                                // optionFilterProp="children"
                                                                // disabled={unit.legal_entity === "" ? true : false}
                                                                // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                allowClear={true}
                                                                size="default"
                                                                placeholder="Enter Country"
                                                                value={consolidateddata.country || undefined}
                                                                onChange={(data, value) => {
                                                                    setConsolidatedDate({
                                                                        ...consolidateddata,
                                                                        country: data,
                                                                        countryName: value.children,
                                                                        category: "",
                                                                        user_type: "",
                                                                        legel_entity: "",
                                                                        legel_entity_name: "",
                                                                        unit: '',
                                                                        user: "",
                                                                        domain: "",
                                                                        act: "",
                                                                        from_date: "",
                                                                        task_category: "",
                                                                        compliance_task: "",
                                                                        to_date: "",
                                                                        task_sub_category: "",
                                                                        compliance_frequency: "All",
                                                                        compliance_task_status: "All",
                                                                        division: "",
                                                                        domainName: '',
                                                                        unit_name: '',
                                                                        user_name: '',
                                                                        act_name: '',
                                                                        division_name: '',
                                                                        compliance_frequency_name: '',
                                                                        compliance_task_status_name: '',
                                                                        category_name: '',
                                                                        user_type_name: '',
                                                                        task_status: ''
                                                                    })

                                                                }
                                                                }
                                                                // value={compfie.user_Group}
                                                                style={{ width: '100%', marginTop: '5px' }}
                                                            >
                                                                {/* {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                                return ( */}
                                                                {country_info && country_info.length > 0 && country_info.map((item, i) => {

                                                                    return (

                                                                        <Option key={item.c_id}>

                                                                            {item.c_name}

                                                                        </Option>

                                                                    );

                                                                })}
                                                                {/* );
                                                            })} */}
                                                            </Select>

                                                            {validator.current.message(
                                                                'Country',
                                                                consolidateddata.country,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Country Required',
                                                                    }
                                                                })}

                                                        </div> :
                                                        <p>{sessionArr && sessionArr.length > 0 && sessionArr[0].c_name}</p>
                                                    }

                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Legal Entity : </b> <span style={{ color: "red" }}> *</span></label>
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                disabled={consolidateddata.country == "" ? true : false}
                                                                placeholder="Enter Legal Entity"
                                                                onChange={(data, value) => {
                                                                    setCurrentEntity(data)
                                                                    setConsolidatedDate({
                                                                        ...consolidateddata,
                                                                        legel_entity: data,
                                                                        legel_entity_name: value.children,
                                                                        category: "",
                                                                        user_type: "",
                                                                        unit: '',
                                                                        user: "",
                                                                        domain: "",
                                                                        act: "",
                                                                        from_date: "",
                                                                        task_category: "",
                                                                        compliance_task: "",
                                                                        to_date: "",
                                                                        task_sub_category: "",
                                                                        compliance_frequency: "All",
                                                                        compliance_task_status: "All",
                                                                        division: "",
                                                                        domainName: '',
                                                                        unit_name: '',
                                                                        user_name: '',
                                                                        act_name: '',
                                                                        division_name: '',
                                                                        compliance_frequency_name: '',
                                                                        compliance_task_status_name: '',
                                                                        category_name: '',
                                                                        user_type_name: '',
                                                                        task_status: ''
                                                                    })
                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                //value={Settings.le_id}
                                                                style={{ width: '100%', marginTop: '5px' }}

                                                                value={consolidateddata.legel_entity || undefined}
                                                            >
                                                                {sessionArr && sessionArr.length > 0 && sessionArr.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.le_id}>
                                                                            {item.le_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                            {validator.current.message(
                                                                'legalentity',
                                                                consolidateddata.legel_entity,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Legal Entity Required',
                                                                    }
                                                                })}
                                                        </div> :
                                                        <p>{localStorage.getItem('SelectedEntity')}</p>
                                                    }
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Domain : </b> <span style={{ color: "red" }}>*</span></label><br />
                                                    <Select
                                                        onChange={(value, data) => {
                                                            setConsolidatedDate({
                                                                ...consolidateddata,
                                                                domain: value,
                                                                domainName: data.children,
                                                                category: "",
                                                                user_type: "",
                                                                unit: '',
                                                                user: "",
                                                                act: "",
                                                                from_date: "",
                                                                task_category: "",
                                                                compliance_task: "",
                                                                to_date: "",
                                                                task_sub_category: "",
                                                                compliance_frequency: "All",
                                                                compliance_task_status: "All",
                                                                division: "",
                                                                unit_name: '',
                                                                user_name: '',
                                                                act_name: '',
                                                                division_name: '',
                                                                compliance_frequency_name: '',
                                                                compliance_task_status_name: '',
                                                                category_name: '',
                                                                user_type_name: '',
                                                                task_status: ''
                                                            })
                                                        }}
                                                        disabled={((localStorage.getItem('SelectedEntity') === "All Legal Entity") && consolidateddata.legel_entity == '') ? true : false}
                                                        placeholder="Enter Domain"
                                                        style={{ width: '100%', marginTop: '5px' }}

                                                        value={consolidateddata.domainName || undefined}
                                                    >
                                                        {domainList && domainList.length > 0 && domainList.map((item, i) => {
                                                            return (
                                                                <Option key={item.d_id}>
                                                                    {item.d_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                    {validator.current.message(
                                                        'domain',
                                                        consolidateddata.domain,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Domain Required',
                                                            }
                                                        })}
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-md-12 mt-1">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <label><b>Business Group : </b></label><br />
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                placeholder="Enter Business Group"
                                                                onChange={(data, value) => {
                                                                    // setCurrentEntity(data)
                                                                    // SetSettings({
                                                                    //     ...Settings,
                                                                    //     le_id: data
                                                                    // })
                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                //value={Settings.le_id}
                                                                style={{ width: '100%', marginTop: '5px' }}

                                                            >
                                                                {sessionArr && sessionArr.length > 0 && sessionArr.map((item, i) => {
                                                                    if (item.bg_name !== null) {
                                                                        return (
                                                                            <Option key={item.bg_id}>
                                                                                {item.bg_name}
                                                                            </Option>
                                                                        );
                                                                    }
                                                                })}
                                                            </Select>
                                                        </div> :
                                                        <p>{businessGroup.bg_name ? businessGroup.bg_name : '-'}</p>
                                                    }
                                                </div>
                                                <div className="col-4">
                                                    <label htmlFor=""><b>Division :  </b></label><br />
                                                    <Select
                                                        onChange={(value, data) => {
                                                            setConsolidatedDate({
                                                                ...consolidateddata,
                                                                division: value,
                                                                division_name: data.children,
                                                                category: '',
                                                                category_name: '',
                                                                unit: '',
                                                                unit_name: '',
                                                                act_name: '',
                                                                act: '',
                                                                compliance_task: '',
                                                                compliance_frequency: 'All',
                                                                compliance_task_status: 'All'
                                                            })
                                                            let filteredCategory = _.filter(allData && allData.cat_infos, { div_id: Number(value) })
                                                            setCategoryList(filteredCategory)
                                                            console.log(allData && allData.units, 'allData && allData.unit_legal_entity,');
                                                            let filteredUnit = _.filter(allData && allData.units, { division_id: Number(value) })
                                                            setUnitLegalEntity(filteredUnit)
                                                        }}
                                                        placeholder="Enter Division"
                                                        style={{ width: '100%', marginTop: '5px' }}

                                                        value={consolidateddata.division_name || undefined}
                                                        disabled={consolidateddata.domain == '' ? true : false}
                                                    >
                                                        {divisionList && divisionList.length > 0 && divisionList.map((item, i) => {
                                                            return (
                                                                <Option key={item.div_id}>
                                                                    {item.div_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                                <div className="col-md-4">
                                                    <label><b>Category : </b></label><br />
                                                    <Select
                                                        onChange={(value, data) => {
                                                            setConsolidatedDate({
                                                                ...consolidateddata,
                                                                category: value,
                                                                category_name: data.children,
                                                                unit: '',
                                                                unit_name: '',
                                                                act_name: '',
                                                                act: '',
                                                                compliance_task: '',
                                                                compliance_frequency: 'All',
                                                                compliance_task_status: 'All'
                                                            })
                                                            let filteredUnit = _.filter(allData && allData.units, { category_id: Number(value) })
                                                            setUnitLegalEntity(filteredUnit)
                                                        }}
                                                        placeholder="Enter Category"
                                                        style={{ width: '100%', marginTop: '5px' }}

                                                        value={consolidateddata.category_name || undefined}
                                                        disabled={consolidateddata.domain == '' ? true : false}
                                                    >
                                                        {categoryList && categoryList.length > 0 && categoryList.map((item, i) => {
                                                            return (
                                                                <Option key={item.cat_id}>
                                                                    {item.cat_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-md-12 mt-1">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <label htmlFor=""><b>Compliance frequency : </b></label>
                                                    <Select
                                                        onChange={(value, data) => {
                                                            setConsolidatedDate({
                                                                ...consolidateddata,
                                                                compliance_frequency: value,
                                                                // compliance_task_status_name: data.children
                                                            })
                                                        }}
                                                        // placeholder="Enter Domain Name"
                                                        style={{ width: '100%', marginTop: '5px' }}

                                                        value={consolidateddata.compliance_frequency || undefined}
                                                    >
                                                        <Option key='0'>All</Option>
                                                        {complianceFrequencyList && complianceFrequencyList.length > 0 && complianceFrequencyList.map((item, i) => {
                                                            return (

                                                                <Option key={item.frequency_id}>
                                                                    {item.frequency}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Unit : </b></label><br />
                                                    <Select
                                                        onChange={(value, data) => {
                                                            setConsolidatedDate({
                                                                ...consolidateddata,
                                                                unit: value,
                                                                unit_name: data.children,
                                                                act_name: '',
                                                                act: '',
                                                                compliance_task: '',
                                                                compliance_frequency: 'All',
                                                                compliance_task_status: 'All'
                                                            })
                                                        }}
                                                        placeholder="Enter Unit"
                                                        style={{ width: '100%', marginTop: '5px' }}

                                                        value={consolidateddata.unit_name || undefined}
                                                        disabled={consolidateddata.domain == '' ? true : false}
                                                    >
                                                        {unitLegalEntity && unitLegalEntity.length > 0 && unitLegalEntity.map((item, i) => {
                                                            return (
                                                                <Option key={item.unit_id}>
                                                                    {`${item.unit_code}-${item.unit_name}`}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                                <div className="col-4">
                                                    <label htmlFor=""><b>Compliance Task : </b></label>
                                                    <Input placeholder="Enter Compliance Task" style={{ width: '100%', marginTop: '5px' }}

                                                        onChange={(e) => {
                                                            setConsolidatedDate({
                                                                ...consolidateddata,
                                                                compliance_task: e.target.value
                                                            })
                                                        }}
                                                        value={consolidateddata.compliance_task || undefined}
                                                    />
                                                </div>


                                            </div>
                                        </div>
                                        <div className="col-md-12 mt-1">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <label><b>Task Status : </b></label><br />
                                                    <Select
                                                        // disabled={consolidatedData.domain == '' ? true : false}
                                                        onChange={(value) => {
                                                            setConsolidatedDate({
                                                                ...consolidateddata,
                                                                compliance_task_status: value
                                                            })
                                                        }}
                                                        // placeholder="Enter Domain Name"
                                                        style={{ width: '100%', marginTop: '5px' }}

                                                        value={consolidateddata.compliance_task_status || undefined}
                                                    >
                                                        <Option key="All">All</Option>
                                                        <Option key="Assigned">Assigned</Option>
                                                        <Option key="Un-Assigned">Un-Assigned</Option>
                                                        <Option key="Not Opted">Not Opted</Option>
                                                        <Option key="Not Applicable">Not Applicable</Option>
                                                    </Select>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Act : </b></label><br />
                                                    <Select
                                                        onChange={(value, data) => {
                                                            setConsolidatedDate({
                                                                ...consolidateddata,
                                                                act: value,
                                                                act_name: data.children
                                                            })
                                                        }}
                                                        placeholder="Enter Act"
                                                        style={{ width: '100%', marginTop: '5px' }}

                                                        value={consolidateddata.act_name || undefined}
                                                        disabled={consolidateddata.domain == '' ? true : false}
                                                    >
                                                        {actLegalEntity && actLegalEntity.length > 0 && actLegalEntity.map((item, i) => {
                                                            return (
                                                                <Option key={item.parent_id}>
                                                                    {item.act}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                                <div className="col-md-4">

                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: "1%" }}>
                                            <div className='row'>
                                                <div className='col-md-4 popupbtncolour' style={{ marginLeft: '35%' }}>
                                                    <Button type="primary" shape="round" className={'addbutton '+ localStorage.getItem('currentTheme')} style={{ background: "#0096FF", borderColor: "#0096FF", marginLeft: "39px", marginRight: "10px" }}
                                                        onClick={onshow} icon={<EyeOutlined />}
                                                    >
                                                        Show
                                                    </Button>
                                                    <Button type="primary" shape="round" className={'addbutton '+ localStorage.getItem('currentTheme')} style={{ background: "#32a852", borderColor: "#32a852" }} onClick={showModal} icon={<ExportOutlined />}
                                                    >
                                                        Export
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Panel>
                                </Collapse>

                                <Modal
                  onCancel={setcancelFilter}
                  footer={false}
                  title="Unit List"
                  className={
                    "add-service-prv cons-report " +
                    localStorage.getItem("currentTheme")
                  }
                  visible={filterModalVisible}
                >        
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-4">
                            <label><b>Country : </b><span style={{ color: "red" }}> *</span></label>
                            {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?

                                <div className="form-group">

                                    <Select
                                        // showSearch
                                        // optionFilterProp="children"
                                        // disabled={unit.legal_entity === "" ? true : false}
                                        // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        allowClear={true}
                                        size="default"
                                        placeholder="Enter Country"
                                        value={consolidateddata.country || undefined}
                                        onChange={(data, value) => {
                                            setConsolidatedDate({
                                                ...consolidateddata,
                                                country: data,
                                                countryName: value.children,
                                                category: "",
                                                user_type: "",
                                                legel_entity: "",
                                                legel_entity_name: "",
                                                unit: '',
                                                user: "",
                                                domain: "",
                                                act: "",
                                                from_date: "",
                                                task_category: "",
                                                compliance_task: "",
                                                to_date: "",
                                                task_sub_category: "",
                                                compliance_frequency: "All",
                                                compliance_task_status: "All",
                                                division: "",
                                                domainName: '',
                                                unit_name: '',
                                                user_name: '',
                                                act_name: '',
                                                division_name: '',
                                                compliance_frequency_name: '',
                                                compliance_task_status_name: '',
                                                category_name: '',
                                                user_type_name: '',
                                                task_status: ''
                                            })

                                        }
                                        }
                                        // value={compfie.user_Group}
                                        style={{ width: '100%', marginTop: '5px' }}
                                    >
                                        {/* {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                        return ( */}
                                        {country_info && country_info.length > 0 && country_info.map((item, i) => {

                                            return (

                                                <Option key={item.c_id}>

                                                    {item.c_name}

                                                </Option>

                                            );

                                        })}
                                        {/* );
                                    })} */}
                                    </Select>

                                    {validator.current.message(
                                        'Country',
                                        consolidateddata.country,
                                        'required',
                                        {
                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                            messages: {
                                                required: 'Country Required',
                                            }
                                        })}

                                </div> :
                                <p>{sessionArr && sessionArr.length > 0 && sessionArr[0].c_name}</p>
                            }

                        </div>
                        <div className='col-md-4'>
                            <label><b>Legal Entity : </b> <span style={{ color: "red" }}> *</span></label>
                            {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                <div className="form-group">
                                    <Select
                                        allowClear={true}
                                        size="default"
                                        disabled={consolidateddata.country == "" ? true : false}
                                        placeholder="Enter Legal Entity"
                                        onChange={(data, value) => {
                                            setCurrentEntity(data)
                                            setConsolidatedDate({
                                                ...consolidateddata,
                                                legel_entity: data,
                                                legel_entity_name: value.children,
                                                category: "",
                                                user_type: "",
                                                unit: '',
                                                user: "",
                                                domain: "",
                                                act: "",
                                                from_date: "",
                                                task_category: "",
                                                compliance_task: "",
                                                to_date: "",
                                                task_sub_category: "",
                                                compliance_frequency: "All",
                                                compliance_task_status: "All",
                                                division: "",
                                                domainName: '',
                                                unit_name: '',
                                                user_name: '',
                                                act_name: '',
                                                division_name: '',
                                                compliance_frequency_name: '',
                                                compliance_task_status_name: '',
                                                category_name: '',
                                                user_type_name: '',
                                                task_status: ''
                                            })
                                        }}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        //value={Settings.le_id}
                                        style={{ width: '100%', marginTop: '5px' }}

                                        value={consolidateddata.legel_entity || undefined}
                                    >
                                        {sessionArr && sessionArr.length > 0 && sessionArr.map((item, i) => {
                                            return (
                                                <Option key={item.le_id}>
                                                    {item.le_name}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                    {validator.current.message(
                                        'legalentity',
                                        consolidateddata.legel_entity,
                                        'required',
                                        {
                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                            messages: {
                                                required: 'Legal Entity Required',
                                            }
                                        })}
                                </div> :
                                <p>{localStorage.getItem('SelectedEntity')}</p>
                            }
                        </div>
                        <div className='col-md-4'>
                            <label><b>Domain : </b> <span style={{ color: "red" }}>*</span></label><br />
                            <Select
                                onChange={(value, data) => {
                                    setConsolidatedDate({
                                        ...consolidateddata,
                                        domain: value,
                                        domainName: data.children,
                                        category: "",
                                        user_type: "",
                                        unit: '',
                                        user: "",
                                        act: "",
                                        from_date: "",
                                        task_category: "",
                                        compliance_task: "",
                                        to_date: "",
                                        task_sub_category: "",
                                        compliance_frequency: "All",
                                        compliance_task_status: "All",
                                        division: "",
                                        unit_name: '',
                                        user_name: '',
                                        act_name: '',
                                        division_name: '',
                                        compliance_frequency_name: '',
                                        compliance_task_status_name: '',
                                        category_name: '',
                                        user_type_name: '',
                                        task_status: ''
                                    })
                                }}
                                disabled={((localStorage.getItem('SelectedEntity') === "All Legal Entity") && consolidateddata.legel_entity == '') ? true : false}
                                placeholder="Enter Domain"
                                style={{ width: '100%', marginTop: '5px' }}

                                value={consolidateddata.domainName || undefined}
                            >
                                {domainList && domainList.length > 0 && domainList.map((item, i) => {
                                    return (
                                        <Option key={item.d_id}>
                                            {item.d_name}
                                        </Option>
                                    );
                                })}
                            </Select>
                            {validator.current.message(
                                'domain',
                                consolidateddata.domain,
                                'required',
                                {
                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                    messages: {
                                        required: 'Domain Required',
                                    }
                                })}
                        </div>

                    </div>
                </div>
                <div className="col-md-12 mt-1">
                    <div className="row">
                        <div className="col-md-4">
                            <label><b>Business Group : </b></label><br />
                            {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                <div className="form-group">
                                    <Select
                                        allowClear={true}
                                        size="default"
                                        placeholder="Enter Business Group"
                                        onChange={(data, value) => {
                                            // setCurrentEntity(data)
                                            // SetSettings({
                                            //     ...Settings,
                                            //     le_id: data
                                            // })
                                        }}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        //value={Settings.le_id}
                                        style={{ width: '100%', marginTop: '5px' }}

                                    >
                                        {sessionArr && sessionArr.length > 0 && sessionArr.map((item, i) => {
                                            if (item.bg_name !== null) {
                                                return (
                                                    <Option key={item.bg_id}>
                                                        {item.bg_name}
                                                    </Option>
                                                );
                                            }
                                        })}
                                    </Select>
                                </div> :
                                <p>{businessGroup.bg_name ? businessGroup.bg_name : '-'}</p>
                            }
                        </div>
                        <div className="col-4">
                            <label htmlFor=""><b>Division :  </b></label><br />
                            <Select
                                onChange={(value, data) => {
                                    setConsolidatedDate({
                                        ...consolidateddata,
                                        division: value,
                                        division_name: data.children,
                                        category: '',
                                        category_name: '',
                                        unit: '',
                                        unit_name: '',
                                        act_name: '',
                                        act: '',
                                        compliance_task: '',
                                        compliance_frequency: 'All',
                                        compliance_task_status: 'All'
                                    })
                                    let filteredCategory = _.filter(allData && allData.cat_infos, { div_id: Number(value) })
                                    setCategoryList(filteredCategory)
                                    console.log(allData && allData.units, 'allData && allData.unit_legal_entity,');
                                    let filteredUnit = _.filter(allData && allData.units, { division_id: Number(value) })
                                    setUnitLegalEntity(filteredUnit)
                                }}
                                placeholder="Enter Division"
                                style={{ width: '100%', marginTop: '5px' }}

                                value={consolidateddata.division_name || undefined}
                                disabled={consolidateddata.domain == '' ? true : false}
                            >
                                {divisionList && divisionList.length > 0 && divisionList.map((item, i) => {
                                    return (
                                        <Option key={item.div_id}>
                                            {item.div_name}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </div>
                        <div className="col-md-4">
                            <label><b>Category : </b></label><br />
                            <Select
                                onChange={(value, data) => {
                                    setConsolidatedDate({
                                        ...consolidateddata,
                                        category: value,
                                        category_name: data.children,
                                        unit: '',
                                        unit_name: '',
                                        act_name: '',
                                        act: '',
                                        compliance_task: '',
                                        compliance_frequency: 'All',
                                        compliance_task_status: 'All'
                                    })
                                    let filteredUnit = _.filter(allData && allData.units, { category_id: Number(value) })
                                    setUnitLegalEntity(filteredUnit)
                                }}
                                placeholder="Enter Category"
                                style={{ width: '100%', marginTop: '5px' }}

                                value={consolidateddata.category_name || undefined}
                                disabled={consolidateddata.domain == '' ? true : false}
                            >
                                {categoryList && categoryList.length > 0 && categoryList.map((item, i) => {
                                    return (
                                        <Option key={item.cat_id}>
                                            {item.cat_name}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </div>

                    </div>
                </div>
                <div className="col-md-12 mt-1">
                    <div className="row">
                        <div className="col-md-4">
                            <label htmlFor=""><b>Compliance frequency : </b></label>
                            <Select
                                onChange={(value, data) => {
                                    setConsolidatedDate({
                                        ...consolidateddata,
                                        compliance_frequency: value,
                                        // compliance_task_status_name: data.children
                                    })
                                }}
                                // placeholder="Enter Domain Name"
                                style={{ width: '100%', marginTop: '5px' }}

                                value={consolidateddata.compliance_frequency || undefined}
                            >
                                <Option key='0'>All</Option>
                                {complianceFrequencyList && complianceFrequencyList.length > 0 && complianceFrequencyList.map((item, i) => {
                                    return (

                                        <Option key={item.frequency_id}>
                                            {item.frequency}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </div>
                        <div className='col-md-4'>
                            <label><b>Unit : </b></label><br />
                            <Select
                                onChange={(value, data) => {
                                    setConsolidatedDate({
                                        ...consolidateddata,
                                        unit: value,
                                        unit_name: data.children,
                                        act_name: '',
                                        act: '',
                                        compliance_task: '',
                                        compliance_frequency: 'All',
                                        compliance_task_status: 'All'
                                    })
                                }}
                                placeholder="Enter Unit"
                                style={{ width: '100%', marginTop: '5px' }}

                                value={consolidateddata.unit_name || undefined}
                                disabled={consolidateddata.domain == '' ? true : false}
                            >
                                {unitLegalEntity && unitLegalEntity.length > 0 && unitLegalEntity.map((item, i) => {
                                    return (
                                        <Option key={item.unit_id}>
                                            {`${item.unit_code}-${item.unit_name}`}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </div>
                        <div className="col-4">
                            <label htmlFor=""><b>Compliance Task : </b></label>
                            <Input placeholder="Enter Compliance Task" style={{ width: '100%', marginTop: '5px' }}

                                onChange={(e) => {
                                    setConsolidatedDate({
                                        ...consolidateddata,
                                        compliance_task: e.target.value
                                    })
                                }}
                                value={consolidateddata.compliance_task || undefined}
                            />
                        </div>


                    </div>
                </div>
                <div className="col-md-12 mt-1">
                    <div className="row">
                        <div className="col-md-4">
                            <label><b>Task Status : </b></label><br />
                            <Select
                                // disabled={consolidatedData.domain == '' ? true : false}
                                onChange={(value) => {
                                    setConsolidatedDate({
                                        ...consolidateddata,
                                        compliance_task_status: value
                                    })
                                }}
                                // placeholder="Enter Domain Name"
                                style={{ width: '100%', marginTop: '5px' }}

                                value={consolidateddata.compliance_task_status || undefined}
                            >
                                <Option key="All">All</Option>
                                <Option key="Assigned">Assigned</Option>
                                <Option key="Un-Assigned">Un-Assigned</Option>
                                <Option key="Not Opted">Not Opted</Option>
                                <Option key="Not Applicable">Not Applicable</Option>
                            </Select>
                        </div>
                        <div className='col-md-4'>
                            <label><b>Act : </b></label><br />
                            <Select
                                onChange={(value, data) => {
                                    setConsolidatedDate({
                                        ...consolidateddata,
                                        act: value,
                                        act_name: data.children
                                    })
                                }}
                                placeholder="Enter Act"
                                style={{ width: '100%', marginTop: '5px' }}

                                value={consolidateddata.act_name || undefined}
                                disabled={consolidateddata.domain == '' ? true : false}
                            >
                                {actLegalEntity && actLegalEntity.length > 0 && actLegalEntity.map((item, i) => {
                                    return (
                                        <Option key={item.parent_id}>
                                            {item.act}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </div>
                        <div className="col-md-4">

                        </div>
                    </div>
                </div>
                <div className='col-md-12' style={{ marginTop: "1%" }}>
                    <div className='row'>
                        <div className='col-md-4 popupbtncolour' style={{ marginLeft: '35%' }}>
                            <Button type="primary" shape="round" className={'addbutton '+ localStorage.getItem('currentTheme')} style={{ background: "#0096FF", borderColor: "#0096FF", marginLeft: "39px", marginRight: "10px" }}
                                onClick={onshow} icon={<EyeOutlined />}
                            >
                                Show
                            </Button>
                            <Button type="primary" shape="round" className={'addbutton '+ localStorage.getItem('currentTheme')} style={{ background: "#32a852", borderColor: "#32a852" }} onClick={showModal} icon={<ExportOutlined />}
                            >
                                Export
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
                            </div>
                            {/* <div className={localStorage.getItem('currentTheme')}>
                                {tableshow == true ?
                                    <Collapse defaultActiveKey={["1"]} className='report-collapse' onChange={onStatChange} style={{ marginTop: '1%', backgroundColor: '#5daae3' }}>
                                        <Panel header="Overall Statistics" key={statkey}>
                                            <div className="col-md-12" style={{ marginLeft: '5%', marginRight: '5%' }}>
                                                <div className="row m-2">
                                                    <div className="col-md-3">
                                                        <Progress type="circle" percent={30} width={90} format={(percent) => `${180}`} />
                                                        <h4>Assigned</h4>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <Progress type="circle" percent={20} width={90} status="exception" format={(percent) => `${110}`} />
                                                        <h4>Un-Assigned</h4>

                                                    </div>
                                                    <div className="col-md-3">
                                                        <Progress type="circle" percent={100} width={90} format={(percent) => `${300}`} />
                                                        <h4>Not Opted</h4>

                                                    </div>
                                                    <div className="col-md-3">
                                                        <Progress type="circle" percent={80} width={90} strokeColor={{ '0%': '#f5b342', '100%': '#f5b342' }} format={(percent) => `${280}`} />
                                                        <h4>Not Applicable</h4>

                                                    </div>
                                                </div>
                                            </div>
                                        </Panel>
                                    </Collapse> : false}
                            </div> */}
                            {tableshow == true ?
                                <Card className='rounded-3'>
                                    <div className='col-md-12 mt-1 mb-1' >
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Legal Entity : </b> {localStorage.getItem('SelectedEntity') === 'All Legal Entity' ? consolidateddata.legel_entity_name : localStorage.getItem('SelectedEntity')}</label>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Country : </b> {sessionArr && sessionArr[0].c_name}</label>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Domain : </b> {consolidateddata.domainName}</label>
                                            </div>


                                        </div>
                                    </div>
                                    <div className={localStorage.getItem('currentTheme')}>
                                        <div className='ant-table ant-table-bordered ant-table-scroll-horizontal'>
                                            <div className='ant-table-container'>
                                                <div className='ant-table-content'>
                                                    <table>
                                                        <colgroup>
                                                            <col style={{ width: '10px' }}></col>
                                                        </colgroup>
                                                        <thead className="ant-table-thead antable-head">
                                                            <tr>
                                                                <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '10px' }}>#</th>
                                                                <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '250px' }}>Compliance Task</th>
                                                                <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '40px' }}>Criticality</th>
                                                                <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '150px' }}>Compliance Frequency</th>
                                                                <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '100px' }}><center>Due Date</center></th>
                                                                <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '150px' }}>Task Status</th>
                                                                <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '140px' }}><center>Assignee</center></th>
                                                                <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '120px' }}><center>Format</center></th>
                                                                {/* <th className='ant-table-cell custom-width1'><center>Activity Date</center></th>
                                                            <th className='ant-table-cell custom-width1'><center>Uploaded Document</center></th>
                                                            <th className='ant-table-cell custom-width1'><center>Task Completion Date/Document issued Date</center></th> */}
                                                            </tr>
                                                        </thead>
                                                        {filteredArrayState && filteredArrayState.length ?
                                                            filteredArrayState.map((item, i) => {

                                                                return (
                                                                    <tbody className='ant-table-tbody' key={i}>
                                                                        <tr className="bg-lightdark">
                                                                            <td style={{ paddingLeft: '0 !important', paddingRight: '0 !important' }} colSpan="100%">
                                                                                <div className='bg-white shadow-sm border-radius-reports'>
                                                                                    <div className="col-md-12">
                                                                                        <div className='row m-2'>
                                                                                            <div className='col-md-3' >
                                                                                                <label><b>Unit :</b> {item[0].unit}</label>
                                                                                            </div>
                                                                                            <div className='col-md-3' >
                                                                                                <label><b>Category :</b> {item[0].category_name}</label>
                                                                                            </div>
                                                                                            <div className='col-md-3' >
                                                                                                <label><b>Division :</b> {item[0].division_name}</label>
                                                                                            </div>
                                                                                            <div className='col-md-3' >
                                                                                                <label><b>Time Zone :</b> {item[0].tz_name}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>


                                                                        {titledata && titledata.map((itemdata, i) => {
                                                                            let data = _.find(itemdata.child, { unit_id: item[0].unit_id })

                                                                            if (data != undefined) {
                                                                                if (data.act_name == itemdata.parent) {


                                                                                    return <Fragment key={i}>

                                                                                        <tr className='ant-table-row ant-table-row-level-0'>

                                                                                            <td colSpan={12} className='ant-table-cell'><b>{itemdata.parent}</b></td>
                                                                                        </tr>

                                                                                        {itemdata.child.map((items, itemsjk) => {
                                                                                            const content = (

                                                                                                <table style={{ width: "300px", display: 'block', height: '100px' }} class="table table-striped" >
                                                                                                    <tbody>
                                                                                                        <tr>
                                                                                                            <th style={{ marginLeft: '50%' }}>Concurrer</th>
                                                                                                            <th style={{ marginLeft: '50%' }}>Approver</th>

                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td>{items.concurrer_name ? items.concurrer_name : '-'}</td>
                                                                                                            <td>{items.assignee_name ? items.assignee_name : '-'}</td>
                                                                                                        </tr>


                                                                                                    </tbody>
                                                                                                </table>
                                                                                            )
                                                                                            if (item[0].unit_id == items.unit_id) {
                                                                                                let array = []

                                                                                                for (let i in tableData && tableData.statutory_settings_unit_wise_list && tableData.statutory_settings_unit_wise_list.length && tableData.statutory_settings_unit_wise_list) {
                                                                                                    if (tableData.statutory_settings_unit_wise_list && tableData.statutory_settings_unit_wise_list.length && tableData.statutory_settings_unit_wise_list[i].compliance_id == items.compliance_id) {
                                                                                                        if (tableData.statutory_settings_unit_wise_list && tableData.statutory_settings_unit_wise_list.length && tableData.statutory_settings_unit_wise_list[i].unit_id == items.unit_id) {
                                                                                                            array.push(tableData.statutory_settings_unit_wise_list && tableData.statutory_settings_unit_wise_list.length && tableData.statutory_settings_unit_wise_list[i])
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                                return <tr key={itemsjk}>
                                                                                                    <td className='ant-table-cell'><center>{index + 1}</center></td>
                                                                                                    <td title={items.compliance_task} className='ant-table-cell'><Tooltip title={items.compliance_description}>
                                                                                                        <ExclamationCircleTwoTone />
                                                                                                    </Tooltip> &nbsp;{array.length > 1 ? <a href='javascript:;' onClick={() => {
                                                                                                        chilData(items.compliance_id, items.unit_id)
                                                                                                    }}>{items.compliance_task}</a> : <a >{items.compliance_task}</a>}</td>
                                                                                                    <td className='ant-table-cell'>{items[" criticality_name"]}</td>
                                                                                                    <td className='ant-table-cell'>{items.frequency}</td>
                                                                                                    <td className='ant-table-cell'><center>{items.due_date}</center></td>
                                                                                                    <td className='ant-table-cell'>{items.task_status}</td>
                                                                                                    <td className='ant-table-cell'><>
                                                                                                        {items.approver_name == null ? false :
                                                                                                            <>
                                                                                                                <div>
                                                                                                                    <div className="mb-3">
                                                                                                                        <Tooltip style={{ maxWidth: '100%' }} title={
                                                                                                                            <table style={{ width: "100%", borderCollapse: 'collapse', border: '1px solid #636262', tableLayout: 'auto' }}>
                                                                                                                                <tbody>
                                                                                                                                    <tr>
                                                                                                                                        <th style={{ border: '1px solid #636262', marginLeft: '5%', marginRight: '5%' }}><center>Concurrer</center></th>
                                                                                                                                        <th style={{ border: '1px solid #636262' }}><center>Approver</center></th>

                                                                                                                                    </tr>
                                                                                                                                    <tr>
                                                                                                                                        <td style={{ border: '1px solid #636262' }}><center>{items.concurrer_name ? items.concurrer_name : '-'}</center></td>
                                                                                                                                        <td style={{ border: '1px solid #636262' }}><center>{items.assignee_name ? items.assignee_name : '-'}</center></td>
                                                                                                                                    </tr>


                                                                                                                                </tbody>
                                                                                                                            </table>
                                                                                                                        }> <i className="bi-info-circle-fill"></i>&nbsp;{items.assignee_name}</Tooltip>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </>
                                                                                                        }

                                                                                                    </></td>
                                                                                                    <td className='ant-table-cell'><center>{
                                                                                                        <a href={`${'https://13.234.100.64/compliance_format/' + items.download_url}`} target="_blank" >
                                                                                                            {items[" document_name"]}
                                                                                                        </a>}</center></td>
                                                                                                    {/* </tr> */}
                                                                                                    <span hidden>{index = index + 1}</span>

                                                                                                </tr>
                                                                                            }

                                                                                        })

                                                                                        }
                                                                                    </Fragment>
                                                                                }
                                                                            }
                                                                        })
                                                                        }
                                                                    </tbody>
                                                                )
                                                            }) :
                                                            <tr>
                                                                <td colSpan="100%" style={{ paddingTop: '15px', textAlign: 'center', fontSize: '14px', fontWeight: 'bold', paddingBottom: '15px' }}>No Records Found</td>
                                                            </tr>
                                                        }
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='row mt-3'>
                                        {index != 0 ?
                                            <>
                                                <div className='col-md-6'>
                                                    <label>Showing {pageSize * (current - 1) + 1} to {index} of {tableData.total_count} entries</label>
                                                </div>
                                                <div className='col-md-6 text-right'>

                                                    <Pagination
                                                        current={current}
                                                        showSizeChanger={true}
                                                        onShowSizeChange={() => {
                                                            setCurrent(1)
                                                        }}
                                                        pageSizeOptions={[25, 50, 100]}
                                                        hideOnSinglePage={tableData.total_count > 25 ? true : false}
                                                        defaultPageSize={25}
                                                        onChange={(page, pageSizes) => {
                                                            setpageState(true)
                                                            setCurrent(pageSize !== pageSizes ? 1 : page);
                                                            setPageSize(pageSizes)
                                                        }} total={tableData.total_count}
                                                    />


                                                </div>
                                            </>
                                            : ''}
                                    </div>
                                </Card>
                                : ""}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default connect(mapStateToProps, {
    getData,
    getStatutoryUnitwiseReportData,
    getStatutoryUnitwiseReportExport,
})(UnitWiseReports);