import React from 'react'
import { Collapse, Select, Input, DatePicker, Card, Drawer, Button, Pagination, Table, Modal, Progress, Tooltip } from 'antd';
import DataTable from "react-data-table-component";
import { useState, useEffect, useRef, Fragment } from 'react';
import { FilterValues, getServiceProviderReportData, getRemarksData, getserviceProviderExport } from '../../../Store/Action/Report/ServiceProviderComplinaceReport';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import SimpleReactValidator from "simple-react-validator";
import moment from 'moment';
import { Toaster } from '../../../Libs/Toaster';
import { fullscreenmode, fullscreenexit, handleKeyDown } from './../../../Libs/fullscreenmode';
import { ExclamationCircleTwoTone, ExportOutlined, EyeOutlined, InfoCircleOutlined, ArrowRightOutlined, ArrowLeftOutlined, PlayCircleOutlined, DownloadOutlined, UpOutlined, FullscreenOutlined, FilterOutlined } from '@ant-design/icons';
import ScrollToTop from "react-scroll-to-top";

const mapStateToProps = (state) => ({
    filterValue: state.ServiceProviderComplianceReport.ServiceProviderComplianceReport
})
const ServiceProviderComplianceReports = (({
    FilterValues,
    filterValue,
    getServiceProviderReportData,
    getTaskwiseReportData,
    getUnitData,
    getRemarksData,
    getserviceProviderExport
}) => {
    const _ = require("lodash");
    const validator = useRef(new SimpleReactValidator());
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [RemarksModal, setRemarksModal] = useState(false);
    const [titledata, Settitledata] = useState()
    const [tableshow, setTableShow] = useState(false)
    const [childCompliceId, setChildComplinaceId] = useState([])
    const [key, setKey] = useState("1")
    let [index, setIndex] = useState(0)
    const [complianceID, setComplianceID] = useState([])
    const [uniqueData, setUniqueData] = useState([])
    const [filteredArray, setFilteredArray] = useState([])
    const [filteredArrayState, setfilteredArrayState] = useState([])
    const [current, setCurrent] = useState(1);
    const [pageState, setpageState] = useState(false);
    const [pageSize, setPageSize] = useState(25)
    const [paginationArray, setPaginationArray] = useState([])
    console.log(paginationArray, 'paginationArray');
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true
    })
    console.log(filterValue, 'filterValue');
    const [consolidatedData, setConsolidatedData] = useState({
        serviceProviderName: "",
        country: "",
        category: "",
        user_type: "",
        legel_entity: "",
        legal_entity_name: "",
        unit: '',
        user: "",
        domain: "",
        act: "",
        from_date: "",
        task_category: "",
        compliance_task: "",
        to_date: "",
        task_sub_category: "",
        compliance_frequency: "",
        compliance_task_status: "",
        division: "",
        domainName: "",
        countryName: '',
        complianceName: "",
        serviceProvider: ''

    })

    const [open, setOpen] = useState(false);
    const location = useLocation();
    const paramid = location.pathname;
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const sessionvalue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionvalue)
    const sessionArr = sessionParsedValue.entity_info
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [filterTaskAcc, setFilterTaskAcc] = useState(true);
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const [exitCollapse, setExitCollapse] = useState(false);
    const [domain, setDomain] = useState([])
    const [finalResult, SetfinalResult] = useState({
        Resultdata: []
    })
    const [checkboxValue, setCheckBoxValue] = useState([0, 1, 2, 3, 8, 9, 10, 11, 32, 35])
    const [exportButton, setExportButton] = useState(false)
    const [division, setDivision] = useState([])
    const [ComplianceTask, setComplianceTask] = useState([])
    const [Acts, setActs] = useState([])
    console.log(Acts, 'Acts');
    const [complianceTaskStatus, setcomplianceTaskStatus] = useState([])
    const [user, setuser] = useState([])
    console.log(user, 'user');
    const [serviceProvider, setServiceProvider] = useState([])
    const [frequency, setfrequency] = useState([])
    const [category, setCategoryList] = useState([])
    const [unitcode, setUnitcode] = useState([])
    const [units, setUnits] = useState([])
    const [disableButton, setDisableButton] = useState(false)
    const [statkey, setStatKey] = useState("0")

    const { Panel } = Collapse;
    const { Option } = Select;
    const onshow = () => {
        setExitCollapse(true);
        setFilterModalVisible(false);
        setFilterTaskAcc(false);
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            setTableShow(true)
            setKey(0)
            setStatKey(1)
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetServiceProviderWiseReport",
                        {
                            "country_id": 1,
                            "legal_entity_id": entityid,
                            "sp_id": Number(consolidatedData.serviceProvider),
                            "domain_id": Number(consolidatedData.domain),
                            "division_id": consolidatedData.division ? Number(consolidatedData.division) : 0,
                            "category_id": consolidatedData.category ? Number(consolidatedData.category) : 0,
                            "unit_id": consolidatedData.unit ? Number(consolidatedData.unit) : 0,
                            "parent_id": consolidatedData.act ? Number(consolidatedData.act) : 0,
                            "compliance_task": consolidatedData.compliance_task ? consolidatedData.compliance_task : null,
                            "user_id": consolidatedData.user ? Number(consolidatedData.user) : 0,
                            "due_from_date": moment(consolidatedData.from_date).format('DD-MMM-YYYY'),
                            "due_to_date": moment(consolidatedData.to_date._d).format('DD-MMM-YYYY'),
                            "task_status": consolidatedData.compliance_task_status ? Number(consolidatedData.compliance_task_status) : 'All',
                            "csv": false,
                            "from_count": 0,
                            "page_count": 0,
                            "list_check": null
                        }
                    ]
                }
            ]
            getServiceProviderReportData({
                payload: payload,
                paramid: paramid
            })
        }
    }

    const onClose = () => {
        setOpen(false);
    };

    const disablecondition = (e) => {
        var currentToDate = moment(consolidatedData.from_date).add(3, 'months')
        if (currentToDate < moment(e)) {
            setDisableButton(true)
        } else {
            setDisableButton(false)
        }
    }
    const setcancelFilter = () => {
        setFilterModalVisible(false);
        setFilterTaskAcc(false);
      };

    const showModal = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            setIsModalOpen(true);
        }
        else {
            Toaster.error('Please Select Required Fields')
        }
    };

    const handleOk = () => {
        setAddFormSubmit(true)
        setExportButton(true)
        if (validator.current.allValid()) {
            setTableShow(true)
            setKey(0)
            setStatKey(1)
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetServiceProviderWiseReport",
                        {
                            "country_id": localStorage.getItem('SelectedEntity') !== "All Legal Entity" ? sessionArr && sessionArr[0].c_id : Number(consolidatedData.country),
                            "legal_entity_id": entityid,
                            "sp_id": Number(consolidatedData.serviceProvider),
                            "domain_id": Number(consolidatedData.domain),
                            "division_id": consolidatedData.division ? Number(consolidatedData.division) : 0,
                            "category_id": consolidatedData.category ? Number(consolidatedData.category) : 0,
                            "unit_id": consolidatedData.unit ? Number(consolidatedData.unit) : 0,
                            "parent_id": consolidatedData.act ? Number(consolidatedData.act) : 0,
                            "compliance_task": consolidatedData.compliance_task ? consolidatedData.compliance_task : null,
                            "user_id": consolidatedData.user ? Number(consolidatedData.user) : 0,
                            "due_from_date": moment(consolidatedData.from_date).format('DD-MMM-YYYY'),
                            "due_to_date": moment(consolidatedData.to_date._d).format('DD-MMM-YYYY'),
                            "task_status": consolidatedData.compliance_task_status ? Number(consolidatedData.compliance_task_status) : "All",
                            "csv": true,
                            "from_count": 0,
                            "page_count": 0,
                            "list_check": checkboxValue
                        }
                    ]
                }
            ]
            getserviceProviderExport({
                payload: payload,
                paramid: paramid
            })
        }
        setIsModalOpen(false);
    };
    const checkBoxOnChange = (e) => {
        let temp = checkboxValue
        let tempIds = []
        if (temp.includes(Number(e.target.value)) == true) {
            for (let i in temp) {
                if (temp[i] == Number(e.target.value)) {
                    temp.splice(i, 1)
                }
            }
            setCheckBoxValue([...temp])
        } else {
            tempIds.push(Number(e.target.value))
            setCheckBoxValue([...checkboxValue, ...tempIds])
        }
    }

    const selectAllCheckBox = (checked) => {
        var ele = document.getElementsByName('chk');
        let tempAllIds = []
        if (checked == true) {
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox') {
                    ele[i].checked = true;
                    tempAllIds.push(Number(ele[i].value))
                }
            }
            setCheckBoxValue(tempAllIds)
        }
        else {
            var ele = document.getElementsByName('chk');
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox')
                    ele[i].checked = false;

            }
            setCheckBoxValue([])
        }
    }
    const handleCancel = () => {
        setIsModalOpen(false);
        setIsModalVisible(false)
        setRemarksModal(false)
    };
    useEffect(() => {
        if (entityid === null) {

            setCurrentEntity(consolidatedData.legel_entity)

        }
    }, [entityid])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') != "All Legal Entity") {
            setConsolidatedData({
                ...consolidatedData,
                legel_entity: localStorage.getItem('SelectedEntityid')
            })
        }

    }, [localStorage.getItem('SelectedEntity')])

    const ComplianceTaskOnChange = (data, value) => {
        setConsolidatedData({
            ...consolidatedData,
            compliance_task: data,
            complianceName: value.children
        })
        const payload = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetTaskWiseActReportFilters",
                    {
                        "country_id": localStorage.getItem('SelectedEntity') !== "All Legal Entity" ? sessionArr && sessionArr[0].c_id : Number(consolidatedData.country),
                        "legal_entity_id": entityid,
                        "domain_id": Number(consolidatedData.domain),
                        "comp_tsk_id": Number(data)
                    }
                ]
            }
        ]
        getUnitData({
            payload: payload,
            paramid: paramid
        })
    }
    useEffect(() => {
        let tempArr = []
        if (filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.sp_compliances && filterValue.ServiceProviderComplianceReportList.sp_compliances.length > 25) {
            filteredArrayState && filteredArrayState.length > 0 && filteredArrayState.map((item, i) => {
                let uniqueObjArray = [...new Map(item && item.length && item.map((items) => [items["compliance_id"], items])).values()];
                const uniqueIds = uniqueObjArray && uniqueObjArray.map((itemData) => {
                    return itemData.compliance_id
                })

                for (let i in uniqueIds) {
                    let filteredArr = _.find(item && item.length && item, { compliance_id: uniqueIds[i] })
                    tempArr.push(filteredArr)
                }
            })

            setUniqueData(tempArr)

        }
    }, [filteredArrayState])

    useEffect(() => {
        // setTempeditdata(editdata)
        let array = [];
        let finalarray = [];
        let datavalue = [];


        // setTempeditdata(editdata)
        if (paginationArray && paginationArray.length > 0) {
            const ab = paginationArray && paginationArray.length && paginationArray.map(function (item) {
                if ((array.indexOf(item.statutory_mapping) < 0))
                    array.push(item.statutory_mapping)
            })

            array.map((data, i) => {
                let temp = [];
                let entityArray = [];
                let datavalue = [];
                if (data) {
                    paginationArray && paginationArray.length && paginationArray.map((child, childIndex) => {
                        if (data === child.statutory_mapping) {
                            entityArray = {
                                "activity_date": child.activity_date,
                                "activity_status": child.activity_status,
                                "assignee_name": child.assignee_name,
                                "category_name": child.category_name,
                                "completion_date": child.completion_date,
                                "compliance_activity_id": child.compliance_activity_id,
                                "compliance_description": child.compliance_description,
                                "compliance_history_id": child.compliance_history_id,
                                "compliance_id": child.compliance_id,
                                "compliance_task": child.compliance_task,
                                "country_id": child.country_id,
                                "criticality_name": child.criticality_name,
                                "division_name": child.division_name,
                                "doc_ref_num": child.doc_ref_num,
                                "document_name": child.document_name,
                                "domain_id": child.domain_id,
                                "due_date": child.due_date,
                                "extend_due_date": child.extend_due_date,
                                "frequency_name": child.frequency_name,
                                "history_count": child.history_count,
                                "interim_count": child.interim_count,
                                "legal_entity_id": child.legal_entity_id,
                                "logo_url": child.logo_url,
                                "remarks_count": child.remarks_count,
                                "start_date": child.start_date,
                                "statutory_mapping": child.statutory_mapping,
                                "task_status": child.task_status,
                                "tz_name": child.tz_name,
                                "unit_address": child.unit_address,
                                "unit_id": child.unit_id,
                                " unit_name": child.unit_name,
                                "url": child.url,
                                'childId': childIndex,
                                'parentId': i
                            }
                            temp.push(entityArray)
                        }
                    })
                }

                datavalue = {
                    index: i,
                    parent: data,
                    child: temp
                }
                finalarray.push(datavalue)
            })
            Settitledata(finalarray)

        }

        // else {
        //     if (filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.sp_compliances && filterValue.ServiceProviderComplianceReportList.sp_compliances.length > 0) {
        //         const ab = filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.sp_compliances && filterValue.ServiceProviderComplianceReportList.sp_compliances.length > 0 && filterValue.ServiceProviderComplianceReportList.sp_compliances.map(function (item) {
        //             if ((array.indexOf(item.statutory_mapping) < 0))
        //                 array.push(item.statutory_mapping)
        //         })

        //         array.map((data, i) => {
        //             let temp = [];
        //             let entityArray = [];
        //             let datavalue = [];
        //             if (data) {
        //                 filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.sp_compliances && filterValue.ServiceProviderComplianceReportList.sp_compliances.length > 0 && filterValue.ServiceProviderComplianceReportList.sp_compliances.map((child, childIndex) => {
        //                     if (data === child.statutory_mapping) {
        //                         entityArray = {
        //                             "activity_date": child.activity_date,
        //                             "activity_status": child.activity_status,
        //                             "assignee_name": child.assignee_name,
        //                             "category_name": child.category_name,
        //                             "completion_date": child.completion_date,
        //                             "compliance_activity_id": child.compliance_activity_id,
        //                             "compliance_description": child.compliance_description,
        //                             "compliance_history_id": child.compliance_history_id,
        //                             "compliance_id": child.compliance_id,
        //                             "compliance_task": child.compliance_task,
        //                             "country_id": child.country_id,
        //                             "criticality_name": child.criticality_name,
        //                             "division_name": child.division_name,
        //                             "doc_ref_num": child.doc_ref_num,
        //                             "document_name": child.document_name,
        //                             "domain_id": child.domain_id,
        //                             "due_date": child.due_date,
        //                             "extend_due_date": child.extend_due_date,
        //                             "frequency_name": child.frequency_name,
        //                             "history_count": child.history_count,
        //                             "interim_count": child.interim_count,
        //                             "legal_entity_id": child.legal_entity_id,
        //                             "logo_url": child.logo_url,
        //                             "remarks_count": child.remarks_count,
        //                             "start_date": child.start_date,
        //                             "statutory_mapping": child.statutory_mapping,
        //                             "task_status": child.task_status,
        //                             "tz_name": child.tz_name,
        //                             "unit_address": child.unit_address,
        //                             "unit_id": child.unit_id,
        //                             " unit_name": child.unit_name,
        //                             "url": child.url,
        //                             'childId': childIndex,
        //                             'parentId': i
        //                         }
        //                         temp.push(entityArray)
        //                     }
        //                 })
        //             }

        //             datavalue = {
        //                 index: i,
        //                 parent: data,
        //                 child: temp
        //             }
        //             finalarray.push(datavalue)
        //         })
        //         Settitledata(finalarray)

        //     }
        // }


    }, [paginationArray])

    useEffect(() => {
        console.log(filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.sp_compliances && filterValue.ServiceProviderComplianceReportList.sp_compliances, 'filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.sp_compliances && filterValue.ServiceProviderComplianceReportList.sp_compliances');
        if (filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.sp_compliances && filterValue.ServiceProviderComplianceReportList.sp_compliances.length > 0) {
            var array = filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.sp_compliances.slice((current - 1) * pageSize, (current * pageSize))
            setPaginationArray(array)
        }
    }, [filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.sp_compliances, pageSize, current])
    useEffect(() => {
        if (pageState === true) {
            setIndex(pageSize * (current - 1))
        }
    }, [pageState, pageSize, current])

    useEffect(() => {
        if (paginationArray && paginationArray.length > 0) {
            let uniqueObjArray = [...new Map(paginationArray && paginationArray.length && paginationArray.map((item) => [item["unit_id"], item])).values()];
            const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                return item.unit_id
            })
            let tempArr = []
            for (let i in uniqueIds) {
                let filteredArr = _.filter(paginationArray, { unit_id: uniqueIds[i] })
                tempArr.push(filteredArr)
                setFilteredArray([...filteredArray, tempArr])
            }
        }
    }, [paginationArray])

    useEffect(() => {
        if (filteredArray && filteredArray.length > 0) {
            filteredArray && filteredArray.map((item, i) => {
                return (
                    setfilteredArrayState(item)
                )
            })
        }

    }, [filteredArray])


    const chilData = (id, unitId) => {
        setIsModalVisible(true)
        if (filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.sp_compliances && filterValue.ServiceProviderComplianceReportList.sp_compliances.length > 25) {
            let tempArr = []
            for (let i in filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.sp_compliances && filterValue.ServiceProviderComplianceReportList.sp_compliances) {
                if (filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.sp_compliances && filterValue.ServiceProviderComplianceReportList.sp_compliances[i].compliance_id == id) {
                    if (filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.sp_compliances && filterValue.ServiceProviderComplianceReportList.sp_compliances[i].unit_id == unitId) {
                        tempArr.push(filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.sp_compliances && filterValue.ServiceProviderComplianceReportList.sp_compliances[i])
                    }
                }
            }
            setChildComplinaceId(tempArr)

        }
    }
    const columns = [
        {
            title: 'User Name',
            ellipsis: true,
            width: '50px',
            dataIndex: 'assignee_name',
            key: 'assignee_name',
        },
        {
            title: 'Activity Status',
            ellipsis: true,
            width: '50px',
            dataIndex: 'activity_status',
            key: 'activity_status',
        },
        {
            title: 'Activity Date',
            ellipsis: true,
            width: '50px',
            dataIndex: 'activity_date',
            key: 'activity_date',
        },
        {
            title: 'Task Completion Date/Document issued Date',
            ellipsis: true,
            width: '50px',
            dataIndex: 'completion_date',
            key: 'completion_date',
        },

    ];
    const column = [
        {
            title: 'Activity Date',
            ellipsis: true,
            width: '50px',
            dataIndex: 'activity_on',
            key: 'activity_on',
        },
        {
            title: 'User Type',
            ellipsis: true,
            width: '50px',
            dataIndex: 'user_category_id',
            key: 'user_category_id',
            render: (row, index, record) => {

                return (
                    <>
                        <p>Assignee</p>
                    </>
                )
            }
        },
        {
            title: 'Reviewer Report',
            ellipsis: true,
            width: '50px',
            dataIndex: 'activity_date',
            key: 'activity_date',
        },
        {
            title: 'Remarks',
            ellipsis: true,
            width: '50px',
            dataIndex: 'remarks',
            key: 'remarks',
        },

    ];
    const item = [
        {
            compliance: "manoj"
        }
    ]
    const onChange = (key) => {
        console.log(key);
    };

    const onStatChange = (key) => {
        console.log(key);
    }

    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetServiceProviderWiseReportFilters",
                        {
                            "country_id": sessionArr && sessionArr.length && sessionArr[0].c_id,
                            "legal_entity_id": entityid
                        }
                    ]
                }
            ]
            if (entityid != 'null') {
                FilterValues({
                    payload: payload,
                    paramid: paramid
                })
            }
        }

    }, [isAuth, entityid])

    useEffect(() => {
        validator.current.showMessages()
    }, [])


    useEffect(() => {
        if (filterValue) {
            setServiceProvider(filterValue && filterValue.Data && filterValue.Data.sp_list)
            setComplianceTask(filterValue && filterValue.Data && filterValue.Data.compliance_task_wise_list)
            setDomain(filterValue && filterValue.Data && filterValue.Data.sp_domains_list)
            setActs(filterValue && filterValue.Data && filterValue.Data.sp_act_task_list)
            setfrequency(filterValue && filterValue.Data && filterValue.Data.compliance_frequency)
            setCategoryList(filterValue && filterValue.Data && filterValue.Data.categories_list)
            setcomplianceTaskStatus(filterValue && filterValue.Data && filterValue.Data.compliance_task_status)
            setUnitcode(filterValue && filterValue.Data && filterValue.Data.unit_code_label_list)
            // setUnits(filterValue && filterValue.Data && filterValue.Data.sp_unit_list)
            setDivision(filterValue && filterValue.Data && filterValue.Data.divisions_list)
            // setuser(filterValue && filterValue.Data && filterValue.Data.sp_users_list)

            let uniqueObjArray = [...new Map(filterValue && filterValue.Data && filterValue.Data.sp_users_list && filterValue.Data.sp_users_list.length > 0 && filterValue.Data.sp_users_list.map((item) => [item[" user_id"], item])).values()];
            setuser(uniqueObjArray)

        }


    }, [filterValue])

    const homescreen = () => {
        window.location.href = '/dashboard'
    }
    const filterHandle = () => {
        setFilterTaskAcc(true);
        setFilterModalVisible(true);
      };

    const [fullscreen, setfullscreen] = useState(false);

    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', handleKeyDown, false);
            document.addEventListener('webkitfullscreenchange', handleKeyDown, false);
            return () => {
                document.addEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('webkitfullscreenchange', handleKeyDown);
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode();
            setfullscreen(true);
        } else {
            fullscreenexit();
            setfullscreen(false);
        }
    }

    return (
        <>
            <ScrollToTop smooth color="red" component={<Tooltip title="Back to top"><UpOutlined /></Tooltip>} />
            <div className="page-wrapper" id="page-wrapper">
                <div className="page-titles pb-0 pt-1">
                    <div className="row">
                        <div className="col-lg-8 col-md-6 col-12 align-self-center">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 d-flex align-items-center">
                                    <li className="breadcrumb-item">
                                        <span className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        <span style={{ fontSize: '14px', fontWeight: "bold" }}>Report</span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: '15px', fontWeight: 'bold' }}>
                                        <span style={{ fontSize: "15px", fontWeight: "bold" }}>Service Provider Reports</span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                        <span>Service Provider Compliances</span>
                                    </li>

                                </ol>
                            </nav>
                        </div>
                        <div className="col-lg-4 text-end">  
                                              <Button
                className="mx-1"
                type="primary"
                shape="round"
                style={{
                  display: filterTaskAcc ? "none" : "initial",
                }}
                onClick={filterHandle}
              >
                <FilterOutlined /> Filter
              </Button>
                            <button onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black ms-2 full-mode fullscreenradius" >
                                <Tooltip placement="left" title="Full Screen Mode"><FullscreenOutlined /></Tooltip>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="container-fluid pt-0">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className={localStorage.getItem('currentTheme')} id='only-restricted' style={{ position: 'relative', padding: "15px 5px" }}>

                                <Collapse   style={{ display: exitCollapse ? "none" : "block" }} accordion defaultActiveKey={["1"]} className='report-collapse' onChange={onChange} >
                                    <Panel header="Service Provider Compliances" key={key} >
                                        <div className='col-md-12'>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <label><b>Country : </b> <span style={{ color: "red" }}>*</span></label>
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?

                                                        <div className="form-group">

                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                placeholder="Enter Country"
                                                                value={consolidatedData.country || undefined}
                                                                onChange={(data, value) => {
                                                                    setConsolidatedData({
                                                                        ...consolidatedData,
                                                                        country: data,
                                                                        countryName: value.children,
                                                                        serviceProviderName: "",
                                                                        category: "",
                                                                        user_type: "",
                                                                        legel_entity: "",
                                                                        legal_entity_name: "",
                                                                        unit: '',
                                                                        user: "",
                                                                        domain: "",
                                                                        act: "",
                                                                        from_date: "",
                                                                        task_category: "",
                                                                        compliance_task: "",
                                                                        to_date: "",
                                                                        task_sub_category: "",
                                                                        compliance_frequency: "",
                                                                        compliance_task_status: "",
                                                                        division: "",
                                                                        domainName: "",
                                                                        complianceName: "",
                                                                        serviceProvider: ''
                                                                    })

                                                                }
                                                                }
                                                                // value={compfie.user_Group}
                                                                style={{ width: '100%', marginTop: '2px' }}

                                                            >
                                                                {/* {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                                return (
                                                                    <Option key={item.c_id}>
                                                                        {item.c_name}
                                                                    </Option>
                                                                );
                                                            })} */}
                                                                <Option key={sessionArr && sessionArr.length && sessionArr[0].c_id}>
                                                                    {sessionArr && sessionArr.length > 0 && sessionArr[0].c_name}
                                                                </Option>
                                                            </Select>

                                                            {validator.current.message(
                                                                'Country',
                                                                consolidatedData.country,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Country Required ',
                                                                    }
                                                                })}

                                                        </div> :
                                                        <p>{sessionArr && sessionArr.length > 0 && sessionArr[0].c_name}</p>
                                                    }

                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Legal Entity : </b><span style={{ color: "red" }}>*</span></label>
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?

                                                        <div className="form-group">

                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                value={consolidatedData.legel_entity || undefined}
                                                                placeholder="Enter Legal Entity"
                                                                onChange={(data, value) => {
                                                                    // setCurrentEntity(data)
                                                                    setConsolidatedData({

                                                                        ...consolidatedData,
                                                                        legel_entity: data,
                                                                        legal_entity_name: value.children,
                                                                        serviceProviderName: "",
                                                                        category: "",
                                                                        user_type: "",
                                                                        unit: '',
                                                                        user: "",
                                                                        domain: "",
                                                                        act: "",
                                                                        from_date: "",
                                                                        task_category: "",
                                                                        compliance_task: "",
                                                                        to_date: "",
                                                                        task_sub_category: "",
                                                                        compliance_frequency: "",
                                                                        compliance_task_status: "",
                                                                        division: "",
                                                                        domainName: "",
                                                                        complianceName: "",
                                                                        serviceProvider: ''
                                                                    })
                                                                    setCurrentEntity(data)
                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                //value={Settings.le_id}
                                                                style={{ width: '100%', marginTop: '2px' }}
                                                                disabled={consolidatedData.country == '' ? true : false}

                                                            >
                                                                [
                                                                {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.le_id}>
                                                                            {item.le_name}
                                                                        </Option>
                                                                    );
                                                                })}


                                                            </Select>

                                                            {validator.current.message(
                                                                'legalEntity',
                                                                consolidatedData.legel_entity,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Legal Entity Required',
                                                                    }
                                                                })}

                                                        </div> :
                                                        <p>{localStorage.getItem('SelectedEntity')}</p>
                                                    }

                                                </div>
                                                <div className='col-md-4' >
                                                    <label><b>Service Provider : </b><span style={{ color: "red" }}>*</span></label>
                                                    <Select
                                                        // showSearch
                                                        // optionFilterProp="children"
                                                        // disabled={unit.legal_entity === "" ? true : false}
                                                        // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        allowClear={true}
                                                        size="default"
                                                        placeholder="Enter Service Provider"

                                                        onChange={(data, value) => {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                serviceProvider: data,
                                                                serviceProviderName: value.children,
                                                                category: "",
                                                                user_type: "",
                                                                unit: '',
                                                                user: "",
                                                                domain: "",
                                                                act: "",
                                                                from_date: "",
                                                                task_category: "",
                                                                compliance_task: "",
                                                                to_date: "",
                                                                task_sub_category: "",
                                                                compliance_frequency: "",
                                                                compliance_task_status: "",
                                                                division: "",
                                                                domainName: "",
                                                                complianceName: "",
                                                            })

                                                            let filteredDomain = _.filter(filterValue && filterValue.Data && filterValue.Data.sp_domains_list, { sp_id_optional: Number(data) })
                                                            setDomain(filteredDomain)

                                                            let filteredUnit = _.filter(filterValue && filterValue.Data && filterValue.Data.sp_unit_list, { sp_id_optional: Number(data) })
                                                            setUnits(filteredUnit)
                                                        }
                                                        }
                                                        value={consolidatedData.serviceProvider || undefined}
                                                        style={{ width: '100%', marginTop: '2px' }}
                                                        disabled={consolidatedData.legel_entity == '' ? true : false}
                                                    >
                                                        {serviceProvider && serviceProvider.length && serviceProvider.map((item, i) => {
                                                            return (
                                                                <Option key={item.sp_id}>
                                                                    {item.sp_name}
                                                                </Option>
                                                            );
                                                        })}

                                                    </Select>

                                                    {validator.current.message(
                                                        'serviceprovider',
                                                        consolidatedData.serviceProvider,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Service Provider Required',
                                                            }
                                                        })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: "5px" }}>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <label><b>Domain : </b> <span style={{ color: "red" }}>*</span></label>
                                                    <Select
                                                        disabled={consolidatedData.serviceProvider == '' ? true : false}
                                                        // showSearch
                                                        // optionFilterProp="children"
                                                        // disabled={unit.legal_entity === "" ? true : false}
                                                        // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        allowClear={true}
                                                        size="default"
                                                        value={consolidatedData.domain || undefined}
                                                        placeholder="Enter Domain"
                                                        onChange={(data, value) => {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                domain: data,
                                                                domainName: value.children,
                                                                category: "",
                                                                user_type: "",
                                                                unit: '',
                                                                user: "",
                                                                act: "",
                                                                from_date: "",
                                                                task_category: "",
                                                                compliance_task: "",
                                                                to_date: "",
                                                                task_sub_category: "",
                                                                compliance_frequency: "",
                                                                compliance_task_status: "",
                                                                division: "",
                                                                complianceName: "",
                                                            })
                                                            // let domainData = _.filter(taskCategory && taskCategory.length > 0 && taskCategory, { task_domain_id: Number(data) })
                                                            // setaskCategory(domainData)
                                                        }

                                                        }
                                                        // onChange={(data) => {
                                                        //     setValue({
                                                        //         ...value,
                                                        //         userGroup: data
                                                        //     })
                                                        //     setCompfie({
                                                        //         ...compfie,
                                                        //         user_Group: data
                                                        //     })
                                                        // }
                                                        // }
                                                        // value={compfie.user_Group}
                                                        style={{ width: '100%', marginTop: '2px' }}
                                                    >
                                                        {domain && domain.length && domain.map((item, i) => {
                                                            return (
                                                                <Option key={item.domain_id}>
                                                                    {item.domain_name}
                                                                </Option>
                                                            );
                                                        })}

                                                    </Select>
                                                    {validator.current.message(
                                                        'domain',
                                                        consolidatedData.domain,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Domain Required',
                                                            }
                                                        })}
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>From Date : </b><span style={{ color: "red" }}>*</span></label>
                                                    <DatePicker format={'DD-MMM-YYYY'} placeholder='DD-MMM-YYYY' style={{ width: '100%', marginTop: '2px' }}
                                                        allowClear={true}
                                                        value={consolidatedData.from_date ? moment(consolidatedData.from_date) : ''}
                                                        onChange={(date, dateString) => {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                from_date: dateString,
                                                                to_date: moment(dateString).add(3, 'months')
                                                            })

                                                        }}

                                                    />
                                                    {validator.current.message(
                                                        'from_date',
                                                        consolidatedData.from_date,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'From Date Required ',
                                                            }
                                                        })}
                                                </div>
                                                <div className='col-md-4'>

                                                    <label><b>To Date : </b> <span style={{ color: "red" }}>*</span></label>
                                                    <DatePicker allowClear={true} format={'DD-MMM-YYYY'} placeholder='DD-MMM-YYYY' style={{ width: '100%', marginTop: '2px' }}

                                                        onChange={(date, dateString) => {
                                                            disablecondition(dateString)
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                to_date: dateString._i
                                                            })
                                                        }}
                                                        value={consolidatedData.to_date}
                                                        disabledDate={(current) => {
                                                            return moment(consolidatedData.from_date).add(1, 'days') >= current ||
                                                                moment(consolidatedData.from_date).add(1, 'year') < current;
                                                        }}
                                                    />
                                                    {validator.current.message(
                                                        'To-Date',
                                                        consolidatedData.to_date,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'To Date Required ',
                                                            }
                                                        })}
                                                    {/* <input type="date" className='form-control' style={{ width: '300px' }} /> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: "5px" }}>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <label><b>Category : </b></label>
                                                    <Select
                                                        value={consolidatedData.category || undefined}
                                                        // showSearch
                                                        // optionFilterProp="children"
                                                        // disabled={unit.legal_entity === "" ? true : false}
                                                        // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        allowClear={true}
                                                        size="default"
                                                        placeholder="Enter category"

                                                        onChange={(data) => {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                category: data
                                                            })
                                                            // let unitData = _.filter(units && units.length > 0 && units, { category_id: Number(data) })
                                                            // setUnits(unitData)
                                                        }
                                                        }
                                                        // value={compfie.user_Group}
                                                        style={{ width: '100%', marginTop: '2px' }}

                                                    >
                                                        {category && category.length > 0 && category.map((item, i) => {
                                                            return (
                                                                <Option key={item.cat_id}>
                                                                    {item.cat_name}
                                                                </Option>
                                                            );
                                                        })}

                                                    </Select>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Division : </b></label>
                                                    <Select
                                                        disabled={consolidatedData.domain == '' ? true : false}
                                                        allowClear={true}
                                                        size="default"
                                                        value={consolidatedData.division || undefined}
                                                        placeholder="Enter Division"
                                                        onChange={(data) => {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                division: data
                                                            })
                                                            let categorydata = _.filter(category, { div_id: Number(data) })
                                                            setCategoryList(categorydata)
                                                        }
                                                        }
                                                        // onChange={(data) => {
                                                        //     setValue({
                                                        //         ...value,
                                                        //         userGroup: data
                                                        //     })
                                                        //     setCompfie({
                                                        //         ...compfie,
                                                        //         user_Group: data
                                                        //     })
                                                        // }
                                                        // }
                                                        // value={compfie.user_Group}
                                                        style={{ width: '100%', marginTop: '2px' }}
                                                    >
                                                        {division && division.length > 0 && division.map((item, i) => {
                                                            return (
                                                                <Option key={item.div_id}>
                                                                    {item.div_name}
                                                                </Option>
                                                            );
                                                        })}

                                                    </Select>


                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Unit : </b></label>
                                                    <Select
                                                        disabled={consolidatedData.domain == '' ? true : false}
                                                        value={consolidatedData.unit || undefined}
                                                        allowClear={true}
                                                        size="default"
                                                        placeholder="Enter Unit"
                                                        onChange={(data) => {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                unit: data
                                                            })
                                                        }
                                                        }
                                                        // onChange={(data) => {
                                                        //     setValue({
                                                        //         ...value,
                                                        //         userGroup: data
                                                        //     })
                                                        //     setCompfie({
                                                        //         ...compfie,
                                                        //         user_Group: data
                                                        //     })
                                                        // }
                                                        // }
                                                        // value={compfie.user_Group}
                                                        style={{ width: '100%', marginTop: '2px' }}

                                                    >
                                                        {units && units.length > 0 && units.map((item, i) => {
                                                            return (
                                                                <Option key={item.unit_id}>
                                                                    {`${item.unit_code}-${item.unit_name}`}
                                                                </Option>
                                                            );
                                                        })}

                                                    </Select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: "5px" }}>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <label><b>Act : </b></label>
                                                    <Select
                                                        disabled={consolidatedData.domain == '' ? true : false}
                                                        value={consolidatedData.act || undefined}
                                                        allowClear={true}
                                                        size="default"
                                                        placeholder="Enter Act"
                                                        onChange={(data) => {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                act: data
                                                            })
                                                        }
                                                        }
                                                        // onChange={(data) => {
                                                        //     setValue({
                                                        //         ...value,
                                                        //         userGroup: data
                                                        //     })
                                                        //     setCompfie({
                                                        //         ...compfie,
                                                        //         user_Group: data
                                                        //     })
                                                        // }
                                                        // }
                                                        // value={compfie.user_Group}
                                                        style={{ width: '100%', margintop: '5px' }}
                                                    >
                                                        {Acts && Acts.length > 0 && Acts.map((item, i) => {
                                                            return (
                                                                <Option key={item.parent_id}>
                                                                    {item.statutory_mapping}
                                                                </Option>
                                                            );
                                                        })}

                                                    </Select>

                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Compliance Task : </b></label>
                                                    {/* <Input placeholder="Enter Compliance Task" style={{ width: '280px' }}
                                                // onChange={(e) => {
                                                //     setConsolidatedDate({
                                                //         ...consolidateddata,
                                                //         compliance_task: e.target.value
                                                //     })
                                                // }}
                                                /> */}
                                                    <Input placeholder="Enter Compliance Task" style={{ width: '100%', marginTop: '2px' }}

                                                        // onChange={(e) => {
                                                        //     setConsolidatedDate({
                                                        //         ...consolidateddata,
                                                        //         compliance_task: e.target.value
                                                        //     })
                                                        // }}
                                                        disabled={consolidatedData.domain == '' ? true : false} />

                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Compliance Task Status : </b></label>
                                                    <Select
                                                        placeholder='Enter Compliance Task Status'
                                                        disabled={consolidatedData.domain == '' ? true : false}
                                                        value={consolidatedData.compliance_task_status || undefined}
                                                        onChange={(value) => {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                compliance_task_status: value
                                                            })
                                                        }}
                                                        // placeholder="Enter Domain Name"
                                                        style={{ width: '100%', marginTop: '2px' }}
                                                    >
                                                        <Option key='All'>All</Option>
                                                        {complianceTaskStatus && complianceTaskStatus.length && complianceTaskStatus.map((item, i) => {
                                                            return (
                                                                <Option key={item.task_status}>
                                                                    {item.task_status}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: "5px" }}>
                                            <div className='row'>
                                                <div className='col-md-4'>user
                                                    <label><b>User : </b></label>
                                                    <Select
                                                        disabled={consolidatedData.domain == '' ? true : false}
                                                        value={consolidatedData.user || undefined}
                                                        // showSearch
                                                        // optionFilterProp="children"
                                                        // disabled={unit.legal_entity === "" ? true : false}
                                                        // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        allowClear={true}
                                                        size="default"
                                                        placeholder="Enter User"
                                                        onChange={(data) => {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                user: data
                                                            })
                                                        }
                                                        }
                                                        // onChange={(data) => {
                                                        //     setValue({
                                                        //         ...value,
                                                        //         userGroup: data
                                                        //     })
                                                        //     setCompfie({
                                                        //         ...compfie,
                                                        //         user_Group: data
                                                        //     })
                                                        // }
                                                        // }
                                                        // value={compfie.user_Group}
                                                        style={{ width: '100%', marginTop: '2px' }}
                                                    >
                                                        {user && user.length && user.map((item, i) => {
                                                            return (
                                                                <Option key={item.user_id}>
                                                                    {item.user_name}
                                                                </Option>
                                                            );
                                                        })}

                                                    </Select>
                                                    {/* <input type="date" className='form-control' style={{ width: '300px' }} /> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12'>
                                            <div className='row'>



                                                <div className='col-md-4'></div>
                                                <div className='col-md-4'></div>
                                            </div>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: "10px" }}>
                                            <div className='row'>
                                                <div className='col-md-4'></div>
                                                <div className='col-md-4 popupbtncolour'>
                                                    <Button type="primary" shape="round" className={'addbutton' + localStorage.getItem('currentTheme')} style={{ marginLeft: "23%", marginRight: "10px" }} onClick={onshow}
                                                        icon={<EyeOutlined />}   >
                                                        Show
                                                    </Button>
                                                    <Button type="primary" shape="round" className={'addbutton' + localStorage.getItem('currentTheme')} style={{ background: "#32a852", borderColor: "#32a852" }} onClick={showModal}
                                                        icon={<ExportOutlined />} >
                                                        Export
                                                    </Button>

                                                </div>
                                                <div className='col-md-4'></div>
                                            </div>
                                        </div>

                                    </Panel>
                                </Collapse>
                                <Modal
                  onCancel={setcancelFilter}
                  footer={false}
                  title="Consolidated Report"
                  className={
                    "add-service-prv cons-report " +
                    localStorage.getItem("currentTheme")
                  }
                  visible={filterModalVisible}
                >   
                <div className='col-md-12'>
                    <div className='row'>
                        <div className='col-md-4'>
                            <label><b>Country : </b> <span style={{ color: "red" }}>*</span></label>
                            {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?

                                <div className="form-group">

                                    <Select
                                        allowClear={true}
                                        size="default"
                                        placeholder="Enter Country"
                                        value={consolidatedData.country || undefined}
                                        onChange={(data, value) => {
                                            setConsolidatedData({
                                                ...consolidatedData,
                                                country: data,
                                                countryName: value.children,
                                                serviceProviderName: "",
                                                category: "",
                                                user_type: "",
                                                legel_entity: "",
                                                legal_entity_name: "",
                                                unit: '',
                                                user: "",
                                                domain: "",
                                                act: "",
                                                from_date: "",
                                                task_category: "",
                                                compliance_task: "",
                                                to_date: "",
                                                task_sub_category: "",
                                                compliance_frequency: "",
                                                compliance_task_status: "",
                                                division: "",
                                                domainName: "",
                                                complianceName: "",
                                                serviceProvider: ''
                                            })

                                        }
                                        }
                                        // value={compfie.user_Group}
                                        style={{ width: '100%', marginTop: '2px' }}

                                    >
                                        {/* {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                        return (
                                            <Option key={item.c_id}>
                                                {item.c_name}
                                            </Option>
                                        );
                                    })} */}
                                        <Option key={sessionArr && sessionArr.length && sessionArr[0].c_id}>
                                            {sessionArr && sessionArr.length > 0 && sessionArr[0].c_name}
                                        </Option>
                                    </Select>

                                    {validator.current.message(
                                        'Country',
                                        consolidatedData.country,
                                        'required',
                                        {
                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                            messages: {
                                                required: 'Country Required ',
                                            }
                                        })}

                                </div> :
                                <p>{sessionArr && sessionArr.length > 0 && sessionArr[0].c_name}</p>
                            }

                        </div>
                        <div className='col-md-4'>
                            <label><b>Legal Entity : </b><span style={{ color: "red" }}>*</span></label>
                            {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?

                                <div className="form-group">

                                    <Select
                                        allowClear={true}
                                        size="default"
                                        value={consolidatedData.legel_entity || undefined}
                                        placeholder="Enter Legal Entity"
                                        onChange={(data, value) => {
                                            // setCurrentEntity(data)
                                            setConsolidatedData({

                                                ...consolidatedData,
                                                legel_entity: data,
                                                legal_entity_name: value.children,
                                                serviceProviderName: "",
                                                category: "",
                                                user_type: "",
                                                unit: '',
                                                user: "",
                                                domain: "",
                                                act: "",
                                                from_date: "",
                                                task_category: "",
                                                compliance_task: "",
                                                to_date: "",
                                                task_sub_category: "",
                                                compliance_frequency: "",
                                                compliance_task_status: "",
                                                division: "",
                                                domainName: "",
                                                complianceName: "",
                                                serviceProvider: ''
                                            })
                                            setCurrentEntity(data)
                                        }}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        //value={Settings.le_id}
                                        style={{ width: '100%', marginTop: '2px' }}
                                        disabled={consolidatedData.country == '' ? true : false}

                                    >
                                        [
                                        {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                            return (
                                                <Option key={item.le_id}>
                                                    {item.le_name}
                                                </Option>
                                            );
                                        })}


                                    </Select>

                                    {validator.current.message(
                                        'legalEntity',
                                        consolidatedData.legel_entity,
                                        'required',
                                        {
                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                            messages: {
                                                required: 'Legal Entity Required',
                                            }
                                        })}

                                </div> :
                                <p>{localStorage.getItem('SelectedEntity')}</p>
                            }

                        </div>
                        <div className='col-md-4' >
                            <label><b>Service Provider : </b><span style={{ color: "red" }}>*</span></label>
                            <Select
                                // showSearch
                                // optionFilterProp="children"
                                // disabled={unit.legal_entity === "" ? true : false}
                                // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                allowClear={true}
                                size="default"
                                placeholder="Enter Service Provider"

                                onChange={(data, value) => {
                                    setConsolidatedData({
                                        ...consolidatedData,
                                        serviceProvider: data,
                                        serviceProviderName: value.children,
                                        category: "",
                                        user_type: "",
                                        unit: '',
                                        user: "",
                                        domain: "",
                                        act: "",
                                        from_date: "",
                                        task_category: "",
                                        compliance_task: "",
                                        to_date: "",
                                        task_sub_category: "",
                                        compliance_frequency: "",
                                        compliance_task_status: "",
                                        division: "",
                                        domainName: "",
                                        complianceName: "",
                                    })

                                    let filteredDomain = _.filter(filterValue && filterValue.Data && filterValue.Data.sp_domains_list, { sp_id_optional: Number(data) })
                                    setDomain(filteredDomain)

                                    let filteredUnit = _.filter(filterValue && filterValue.Data && filterValue.Data.sp_unit_list, { sp_id_optional: Number(data) })
                                    setUnits(filteredUnit)
                                }
                                }
                                value={consolidatedData.serviceProvider || undefined}
                                style={{ width: '100%', marginTop: '2px' }}
                                disabled={consolidatedData.legel_entity == '' ? true : false}
                            >
                                {serviceProvider && serviceProvider.length && serviceProvider.map((item, i) => {
                                    return (
                                        <Option key={item.sp_id}>
                                            {item.sp_name}
                                        </Option>
                                    );
                                })}

                            </Select>

                            {validator.current.message(
                                'serviceprovider',
                                consolidatedData.serviceProvider,
                                'required',
                                {
                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                    messages: {
                                        required: 'Service Provider Required',
                                    }
                                })}
                        </div>
                    </div>
                </div>
                <div className='col-md-12' style={{ marginTop: "5px" }}>
                    <div className='row'>
                        <div className='col-md-4'>
                            <label><b>Domain : </b> <span style={{ color: "red" }}>*</span></label>
                            <Select
                                disabled={consolidatedData.serviceProvider == '' ? true : false}
                                // showSearch
                                // optionFilterProp="children"
                                // disabled={unit.legal_entity === "" ? true : false}
                                // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                allowClear={true}
                                size="default"
                                value={consolidatedData.domain || undefined}
                                placeholder="Enter Domain"
                                onChange={(data, value) => {
                                    setConsolidatedData({
                                        ...consolidatedData,
                                        domain: data,
                                        domainName: value.children,
                                        category: "",
                                        user_type: "",
                                        unit: '',
                                        user: "",
                                        act: "",
                                        from_date: "",
                                        task_category: "",
                                        compliance_task: "",
                                        to_date: "",
                                        task_sub_category: "",
                                        compliance_frequency: "",
                                        compliance_task_status: "",
                                        division: "",
                                        complianceName: "",
                                    })
                                    // let domainData = _.filter(taskCategory && taskCategory.length > 0 && taskCategory, { task_domain_id: Number(data) })
                                    // setaskCategory(domainData)
                                }

                                }
                                // onChange={(data) => {
                                //     setValue({
                                //         ...value,
                                //         userGroup: data
                                //     })
                                //     setCompfie({
                                //         ...compfie,
                                //         user_Group: data
                                //     })
                                // }
                                // }
                                // value={compfie.user_Group}
                                style={{ width: '100%', marginTop: '2px' }}
                            >
                                {domain && domain.length && domain.map((item, i) => {
                                    return (
                                        <Option key={item.domain_id}>
                                            {item.domain_name}
                                        </Option>
                                    );
                                })}

                            </Select>
                            {validator.current.message(
                                'domain',
                                consolidatedData.domain,
                                'required',
                                {
                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                    messages: {
                                        required: 'Domain Required',
                                    }
                                })}
                        </div>
                        <div className='col-md-4'>
                            <label><b>From Date : </b><span style={{ color: "red" }}>*</span></label>
                            <DatePicker format={'DD-MMM-YYYY'} placeholder='DD-MMM-YYYY' style={{ width: '100%', marginTop: '2px' }}
                                allowClear={true}
                                value={consolidatedData.from_date ? moment(consolidatedData.from_date) : ''}
                                onChange={(date, dateString) => {
                                    setConsolidatedData({
                                        ...consolidatedData,
                                        from_date: dateString,
                                        to_date: moment(dateString).add(3, 'months')
                                    })

                                }}

                            />
                            {validator.current.message(
                                'from_date',
                                consolidatedData.from_date,
                                'required',
                                {
                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                    messages: {
                                        required: 'From Date Required ',
                                    }
                                })}
                        </div>
                        <div className='col-md-4'>

                            <label><b>To Date : </b> <span style={{ color: "red" }}>*</span></label>
                            <DatePicker allowClear={true} format={'DD-MMM-YYYY'} placeholder='DD-MMM-YYYY' style={{ width: '100%', marginTop: '2px' }}

                                onChange={(date, dateString) => {
                                    disablecondition(dateString)
                                    setConsolidatedData({
                                        ...consolidatedData,
                                        to_date: dateString._i
                                    })
                                }}
                                value={consolidatedData.to_date}
                                disabledDate={(current) => {
                                    return moment(consolidatedData.from_date).add(1, 'days') >= current ||
                                        moment(consolidatedData.from_date).add(1, 'year') < current;
                                }}
                            />
                            {validator.current.message(
                                'To-Date',
                                consolidatedData.to_date,
                                'required',
                                {
                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                    messages: {
                                        required: 'To Date Required ',
                                    }
                                })}
                            {/* <input type="date" className='form-control' style={{ width: '300px' }} /> */}
                        </div>
                    </div>
                </div>
                <div className='col-md-12' style={{ marginTop: "5px" }}>
                    <div className='row'>
                        <div className='col-md-4'>
                            <label><b>Category : </b></label>
                            <Select
                                value={consolidatedData.category || undefined}
                                // showSearch
                                // optionFilterProp="children"
                                // disabled={unit.legal_entity === "" ? true : false}
                                // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                allowClear={true}
                                size="default"
                                placeholder="Enter category"

                                onChange={(data) => {
                                    setConsolidatedData({
                                        ...consolidatedData,
                                        category: data
                                    })
                                    // let unitData = _.filter(units && units.length > 0 && units, { category_id: Number(data) })
                                    // setUnits(unitData)
                                }
                                }
                                // value={compfie.user_Group}
                                style={{ width: '100%', marginTop: '2px' }}

                            >
                                {category && category.length > 0 && category.map((item, i) => {
                                    return (
                                        <Option key={item.cat_id}>
                                            {item.cat_name}
                                        </Option>
                                    );
                                })}

                            </Select>
                        </div>
                        <div className='col-md-4'>
                            <label><b>Division : </b></label>
                            <Select
                                disabled={consolidatedData.domain == '' ? true : false}
                                allowClear={true}
                                size="default"
                                value={consolidatedData.division || undefined}
                                placeholder="Enter Division"
                                onChange={(data) => {
                                    setConsolidatedData({
                                        ...consolidatedData,
                                        division: data
                                    })
                                    let categorydata = _.filter(category, { div_id: Number(data) })
                                    setCategoryList(categorydata)
                                }
                                }
                                // onChange={(data) => {
                                //     setValue({
                                //         ...value,
                                //         userGroup: data
                                //     })
                                //     setCompfie({
                                //         ...compfie,
                                //         user_Group: data
                                //     })
                                // }
                                // }
                                // value={compfie.user_Group}
                                style={{ width: '100%', marginTop: '2px' }}
                            >
                                {division && division.length > 0 && division.map((item, i) => {
                                    return (
                                        <Option key={item.div_id}>
                                            {item.div_name}
                                        </Option>
                                    );
                                })}

                            </Select>


                        </div>
                        <div className='col-md-4'>
                            <label><b>Unit : </b></label>
                            <Select
                                disabled={consolidatedData.domain == '' ? true : false}
                                value={consolidatedData.unit || undefined}
                                allowClear={true}
                                size="default"
                                placeholder="Enter Unit"
                                onChange={(data) => {
                                    setConsolidatedData({
                                        ...consolidatedData,
                                        unit: data
                                    })
                                }
                                }
                                // onChange={(data) => {
                                //     setValue({
                                //         ...value,
                                //         userGroup: data
                                //     })
                                //     setCompfie({
                                //         ...compfie,
                                //         user_Group: data
                                //     })
                                // }
                                // }
                                // value={compfie.user_Group}
                                style={{ width: '100%', marginTop: '2px' }}

                            >
                                {units && units.length > 0 && units.map((item, i) => {
                                    return (
                                        <Option key={item.unit_id}>
                                            {`${item.unit_code}-${item.unit_name}`}
                                        </Option>
                                    );
                                })}

                            </Select>
                        </div>
                    </div>
                </div>
                <div className='col-md-12' style={{ marginTop: "5px" }}>
                    <div className='row'>
                        <div className='col-md-4'>
                            <label><b>Act : </b></label>
                            <Select
                                disabled={consolidatedData.domain == '' ? true : false}
                                value={consolidatedData.act || undefined}
                                allowClear={true}
                                size="default"
                                placeholder="Enter Act"
                                onChange={(data) => {
                                    setConsolidatedData({
                                        ...consolidatedData,
                                        act: data
                                    })
                                }
                                }
                                // onChange={(data) => {
                                //     setValue({
                                //         ...value,
                                //         userGroup: data
                                //     })
                                //     setCompfie({
                                //         ...compfie,
                                //         user_Group: data
                                //     })
                                // }
                                // }
                                // value={compfie.user_Group}
                                style={{ width: '100%', margintop: '5px' }}
                            >
                                {Acts && Acts.length > 0 && Acts.map((item, i) => {
                                    return (
                                        <Option key={item.parent_id}>
                                            {item.statutory_mapping}
                                        </Option>
                                    );
                                })}

                            </Select>

                        </div>
                        <div className='col-md-4'>
                            <label><b>Compliance Task : </b></label>
                            {/* <Input placeholder="Enter Compliance Task" style={{ width: '280px' }}
                        // onChange={(e) => {
                        //     setConsolidatedDate({
                        //         ...consolidateddata,
                        //         compliance_task: e.target.value
                        //     })
                        // }}
                        /> */}
                            <Input placeholder="Enter Compliance Task" style={{ width: '100%', marginTop: '2px' }}

                                // onChange={(e) => {
                                //     setConsolidatedDate({
                                //         ...consolidateddata,
                                //         compliance_task: e.target.value
                                //     })
                                // }}
                                disabled={consolidatedData.domain == '' ? true : false} />

                        </div>
                        <div className='col-md-4'>
                            <label><b>Compliance Task Status : </b></label>
                            <Select
                                placeholder='Enter Compliance Task Status'
                                disabled={consolidatedData.domain == '' ? true : false}
                                value={consolidatedData.compliance_task_status || undefined}
                                onChange={(value) => {
                                    setConsolidatedData({
                                        ...consolidatedData,
                                        compliance_task_status: value
                                    })
                                }}
                                // placeholder="Enter Domain Name"
                                style={{ width: '100%', marginTop: '2px' }}
                            >
                                <Option key='All'>All</Option>
                                {complianceTaskStatus && complianceTaskStatus.length && complianceTaskStatus.map((item, i) => {
                                    return (
                                        <Option key={item.task_status}>
                                            {item.task_status}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </div>
                    </div>
                </div>
                <div className='col-md-12' style={{ marginTop: "5px" }}>
                    <div className='row'>
                        <div className='col-md-4'>
                            <label><b>User : </b></label>
                            <Select
                                disabled={consolidatedData.domain == '' ? true : false}
                                value={consolidatedData.user || undefined}
                                // showSearch
                                // optionFilterProp="children"
                                // disabled={unit.legal_entity === "" ? true : false}
                                // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                allowClear={true}
                                size="default"
                                placeholder="Enter User"
                                onChange={(data) => {
                                    setConsolidatedData({
                                        ...consolidatedData,
                                        user: data
                                    })
                                }
                                }
                                // onChange={(data) => {
                                //     setValue({
                                //         ...value,
                                //         userGroup: data
                                //     })
                                //     setCompfie({
                                //         ...compfie,
                                //         user_Group: data
                                //     })
                                // }
                                // }
                                // value={compfie.user_Group}
                                style={{ width: '100%', marginTop: '2px' }}
                            >
                                {user && user.length && user.map((item, i) => {
                                    return (
                                        <Option key={item.user_id}>
                                            {item.user_name}
                                        </Option>
                                    );
                                })}

                            </Select>
                            {/* <input type="date" className='form-control' style={{ width: '300px' }} /> */}
                        </div>
                    </div>
                </div>
                <div className='col-md-12'>
                    <div className='row'>



                        <div className='col-md-4'></div>
                        <div className='col-md-4'></div>
                    </div>
                </div>
                <div className='col-md-12' style={{ marginTop: "10px" }}>
                    <div className='row'>
                        <div className='col-md-4'></div>
                        <div className='col-md-4 popupbtncolour' >
                            <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')} style={{ marginLeft: "23%", marginRight: "10px" }} onClick={onshow}
                                icon={<EyeOutlined />}   >
                                Show
                            </Button>
                            <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')} style={{ background: "#32a852", borderColor: "#32a852" }} onClick={showModal}
                                icon={<ExportOutlined />} >
                                Export
                            </Button>

                        </div>
                        <div className='col-md-4'></div>
                    </div>
                </div>

            </Modal>
                                {/* {tableshow === true ?
                                    <Collapse defaultActiveKey={["1"]} className='report-collapse' onChange={onStatChange} style={{ marginTop: '1%', backgroundColor: '#5daae3' }}>
                                        <Panel header="Overall Statistics" key={statkey}>
                                            <div className="col-md-12" style={{ marginLeft: '5%', marginRight: '5%' }}>
                                                <div className="row m-2">
                                                    <div className="col-md-3">
                                                        <Progress type="circle" percent={30} width={90} format={(percent) => `${110}`} />
                                                        <h4>Complied</h4>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <Progress style={{ marginLeft: '8%' }} type="circle" percent={20} width={90} status="exception" format={(percent) => `${30}`} />
                                                        <h4 style={{ marginRight: '12%' }}>Delayed Complied</h4>

                                                    </div>
                                                    <div className="col-md-3">
                                                        <Progress type="circle" percent={100} width={90} format={(percent) => `${80}`} />
                                                        <h4>Inprogress</h4>

                                                    </div>
                                                    <div className="col-md-3">
                                                        <Progress type="circle" percent={80} width={90} strokeColor={{ '0%': '#f5b342', '100%': '#f5b342' }} format={(percent) => `${75}`} />
                                                        <h4>Not Complied</h4>

                                                    </div>
                                                </div>
                                            </div>
                                        </Panel>
                                    </Collapse> : ''
                                } */}

                                {tableshow == true ?
                                    <Card className='rounded-3'>

                                        <div className='col-md-12 mt-2 mb-1' >
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <label><b>Legal Entity :</b> {localStorage.getItem('SelectedEntity') === 'All Legal Entity' ? consolidatedData.legal_entity_name : localStorage.getItem('SelectedEntity')}</label>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Domain :</b> {consolidatedData.domainName}</label>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Service Provider :</b> {consolidatedData.serviceProviderName}</label>
                                                </div>
                                                <div className='col-md-2'>
                                                    <label><b>Country :</b> {sessionArr && sessionArr[0].c_name}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={localStorage.getItem("currentTheme") + ' ant-table-wrapper'}>
                                            <div className='ant-table ant-table-bordered ant-table-fixed-header'>
                                                <div className='ant-table-container'>
                                                    <div className='ant-table-content userprivclass1'>
                                                        <table>
                                                            <colgroup>
                                                                <col style={{ width: '10px' }}></col>
                                                            </colgroup>
                                                            <thead className="ant-table-thead antable-head">
                                                                <tr>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '50px' }}> # </th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '250px' }}>Compliance Task</th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '120px' }}>Criticality</th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '140px' }}>Frequency</th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '140px' }}><center>Due Date</center></th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '150px' }}>Compliance Task Status</th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '120px' }}><center>User Name</center></th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '140px' }}><center>Activity Status</center></th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '150px' }}><center>Activity Date</center></th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '200px' }}><center>Uploaded Document</center></th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '240px' }}><center>Task Completion Date/Document issued Date</center></th>
                                                                </tr>
                                                            </thead>
                                                            {filteredArrayState && filteredArrayState.length > 0 ?
                                                                filteredArrayState.map((item, i) => {
                                                                    return (
                                                                        <tbody className='ant-table-tbody' key={i}>
                                                                            <tr className="bg-lightdark">
                                                                                <td colSpan="100%">
                                                                                    <div className='bg-white shadow-sm border-radius-reports'>
                                                                                        <div className="col-md-12">
                                                                                            <div className='row m-2'>
                                                                                                <div className='col-md-3' >
                                                                                                    <label><b>Unit :</b> {item[0].unit_name}</label>
                                                                                                </div>
                                                                                                <div className='col-md-3' >
                                                                                                    <label><b>Category :</b> {item[0].category_name}</label>
                                                                                                </div>
                                                                                                <div className='col-md-3' >
                                                                                                    <label><b>Division :</b> {item[0].division_name}</label>
                                                                                                </div>
                                                                                                <div className='col-md-3' >
                                                                                                    <label><b>Time Zone :</b> {item[0].tz_name}</label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            {titledata && titledata.map((itemdata, i) => {
                                                                                let data = _.find(itemdata.child, { unit_id: item[0].unit_id })
                                                                                if (data != undefined) {
                                                                                    if (data.statutory_mapping == itemdata.parent) {
                                                                                        return <Fragment>

                                                                                            <tr className='ant-table-row ant-table-row-level-0'>

                                                                                                <td colSpan={12} className='ant-table-cell'><b>{itemdata.parent}</b></td>
                                                                                            </tr>

                                                                                            {itemdata.child.map((items) => {
                                                                                                if (item[0].unit_id == items.unit_id) {
                                                                                                    let array = []

                                                                                                    for (let i in filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.sp_compliances && filterValue.ServiceProviderComplianceReportList.sp_compliances) {
                                                                                                        if (filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.sp_compliances && filterValue.ServiceProviderComplianceReportList.sp_compliances[i].compliance_id == items.compliance_id) {
                                                                                                            if (filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.sp_compliances && filterValue.ServiceProviderComplianceReportList.sp_compliances[i].unit_id == items.unit_id) {
                                                                                                                array.push(filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.sp_compliances && filterValue.ServiceProviderComplianceReportList.sp_compliances[i])
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                    return <tr>
                                                                                                        <td className='ant-table-cell'><center>{index + 1}</center></td>
                                                                                                        <td className='ant-table-cell'><Tooltip title={items.compliance_description}>
                                                                                                            <ExclamationCircleTwoTone />
                                                                                                        </Tooltip> &nbsp;{array.length > 1 ? <a href='javascript:;' onClick={() => {
                                                                                                            chilData(items.compliance_id, items.unit_id)
                                                                                                        }}>{items.compliance_task}</a> : <a >{items.compliance_task}</a>}</td>
                                                                                                        <td className='ant-table-cell'>{items.criticality_name}</td>
                                                                                                        <td className='ant-table-cell'>{items.frequency_name}</td>
                                                                                                        <td className='ant-table-cell'><center>{items.due_date}</center></td>
                                                                                                        <td className='ant-table-cell'><center>{items.task_status}</center></td>
                                                                                                        <td className='ant-table-cell'><center>{items.assignee_name}</center></td>
                                                                                                        {items.history_count < 1 ? <td className='ant-table-cell'><center>{items.activity_status}</center></td> :

                                                                                                            <td className='ant-table-cell'><Tooltip title="Click to view Remarks history">
                                                                                                                <ExclamationCircleTwoTone />
                                                                                                            </Tooltip>  <a onClick={() => {
                                                                                                                const payload = [
                                                                                                                    authtoken,
                                                                                                                    {
                                                                                                                        "session_token": authtoken,
                                                                                                                        "request": [
                                                                                                                            "GetComplianceRemarksHistoryData",
                                                                                                                            {
                                                                                                                                "legal_entity_id": entityid,
                                                                                                                                "unit_id": items.unit_id,
                                                                                                                                "compliance_history_id": items.compliance_history_id
                                                                                                                            }
                                                                                                                        ]
                                                                                                                    }
                                                                                                                ]

                                                                                                                getRemarksData({
                                                                                                                    payload: payload,
                                                                                                                    paramid: paramid
                                                                                                                })
                                                                                                                setRemarksModal(true)
                                                                                                            }}>{items.activity_status}</a></td>
                                                                                                        }
                                                                                                        <td className='ant-table-cell'><center>{items.activity_date}</center></td>
                                                                                                        <td className='ant-table-cell'><center>{items.url}</center></td>
                                                                                                        <td className='ant-table-cell'><center>{items.completion_date}</center></td>
                                                                                                        {/* </tr> */}
                                                                                                        <span hidden>{index = index + 1}</span>

                                                                                                    </tr>
                                                                                                }

                                                                                            })

                                                                                            }
                                                                                        </Fragment>
                                                                                    }
                                                                                }
                                                                            })
                                                                            }

                                                                        </tbody>
                                                                    )
                                                                }) :
                                                                <tr>
                                                                    <td colSpan="100%" style={{ paddingTop: '15px', textAlign: 'center', fontSize: '14px', fontWeight: 'bold', paddingBottom: '15px' }}>No Records Found</td>
                                                                </tr>
                                                            }
                                                        </table>
                                                        <div>
                                                            {/* <button
                                                    className="btn btn-light-success rounded-pill px-4 text-success"
                                                    onClick={submitrec}>Submit</button> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row mt-3'>
                                            {index != 0 ?
                                                <><div className='col-md-6'>
                                                    <label>Showing {pageSize * (current - 1) + 1} to {index} of {filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.total_count} entries</label>
                                                </div>
                                                    <div className='col-md-6 text-right'>
                                                        <Pagination
                                                            current={current}
                                                            showSizeChanger={true}
                                                            // showQuickJumper={true}
                                                            onShowSizeChange={() => {
                                                                setCurrent(1)
                                                                // setpageState(true)
                                                            }}
                                                            pageSizeOptions={[25, 50, 100]}
                                                            hideOnSinglePage={filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.total_count > 25 ? false : true}
                                                            defaultPageSize={25}
                                                            onChange={(page, pageSizes) => {
                                                                setpageState(true)
                                                                setCurrent(pageSize !== pageSizes ? 1 : page);
                                                                setPageSize(pageSizes)
                                                            }} total={filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.total_count}
                                                        // showTotal={(total) => `Showing 1 to ${index} of ${filterValue && filterValue.count} entries`}
                                                        />


                                                    </div>
                                                </> : ""}
                                        </div>
                                    </Card>
                                    : ''}

                                {/* <Modal title="Select Fields" visible={isModalOpen} footer={null} onOk={handleOk} onCancel={handleCancel} width={100}>
                                <div id="form">
                                    <input type="hidden" class="popup_mode" autocomplete="off" />
                                    <div id="form-list">
                                        <div class="portlet-body p-t-0">
                                            <div class="table-responsive">
                                                <table id="datatable-responsive2" class="table table-striped dt-responsive nowrap" cellspacing="0" width="50%">
                                                    <thead>
                                                        <tr class="custom-modal-title">
                                                            <th>
                                                                <input id="select_all" width="100%" type="checkbox" autocomplete="off" onClick={(e) => {
                                                                    let checked = e.target.checked
                                                                    selectAllCheckBox(checked)
                                                                }} /> Select All
                                                            </th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>

                                                    <tbody id="tbody-form-list"><tr><th>
                                                        <input id="column_1" name='chk' type="checkbox" disabled="disabled" checked value='1' onChange={(e) => (e) => checkBoxOnChange(e)} /> Legal Entity</th>
                                                        <th><input id="column_2" name='chk' type="checkbox" disabled="disabled" checked value='2' onChange={(e) => checkBoxOnChange(e)} /> Unit Code</th></tr>
                                                        <tr><th><input id="column_3" name='chk' type="checkbox" disabled="disabled" checked value='3' onChange={(e) => checkBoxOnChange(e)} /> Unit Name</th>
                                                            <th><input id="column_4" name='chk' type="checkbox" value='4' onChange={(e) => checkBoxOnChange(e)} /> Unit Code 1</th></tr><tr><th>
                                                                <input id="column_5" name='chk' type="checkbox" value='5' onChange={(e) => checkBoxOnChange(e)} /> Unit Code 2</th><th>
                                                                <input id="column_6" name='chk' type="checkbox" value='6' onChange={(e) => checkBoxOnChange(e)} /> Unit Code 3</th></tr>
                                                        <tr><th><input id="column_7" name='chk' type="checkbox" value='7' onChange={(e) => checkBoxOnChange(e)} /> Unit Code 4</th>
                                                            <th><input id="column_8" name='chk' type="checkbox" disabled="disabled" checked value='8' onChange={(e) => checkBoxOnChange(e)} /> Location</th></tr>
                                                        <tr><th><input id="column_9" name='chk' type="checkbox" disabled="disabled" value='9' checked onChange={(e) => checkBoxOnChange(e)} /> Act / Rules</th>
                                                            <th><input id="column_10" name='chk' type="checkbox" disabled="disabled" value='10' checked onChange={(e) => checkBoxOnChange(e)} /> Compliance Task</th></tr>
                                                        <tr><th><input id="column_11" name='chk' type="checkbox" disabled="disabled" value='11' checked onChange={(e) => checkBoxOnChange(e)} /> Frequency</th>
                                                            <th><input id="column_12" name='chk' type="checkbox" value='12' onChange={(e) => checkBoxOnChange(e)} /> Periodicity</th></tr>
                                                        <tr><th><input id="column_13" name='chk' type="checkbox" value='13' onChange={(e) => checkBoxOnChange(e)} /> Criticality</th>
                                                            <th><input id="column_14" name='chk' type="checkbox" value='14' onChange={(e) => checkBoxOnChange(e)} /> Division</th></tr>
                                                        <tr><th><input id="column_15" name='chk' type="checkbox" value='15' onChange={(e) => checkBoxOnChange(e)} /> Category</th>
                                                            <th><input id="column_16" name='chk' type="checkbox" value='16' onChange={(e) => checkBoxOnChange(e)} /> Assigned by</th></tr>
                                                        <tr><th><input id="column_17" name='chk' type="checkbox" value='17' onChange={(e) => checkBoxOnChange(e)} /> Assigned To</th>
                                                            <th><input id="column_18" name='chk' type="checkbox" value='18' onChange={(e) => checkBoxOnChange(e)} /> Assigned Date</th></tr>
                                                        <tr><th><input id="column_19" name='chk' type="checkbox" value='19' onChange={(e) => checkBoxOnChange(e)} /> Assignee</th>
                                                            <th><input id="column_20" name='chk' type="checkbox" value='20' onChange={(e) => checkBoxOnChange(e)} /> Completed on</th></tr>
                                                        <tr><th><input id="column_21" name='chk' type="checkbox" value='21' onChange={(e) => checkBoxOnChange(e)} /> Concurrer</th>
                                                            <th><input id="column_22" name='chk' type="checkbox" value='22' onChange={(e) => checkBoxOnChange(e)} /> Concurred on</th></tr>
                                                        <tr><th><input id="column_23" name='chk' type="checkbox" value='23' onChange={(e) => checkBoxOnChange(e)} /> Approver</th>
                                                            <th><input id="column_24" name='chk' type="checkbox" value='24' onChange={(e) => checkBoxOnChange(e)} /> Approved on</th></tr>
                                                        <tr><th><input id="column_25" name='chk' type="checkbox" value='25' onChange={(e) => checkBoxOnChange(e)} /> Activity Status</th>
                                                            <th><input id="column_26" name='chk' type="checkbox" value='26' onChange={(e) => checkBoxOnChange(e)} /> Start Date</th></tr>
                                                        <tr><th><input id="column_27" name='chk' type="checkbox" value='27' onChange={(e) => checkBoxOnChange(e)} /> Due Date</th>
                                                            <th><input id="column_28" name='chk' type="checkbox" value='28' onChange={(e) => checkBoxOnChange(e)} /> Extend Due Date</th></tr>
                                                        <tr><th><input id="column_29" name='chk' type="checkbox" value='29' onChange={(e) => checkBoxOnChange(e)} />Task Completion Date / Document Issued Date</th>
                                                            <th><input id="column_30" name='chk' type="checkbox" value='30' onChange={(e) => checkBoxOnChange(e)} />Month</th></tr>
                                                        <tr><th><input id="column_31" name='chk' type="checkbox" value='31' onChange={(e) => checkBoxOnChange(e)} /> Validity Date </th>
                                                            <th><input id="column_32" name='chk' type="checkbox" disabled="disabled" checked value='32' onChange={(e) => checkBoxOnChange(e)} /> Statutory Status</th></tr>
                                                        <tr><th><input id="column_33" name='chk' type="checkbox" value='33' onChange={(e) => checkBoxOnChange(e)} />Duration</th>
                                                            <th><input id="column_34" name='chk' type="checkbox" value='34' onChange={(e) => checkBoxOnChange(e)} /> Document Reference Number</th></tr>
                                                        <tr><th><input id="column_33" name='chk' type="checkbox" disabled="disabled" checked value='35' onChange={(e) => checkBoxOnChange(e)} />Remarks</th></tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="form-actions">
                                    <Button type="primary" shape="round" className='addbutton'
                                        style={{ background: "#198754", borderColor: "#198754", marginLeft: '40%' }}
                                        icon={<PlayCircleOutlined />} size='default' onClick={handleOk}> Submit
                                    </Button>
                                </div>
                            </Modal> */}
                                <Modal title="Select Fields" className={'add-service-prv ' + localStorage.getItem('currentTheme')} visible={isModalOpen} footer={null} onOk={handleOk} onCancel={handleCancel} width={100}>
                                    <div className='row'>
                                        <div className='col-lg-3'><input id="select_all" width="100%" type="checkbox" autocomplete="off" onClick={(e) => {
                                            let checked = e.target.checked
                                            selectAllCheckBox(checked)
                                        }} /> Select All</div>
                                    </div>
                                    <br />
                                    <div className='row'>
                                        <div className='col-lg-3'><input id="column_1" name='chk' type="checkbox" checked disabled="disabled" value='1' onChange={(e) => (e) => checkBoxOnChange(e)} />Legal Entity </div>
                                        <div className='col-lg-3'><input id="column_2" name='chk' type="checkbox" checked disabled="disabled" value='2' onChange={(e) => checkBoxOnChange(e)} /> Unit Code</div>
                                        <div className='col-lg-3'><input id="column_7" name='chk' type="checkbox" value='3' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> Unit Name</div>
                                        <div className='col-lg-3'><input id="column_7" name='chk' type="checkbox" value='4' onChange={(e) => checkBoxOnChange(e)} />Unit Code 1</div>

                                    </div>
                                    <br />
                                    <div className='row'>
                                        <div className='col-lg-3'><input id="column_8" name='chk' type="checkbox" value='5' onChange={(e) => checkBoxOnChange(e)} /> Unit Code 2 </div>
                                        <div className='col-lg-3'><input id="column_9" name='chk' type="checkbox" value='6' onChange={(e) => checkBoxOnChange(e)} /> Unit Code 3 </div>
                                        <div className='col-lg-3'><input id="column_10" name='chk' type="checkbox" value='7' onChange={(e) => checkBoxOnChange(e)} />Unit Code 4</div>
                                        <div className='col-lg-3'><input id="column_29" name='chk' type="checkbox" value='8' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} />Location</div>
                                    </div>
                                    <br />
                                    <div className='row'>
                                        <div className='col-lg-3'><input id="column_32" name='chk' type="checkbox" value='9' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> Act / Rules</div>
                                        <div className='col-lg-3'><input id="column_3" name='chk' type="checkbox" value='10' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> Compliance Task </div>
                                        <div className='col-lg-3'><input id="column_4" name='chk' type="checkbox" value='11' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> Frequency</div>
                                        <div className='col-lg-3'><input id="column_5" name='chk' type="checkbox" value='12' onChange={(e) => checkBoxOnChange(e)} /> Periodicaly </div>
                                    </div>
                                    <br />
                                    <div className='row'>
                                        <div className='col-lg-3'><input id="column_14" name='chk' type="checkbox" value='13' onChange={(e) => checkBoxOnChange(e)} /> Criticality</div>
                                        <div className='col-lg-3'><input id="column_15" name='chk' type="checkbox" value='14' onChange={(e) => checkBoxOnChange(e)} /> Division</div>
                                        <div className='col-lg-3'><input id="column_16" name='chk' type="checkbox" value='15' onChange={(e) => checkBoxOnChange(e)} /> Category</div>
                                        <div className='col-lg-3'><input id="column_17" name='chk' type="checkbox" value='16' onChange={(e) => checkBoxOnChange(e)} /> Assignee By</div>
                                    </div>
                                    <br />
                                    <div className='row'>
                                        <div className='col-lg-3'><input id="column_18" name='chk' type="checkbox" value='17' onChange={(e) => checkBoxOnChange(e)} /> Assigned To</div>
                                        <div className='col-lg-3'><input id="column_19" name='chk' type="checkbox" value='18' onChange={(e) => checkBoxOnChange(e)} /> Assigned Date</div>
                                        <div className='col-lg-3'><input id="column_20" name='chk' type="checkbox" value='19' onChange={(e) => checkBoxOnChange(e)} /> Assignee </div>
                                        <div className='col-lg-3'><input id="column_21" name='chk' type="checkbox" value='20' onChange={(e) => checkBoxOnChange(e)} /> Completed On</div>
                                    </div>
                                    <br />
                                    <div className='row'>
                                        <div className='col-lg-3'><input id="column_22" name='chk' type="checkbox" value='21' onChange={(e) => checkBoxOnChange(e)} /> Concurrer </div>
                                        <div className='col-lg-3'><input id="column_23" name='chk' type="checkbox" value='22' onChange={(e) => checkBoxOnChange(e)} /> Concurred On </div>
                                        <div className='col-lg-3'><input id="column_24" name='chk' type="checkbox" value='23' onChange={(e) => checkBoxOnChange(e)} /> Approver </div>
                                        <div className='col-lg-3'><input id="column_25" name='chk' type="checkbox" value='24' onChange={(e) => checkBoxOnChange(e)} /> Approved On</div>
                                    </div>
                                    <br />
                                    <div className='row'>
                                        <div className='col-lg-3'><input id="column_26" name='chk' type="checkbox" value='25' onChange={(e) => checkBoxOnChange(e)} /> Activity Status</div>
                                        <div className='col-lg-3'><input id="column_27" name='chk' type="checkbox" value='26' onChange={(e) => checkBoxOnChange(e)} /> Start Date</div>
                                        <div className='col-lg-3'><input id="column_28" name='chk' type="checkbox" value='27' onChange={(e) => checkBoxOnChange(e)} /> Due Date </div>
                                        <div className='col-lg-3'><input id="column_28" name='chk' type="checkbox" value='28' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} />Extend Due Date </div>

                                    </div>
                                    <br />
                                    <div className='row'>
                                        <div className='col-lg-3'><input id="column_31" name='chk' type="checkbox" value='29' onChange={(e) => checkBoxOnChange(e)} /> Task Completion Date / Document Issued Date</div>
                                        <div className='col-lg-3'><input id="column_31" name='chk' type="checkbox" value='30' onChange={(e) => checkBoxOnChange(e)} /> Month</div>
                                        <div className='col-lg-3'><input id="column_31" name='chk' type="checkbox" value='31' onChange={(e) => checkBoxOnChange(e)} /> Validity Date</div>
                                        <div className='col-lg-3'><input id="column_31" name='chk' type="checkbox" value='32' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> Validity Date</div>

                                    </div>
                                    <br />
                                    <div className='row'>
                                        <div className='col-lg-3'><input id="column_31" name='chk' type="checkbox" value='33' onChange={(e) => checkBoxOnChange(e)} /> Duration</div>
                                        <div className='col-lg-3'><input id="column_31" name='chk' type="checkbox" value='34' onChange={(e) => checkBoxOnChange(e)} /> Document Reference Number</div>
                                        <div className='col-lg-3'><input id="column_31" name='chk' type="checkbox" value='35' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> Remarks</div>


                                    </div>
                                    <div className="form-actions text-center popupbtncolour">
                                        <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')}
                                            icon={<PlayCircleOutlined />} size='default' onClick={handleOk}> Submit
                                        </Button>
                                    </div>

                                </Modal>
                                <Modal footer={null} visible={isModalVisible} onCancel={handleCancel} onOk={() => {
                                    setIsModalVisible(false)
                                }} >
                                    <Table style={{ marginTop: '25px' }}
                                        className={localStorage.getItem('currentTheme')}
                                        columns={columns}
                                        dataSource={childCompliceId}
                                        bordered
                                        pagination={false} />
                                </Modal>
                                <Modal visible={RemarksModal} onCancel={handleCancel} onOk={() => {
                                    setRemarksModal(false)
                                }} >
                                    <Table
                                        className='userprivclass'
                                        columns={column}
                                        dataSource={filterValue && filterValue.RemarksData && filterValue.RemarksData.remarks_history}
                                        bordered
                                        pagination={false} />
                                </Modal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
})
export default connect(mapStateToProps, {
    FilterValues,
    getServiceProviderReportData,
    getserviceProviderExport,
    getRemarksData
})(ServiceProviderComplianceReports);






