import { Fragment, useState, useEffect, useRef, useMemo } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom'
import { Modal, Input, Table, Button, Space, Tooltip, Select, DatePicker, Avatar, Segmented, Collapse, Card, } from 'antd';
import moment from 'moment';
import Swal from 'sweetalert2';
import { getVendorClosureList, saveVendorClosureData, saveVendorTempClosureData } from './../../Store/Action/Master/CV_VendorClosure';
import { InfoCircleOutlined, PlayCircleOutlined, PlusOutlined, ClockCircleOutlined, SearchOutlined, PlusCircleOutlined, AudioOutlined, FilterFilled, ExclamationCircleTwoTone, UpOutlined, FullscreenOutlined } from '@ant-design/icons';
import { GetRemarksData } from './../../Store/Action/Transcations/Taskrecall'
import SimpleReactValidator from 'simple-react-validator';
import { UNIT_CLOSURE_LIST } from '../../Store/types';
import ScrollToTop from 'react-scroll-to-top';
import { fullscreenmode, fullscreenexit, handleKeyDown } from "../../Libs/fullscreenmode";

const mapStateToProps = (state) => ({
    unitClosureList: state.VendorClosure.vendorClosure.VendorClosureList,
    filterrecall: state.recallfilters.recallfilters

});
const VendorClosure = ({
    saveVendorClosureData,
    saveVendorTempClosureData,
    getVendorClosureList,
    unitClosureList,
    filterrecall: { recallremarks },
    GetRemarksData

}) => {
    const { Option } = Select;
    const _ = require("lodash");
    const location = useLocation();
    const { Panel } = Collapse;
    const paramid = location.pathname;
    const formValidator = useRef(new SimpleReactValidator());
    const formValidator1 = useRef(new SimpleReactValidator());
    const formValidator2 = useRef(new SimpleReactValidator());
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const [activedata, setActivedata] = useState('0')
    const [inactivedata, setInactivedata] = useState('0')
    const [closeddata, setCloseddata] = useState('0')
    const [remarks, setRemarks] = useState('')
    const [type, setType] = useState('active')
    //const entityid = localStorage.getItem('SelectedEntityid');
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArr = sessionParsedValue.entity_info
    const [show, setShow] = useState(false)
    const [filteredArray, setFilteredArray] = useState([])
    const [baseData, setBaseData] = useState([]);
    console.log(baseData, 'baseData');
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [AddFormSubmit1, setAddFormSubmit1] = useState(false);
    const [AddFormSubmit2, setAddFormSubmit2] = useState(false);
    const [FormSubmit, setFormSubmit] = useState(false);
    const [remarksModal, setRemarksModal] = useState(false);
    const [reactivatedArray, setReActivatedArray] = useState([])
    const [activeArray, setActiveArray] = useState([])
    const [inActiveArray, setInActiveArray] = useState([])
    const [blockedArray, setBlockedArray] = useState([])
    const [unBlockedArray, setUnBlockedArray] = useState([])
    const [reactiveArray, setReactiveArray] = useState([])
    const [resetPaginationToggle, setResetPaginationToggle] = useState(
        false
    );
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true
    })
    const [modalData, setModalData] = useState({
        password: '',
        reason: '',
        unit_id: '',
        legal_entity: '',
        selectedtype: '',
    })
    const [modalData1, setModalData1] = useState({
        password: '',
        reason: '',
        unit_id: '',
        legal_entity: '',
        selectedtype: '',
        date: ''
    })
    const [remarksvalue, Setremarksvalue] = useState();
    const [page, setPage] = useState(1);
    const [modalVisible, setModaVisible] = useState(false)
    const [modalVisible1, setModaVisible1] = useState(false)
    const [entityid, setCurrentEntity] = useState("");
    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') != "All Legal Entity") {
            setCurrentEntity(localStorage.getItem('SelectedEntityid'))
        }
    }, [localStorage.getItem('SelectedEntity')])
    const { Search } = Input;
    const suffix = (
        <AudioOutlined
            style={{
                fontSize: 16,
                color: '#1890ff',
            }}
        />
    );
    const [searchText, setSearchText] = useState({
        data: '',
        index: 0
    });

    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (e, confirm, dataIndex, setSelectedKeys) => {
        setSelectedKeys(e.target.value ? [e.target.value] : [])
        confirm({ closeDropdown: false });
        // setSearchText({
        //     ...searchText,
        //     data: selectedKeys[0]
        // });
        // setSearchedColumn(dataIndex);
    };
    const [iconEnable, setIconEnable] = useState(false);
    const [clearText, setClearText] = useState('');
    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});
    const tablehandleChange = (pagination, filters, sorter) => {
        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText({
            data: '',
            index: searchText.index + 1
        });
    };
    const columns1 = [
        {
            title: '#',
            dataIndex: false,
            align: 'center',
            width: '50px',
            // ellipsis: true,
            render: (text, record, index) => {
                return (page - 1) * 10 + index + 1
            }
        },
        {
            title: 'Vendor',
            dataIndex: 'unit_code',
            key: 'unit_code',
            // width:''
            // ellipsis: true,
            // align: 'left',
            // ...getColumnSearchProps('unit_code', 'Unit'),
            filteredValue: filteredInfo.unit_code || null,
            sorter: (a, b) => a.unit_code.localeCompare(b.unit_code),
            sortOrder: sortedInfo.columnKey === 'unit_code' ? sortedInfo.order : null,
            // width: '200px',
            render: (text, record) => {
                return (
                    <Fragment>
                        <p className='mb-0' style={{ whiteSpace: 'unset' }}><span><Tooltip title={`${record.address},${record.postal_code}`}><ExclamationCircleTwoTone style={{ fontSize: "13px", marginRight: "5px", marginTop: "5px" }} /></Tooltip>&nbsp;</span>
                            <Tooltip title={`${text}-${record.unit_name}`}>{text}-{record.unit_name}</Tooltip></p>
                    </Fragment>
                )
            }
        },
        {
            title: 'TimeLine',
            key: 'status',
            align: "center",
            width: "170px",
            dataIndex: 'status',
            key: 'status',
            render: (text, record, index) => {
                // return <Alert message={`${record.unblock_days} - Days left`} type="error" showIcon={<ClockCircleOutlined />} />
                // return <p style={{color:'#fc4b6c'}}><ClockCircleOutlined /> - {`${record.validity_days} - Days left`}</p>
                // if ((30 - record.validity_days) <= 1) {
                //     return <p className='mb-0' style={{ color: '#fc4b6c' }}><ClockCircleOutlined className='I' id='I' />  {`${record.validity_days} - Days left`}</p>
                // }
                // else {
                //     return <p className='mb-0' style={{ color: '#fc4b6c' }}><ClockCircleOutlined />  {`${(30 - record.validity_days)} - Days left`}</p>
                // }
                if ((30 - record.validity_days) >= 20) {
                    return <p className='mb-0 text-success'><ClockCircleOutlined />  {`${(30 - record.validity_days)} - Days left`}</p>
                }
                else if ((30 - record.validity_days) >= 6) {
                    return <p className='mb-0 text-primary'><ClockCircleOutlined />  {`${(30 - record.validity_days)} - Days left`}</p>
                }
                else if ((30 - record.validity_days) <= 5) {
                    return <p className='mb-0 text-danger'><ClockCircleOutlined className='I' id='I' /> &nbsp; {`${(30 - record.validity_days)} - Days left`}</p>
                }
            },
        },
        {
            // title: <><label>Actions</label><Tooltip className='serviveproidertooltipclass' style={{ marginBotton: '5px', marginleft: '5px' }} placement="topRight" title={"Actions for Close/Closed/Reactive"}><InfoCircleOutlined /></Tooltip></>,
            title: 'Closure',
            dataIndex: 'currentStatus',
            // className: 'actiontd',
            width: '120px',
            align: 'center',
            // width:"100px",
            render: (text, record) => {
                return (
                    <Fragment>
                        {record.is_active == 0 ?
                            <Button
                                type="primary"
                                style={{ padding: "5px", width: "80px", textAlign: "center", borderRadius: '15px' }}
                                onClick={() => {
                                    setShow(true)
                                    closeById(record.unit_id, "closed");
                                }}
                                title=""
                            >Close
                            </Button> : record.is_active == 1 && record.validity_days > 30 ?
                                <span>Closed</span>
                                :
                                <Tooltip title={(30 - record.validity_days) + '-' + 'Days Left'} color='royalblue'>
                                    <Button
                                        type="primary"
                                        style={{ padding: "5px", width: "80px", textAlign: "center", borderRadius: '15px' }}
                                        onClick={() => {
                                            setModalData({
                                                password: '',
                                                reason: ''
                                            })
                                            setShow(true)
                                            closeById(record.unit_id, "reactive");
                                        }}
                                        title=""
                                    >Reactivate
                                    </Button>
                                </Tooltip>

                        }
                    </Fragment>
                )
            }
        }
    ];

    const getColumnSearchProps = (dataIndex, placeholder) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div>
                <Input
                    ref={searchInput}
                    placeholder={'Search ' + placeholder}
                    value={selectedKeys[0]}
                    onChange={(e) => handleSearch(e, confirm, dataIndex, setSelectedKeys)}
                />

            </div>
        ),
        filterIcon: (filtered) => (
            // <FilterFilled
            //     style={{
            //         color: filtered ? '#1890ff' : undefined,
            //     }}
            // />
            <SearchOutlined
                style={{
                    // color: filtered ? 'red' : undefined,
                    //  color: 'black',
                }}
            />
        ),
        onFilter: (value, record) => record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text
    });


    useEffect(() => {
        // if (type === 'active' && baseData.length > 0) {
        let activeData = _.filter(baseData, { is_active: 1 })
        setActiveArray(activeData)
        // }
        // else if (type === 'inactive' && baseData.length > 0) {
        let inactiveData = _.filter(baseData, { is_active: 0 })
        setInActiveArray(inactiveData)
        // }
        // else if (type === 'unblock' && baseData.length > 0) {
        let unblockData = _.filter(baseData, { currentStatus: 1 })
        setUnBlockedArray(unblockData)
        // }
        // else {
        let block = _.filter(baseData, { currentStatus: 0 })
        setBlockedArray(block)
        // }
        let reactive = _.filter(baseData, { currentStatus: 2 })
        setReactiveArray(reactive)
    }, [baseData])

    const columns = [
        {
            title: '#',
            dataIndex: false,
            align: 'center',
            width: '50px',
            // ellipsis: true,
            render: (text, record, index) => {
                return (page - 1) * 10 + index + 1
            }
        },
        // {
        //     title: 'Business Group',
        //     dataIndex: 'business_group_name',
        //     key: 'business_group_name',
        //     width: '170px',
        //     ellipsis: true,
        //     align: 'center',
        //     ...getColumnSearchProps('business_group_name','Business Group'),
        //     sorter: (a, b) => a.business_group_name && b.business_group_name != null ? a.business_group_name.localeCompare(b.business_group_name) : "",
        //     render: (text, record) => {
        //         return (
        //             <Fragment>
        //                 {text != null ? text : '-'}
        //             </Fragment>
        //         )
        //     }
        // },
        // {
        //     title: 'Legal Entity',
        //     dataIndex: 'legal_entity_name',
        //     key: 'legal_entity_name',
        //     width: '150px',
        //     ellipsis: true,
        //     ...getColumnSearchProps('legal_entity_name','Legal Entity'),
        //     sorter: (a, b) => a.legal_entity_name.localeCompare(b.legal_entity_name),
        // },
        {
            title: 'Division',
            dataIndex: 'division_name',
            key: 'division_name',
            align: 'center',
            // ellipsis: true,
            width: '120px',
            ...getColumnSearchProps('division_name', 'Division'),
            sorter: (a, b) => a.division_name.localeCompare(b.division_name),
            render: (text, record) => {
                return (
                    <Fragment>
                        {text != null ?
                            <Tooltip title={text}>{text}</Tooltip> : '-'}
                    </Fragment>
                )
            }
        },
        {
            title: 'Category',
            dataIndex: 'category_name',
            key: 'category_name',
            width: '140px',
            align: 'center',
            // ellipsis: true,
            ...getColumnSearchProps('category_name', 'Category'),
            sorter: (a, b) => a.category_name.localeCompare(b.category_name),
        },
        {
            title: 'Vendor',
            dataIndex: 'unit_code',
            key: 'unit_code',
            // align: 'center',
            // ellipsis: true,
            // align: 'left',
            ...getColumnSearchProps('unit_code', 'Vendor'),
            sorter: (a, b) => a.unit_code.localeCompare(b.unit_code),
            width: '170px',
            render: (text, record) => {
                return (
                    <Fragment>
                        <span style={{ whiteSpace: 'unset' }}><span><Tooltip title={`${record.address},${record.postal_code}`}><ExclamationCircleTwoTone style={{ fontSize: "13px", marginRight: "5px", marginTop: "5px" }} /></Tooltip>&nbsp;</span>
                            <Tooltip title={`${text}-${record.unit_name}`}>{text}-{record.unit_name}</Tooltip></span>
                    </Fragment>
                )
            }
        },
        {
            title: 'Status',
            dataIndex: 'is_active',
            key: 'is_active',
            ellipsis: true,
            width: '80px',
            align: 'center',
            // filters: [
            //     {
            //         text: 'Active',
            //         value: 0,
            //     },
            //     {
            //         text: 'Inactive',
            //         value: 1,
            //     },
            // ],
            // onFilter: (value, record) => record.is_active === value,
            // filterSearch: false,
            render: (text, record) => {
                return (
                    <Fragment>
                        {
                            text == 0 ? "Active" : "In Active"
                        }
                    </Fragment>
                )
            }
        },
        // {
        //     title: 'Closure',
        //     dataIndex: 'is_active',
        //     width: '120px',
        //     align: 'center',
        //     filters: [
        //         {
        //             text: 'Close',
        //             value: 0,
        //         },
        //         {
        //             text: 'Closed',
        //             value: 2,
        //         },
        //         {
        //             text: 'Reactivate',
        //             value: 1,
        //         },
        //     ],
        //     onFilter: (value, record) => record.is_active === value,
        //     filterSearch: false,
        //     render: (text, record) => {
        //         return (
        //             <Fragment>
        //                 {record.is_active == '0' ?
        //                     <Button
        //                         type="primary"
        //                         style={{ padding: "5px", width: "80px", textAlign: "center", borderRadius: '15px' }}
        //                         onClick={() => {
        //                             closeById(record.unit_id, "closed");
        //                         }}
        //                         title=""
        //                     >Close
        //                     </Button> :
        //                     <Tooltip title={(30 - record.validity_days_main) + '-' + 'Days Left'} color='royalblue'>
        //                         <Button
        //                             type="primary"
        //                             style={{ padding: "5px", width: "80px", textAlign: "center", borderRadius: '15px' }}
        //                             onClick={() => {
        //                                 setModalData({
        //                                     password: '',
        //                                     reason: ''
        //                                 })
        //                                 closeById(record.unit_id, "reactive");
        //                             }}
        //                             title=""
        //                         >Reactivate
        //                         </Button>
        //                     </Tooltip>

        //                 }
        //             </Fragment>
        //         )
        //     }
        // },
        {
            // title: <><label>Actions</label><Tooltip className='serviveproidertooltipclass' style={{ marginBotton: '5px', marginleft: '5px' }} placement="topRight" title={"Actions for Close/Closed/Reactive"}><InfoCircleOutlined /></Tooltip></>,
            title: 'Closure',
            dataIndex: 'currentStatus',
            // className: 'actiontd',
            width: '80px',
            align: 'center',
            // width:"100px",
            // filters: [
            //     {
            //         text: 'Close',
            //         value: 0,
            //     },
            //     {
            //         text: 'Closed',
            //         value: 1,
            //     },
            //     {
            //         text: 'Reactivate',
            //         value: 2,
            //     },
            // ],
            // filteredValue: filteredInfo.currentStatus,
            // onFilter: (value, record) => record.currentStatus == value,//filterfunction(value, record),
            filterSearch: false,
            render: (text, record) => {
                return (
                    <Fragment>
                        {record.is_active == 0 ?
                            <Button
                                type="primary"
                                style={{ padding: "5px", width: "80px", textAlign: "center", borderRadius: '15px' }}
                                onClick={() => {
                                    setShow(true)
                                    closeById(record.unit_id, "closed");
                                }}
                                title=""
                            >Close
                            </Button> : record.is_active == 1 && record.validity_days_main > 30 ?
                                <span>Closed</span>
                                :
                                <Tooltip title={(30 - record.validity_days_main) + '-' + 'Days Left'}>
                                    <Button
                                        type="primary"
                                        style={{ padding: "5px", width: "80px", textAlign: "center", borderRadius: '15px' }}
                                        onClick={() => {
                                            setModalData({
                                                password: '',
                                                reason: ''
                                            })
                                            setShow(true)
                                            closeById(record.unit_id, "reactive");
                                        }}
                                        title=""
                                    >Reactivate
                                    </Button>
                                </Tooltip>

                        }
                    </Fragment>
                )
            }
        },
        {
            title: 'Temporary Closure',
            dataIndex: 'is_vendor_closed',
            // className: 'actiontd',
            width: '120px',
            align: 'center',
            // width:"100px",
            filters: [
                {
                    text: 'Close',
                    value: 0,
                },
                {
                    text: 'Closed',
                    value: 2,
                },
                {
                    text: 'Reactivate',
                    value: 1,
                },
            ],
            onFilter: (value, record) => record.is_vendor_closed === value,
            filterSearch: false,
            render: (text, record) => {
                return (
                    <Fragment>
                        {text == 0 ? <>
                            <Button disabled={record.is_active == 1 ? true : false}
                                type="primary"
                                style={{ padding: "5px", width: "80px", textAlign: "center", marginLeft: '15px', borderRadius: '15px' }}
                                onClick={() => {
                                    setShow(true)
                                    closeById1(record.unit_id, "closed");
                                }}
                                title=""
                            >Close
                            </Button> </> : <>
                            <Tooltip title={
                                <table style={{ width: "100%", borderCollapse: 'collapse', border: '1px solid #636262', tableLayout: 'auto' }}>
                                    <tbody>
                                        <tr>
                                            <th style={{ border: '1px solid #636262', marginLeft: '5%', marginRight: '5%' }}><center>Closed On</center></th>
                                            <th style={{ border: '1px solid #636262' }}><center>Reactivated On</center></th>

                                        </tr>
                                        <tr>
                                            <td style={{ border: '1px solid #636262' }}><center>{record.vendor_closed_date ? record.vendor_closed_date : '-'}</center></td>
                                            <td style={{ border: '1px solid #636262' }}><center>{record.vendor_activation_date ? record.vendor_activation_date : '-'}</center></td>
                                        </tr>


                                    </tbody>
                                </table>
                            }> <i class="bi-info-circle-fill"></i>&nbsp;</Tooltip>
                            <Tooltip title={(365 - record.validity_days) + '-' + 'Days Left'} color='royalblue'>
                                <Button disabled={record.is_active == 1 ? true : false}
                                    type="primary"
                                    style={{ padding: "5px", width: "80px", textAlign: "center", borderRadius: '15px' }}
                                    onClick={() => {
                                        setModalData1({
                                            password: '',
                                            reason: ''
                                        })
                                        setShow(true)
                                        closeById1(record.unit_id, "reactive");
                                    }}
                                    title=""
                                >Reactivate
                                </Button>
                            </Tooltip>
                        </>
                        }
                    </Fragment>
                )
            }
        },
        {
            title: 'Pending Tasks',
            dataIndex: 'category_name',
            key: 'category_name',
            width: '100px',

            // ellipsis: true,
            // ...getColumnSearchProps('category_name'),
            // sorter: (a, b) => a.category_name.localeCompare(b.category_name),
        },

    ];

    const RemarksColumn = [
        {
            title: 'S.No',
            dataIndex: false,
            width: '30px',
            align: 'center',
            render: (text, record, index) => {
                return (page - 1) * 10 + index + 1
            }
        },
        {
            title: 'Description',
            dataIndex: 'r_description',
            key: 'r_description',
            width: '200px',
            ellipsis: true,
            render: (text, record) => <a onClick={() => {
                setRemarks(text)
                setRemarksModal(false)
            }}>{record.r_description}</a>,
        },
    ]

    useEffect(() => {
        if (isAuth) {
            if (entityid != '') {
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetVendorClosureData",
                            {
                                "legal_entity_id": entityid,

                            }
                        ]
                    }
                ]
                if (entityid != "null") {
                    if (entityid != undefined) {
                        getVendorClosureList({
                            payload: payload,
                            paramid: paramid
                        })
                    } else {
                        setBaseData('')
                    }
                } else {
                    setBaseData('')
                }
            }

        }
    }, [isAuth, entityid])
    useEffect(() => {
        if (type === 'inactive') {
            let inactive = _.filter(baseData, { is_active: Number(1) })
            setFilteredArray(inactive)
        }
        if (type === 'activeData') {
            let inactive = _.filter(baseData, { is_active: Number(0) })
            setFilteredArray(inactive)
        }
        if (type === 'close') {
            let inactive = _.filter(baseData, { currentStatus: Number(0) })
            setFilteredArray(inactive)
        }
        if (type === 'closed') {
            let inactive = _.filter(baseData, { currentStatus: Number(1) })
            setFilteredArray(inactive)
        }
        if (type === 'reactivate') {
            let inactive = _.filter(baseData, { currentStatus: Number(2) })
            setFilteredArray(inactive)
        }

    }, [baseData, type])
    const onSubmitTable = () => {
        setFormSubmit(true)

        if (formValidator1.current.allValid()) {

            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetVendorClosureData",
                        {
                            "legal_entity_id": entityid,

                        }
                    ]
                }
            ]
            if (entityid != "null") {
                getVendorClosureList({
                    payload: payload,
                    paramid: paramid
                })
            } else {
                setBaseData('')
            }
        }
    }

    const OnSubmited = () => {
        setAddFormSubmit(true)

        if (formValidator.current.allValid()) {
            setModaVisible(false)
            setModaVisible1(false)
            saveVendorClosureData({
                payload:
                    [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "SaveUnitClosureData",
                                {
                                    "password": modalData.password,
                                    "closed_remarks": remarks,
                                    "unit_id": Number(modalData.unit_id),
                                    "grp_mode": modalData.reason == 'closed' ? "close" : "reactive",
                                    "legal_entity_id": entityid
                                }
                            ]
                        }
                    ],
                paramid: paramid,
                entityid: entityid,
                type: modalData.selectedtype
            })
            setModalData({
                ...modalData,
                password: ""
            })
            setRemarks("")
            setAddFormSubmit(false)
        }
    }
    const OnSubmittemp = () => {
        setAddFormSubmit2(true)
        if (formValidator2.current.allValid()) {
            setModaVisible(false)
            setModaVisible1(false)
            saveVendorTempClosureData({
                payload:
                    [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "SaveVendorClosureData",
                                {
                                    "password": modalData1.password,
                                    "closed_remarks": remarks,
                                    "unit_id": Number(modalData1.unit_id),
                                    "grp_mode": modalData1.reason == 'closed' ? "close" : "reactive",
                                    "legal_entity_id": entityid,
                                    "date": "01-Jan-2023",
                                }
                            ]
                        }
                    ],
                paramid: paramid,
                entityid: entityid,
                type: modalData1.selectedtype
            })
            setModalData1({
                ...modalData1,
                password: ""
            })
            setRemarks("")
            setAddFormSubmit2(false)
        }
    }


    useEffect(() => {
        if (recallremarks) {
            let temp = [];
            for (let i = 0; i < recallremarks.length; i++) {
                if (recallremarks[i].r_description != '') {
                    temp.push(recallremarks[i])
                }
                Setremarksvalue(temp)
            }
        }

    }, [recallremarks])

    useEffect(() => {
        formValidator.current.showMessages()
        formValidator1.current.showMessages()
        formValidator2.current.showMessages()
    }, [])
    const passwordcancel = () => {
        setModaVisible(false)
        setModaVisible1(false)
        setModalData({
            ...modalData,
            password: ""
        })
        setRemarks("")
        setAddFormSubmit(false)
    }
    const passwordcancel1 = () => {
        setModaVisible(false)
        setModaVisible1(false)
        setModalData1({
            ...modalData1,
            password: ""
        })
        setRemarks("")
        setAddFormSubmit1(false)
    }
    const handleOk = () => {
        // setIsModalVisible(false);
        setRemarksModal(false)
    };

    const handleCancel = () => {
        // setIsModalVisible(false);
        setRemarksModal(false)
    };

    const showRemarksModal = () => {
        GetRemarksData({
            payload:
                [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetRemarksData",
                            {}
                        ]
                    }
                ],
            paramid: paramid
        })
        setRemarksModal(true);
    };

    const closeById = (unit_id, type) => {
        type == "closed" ?
            Swal.fire({
                title: 'Are you sure?',
                text: "You want to Close?",
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
            }).then((result) => {
                if (result.isConfirmed) {
                    setModaVisible1(true)
                    setModalData1({
                        ...modalData1,
                        unit_id: unit_id,
                        reason: type,
                        selectedtype: 'closed'
                    })
                }
            }) :
            Swal.fire({
                title: 'Are you sure?',
                text: "You want to Activate?",
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
            }).then((result) => {
                if (result.isConfirmed) {
                    setModaVisible1(true)
                    setModalData1({
                        ...modalData1,
                        unit_id: unit_id,
                        reason: type,
                        selectedtype: 'activate'
                    })
                }
            })
    };

    const closeById1 = (unit_id, type) => {
        type == "closed" ?
            Swal.fire({
                title: 'Are you sure?',
                text: "You want to Close?",
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
            }).then((result) => {
                if (result.isConfirmed) {
                    setModaVisible(true)
                    setModalData1({
                        ...modalData1,
                        unit_id: unit_id,
                        reason: type,
                        selectedtype: 'closed'
                    })
                }
            }) :
            Swal.fire({
                title: 'Are you sure?',
                text: "You want to Activate?",
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
            }).then((result) => {
                if (result.isConfirmed) {
                    setModaVisible(true)
                    setModalData1({
                        ...modalData1,
                        unit_id: unit_id,
                        reason: type,
                        selectedtype: 'activate'
                    })
                }
            })
    };

    useEffect(() => {
        if (modalData.legal_entity == '' || null || undefined) {
            setModalData({
                ...modalData,
                legal_entity: entityid
            })

        }
    }, [modalData.legal_entity])

    useEffect(() => {
        if (modalData1.legal_entity == '' || null || undefined) {
            setModalData({
                ...modalData1,
                legal_entity: entityid
            })

        }
    }, [modalData1.legal_entity])



    const dispatch = useDispatch()
    // useEffect(() => {
    //     if (unitClosureList && unitClosureList.vendor_closure_units && unitClosureList.vendor_closure_units.length > 0) {
    //         setBaseData(unitClosureList.vendor_closure_units);
    //     }
    // }, [unitClosureList.vendor_closure_units])

    useEffect(() => {
        let entityArray = []
        let tempArraryNew = []
        if (unitClosureList && unitClosureList.vendor_closure_units && unitClosureList.vendor_closure_units.length > 0) {
            let array1 = _.filter(unitClosureList.vendor_closure_units, { is_active: 0 });
            setActivedata(array1.length)
            let array2 = _.filter(unitClosureList.vendor_closure_units, { is_active: 1 });
            setInactivedata(array2.length)

        }
        if (unitClosureList && unitClosureList.vendor_closure_units && unitClosureList.vendor_closure_units.length > 0) {
            unitClosureList.vendor_closure_units.map((subList) => {
                let currentStatus = ''
                if (subList.is_active == 0) {
                    currentStatus = 0 //close
                } else if (subList.is_active == 1 && subList.validity_days <= 30) {
                    currentStatus = 2 //reactivate
                } else if (subList.is_active == 1 && subList.validity_days > 30) {
                    currentStatus = 1 // closed
                    let tempclosed = Number(closeddata) + 1
                    setCloseddata(tempclosed)
                } else {
                    currentStatus = subList.is_active //default is_active
                }
                entityArray = {
                    'address': subList.address,
                    'business_group_name': subList.business_group_name,
                    'category_name': subList.category_name,
                    'closed_on': subList.closed_on,
                    'division_name': subList.division_name,
                    'is_active': subList.is_active,
                    'is_vendor_closed': subList.is_vendor_closed,
                    'legal_entity_id': subList.legal_entity_id,
                    'legal_entity_name': subList.legal_entity_name,
                    'pending_count': subList.pending_count,
                    'postal_code': subList.postal_code,
                    'unit_code': subList.unit_code,
                    'unit_id': subList.unit_id,
                    'unit_name': subList.unit_name,
                    'validity_days': subList.validity_days,
                    'validity_days_main': subList.validity_days_main,
                    'vendor_activation_date': subList.vendor_activation_date,
                    'vendor_closed_date': subList.vendor_closed_date,
                    'currentStatus': currentStatus
                }
                tempArraryNew.push(entityArray)
            })
            //setBaseData(unitClosureList.UnitClosureList);
            setBaseData(tempArraryNew)
        }
    }, [unitClosureList.vendor_closure_units])

    // useEffect(() => {
    //     setBaseData([])
    // },[])

    useEffect(() => {
        return () => {
            dispatch({
                type: UNIT_CLOSURE_LIST,
                payload: ['', { unit_closure_units: [] }]
            })
        }
    }, [])

    const searchRecords = (e) => {
        if (e == 'clear') {
            setClearText('')
            setBaseData(unitClosureList.vendor_closure_units)
            setIconEnable(false)
        } else {
            setClearText(e.target.value)
            if (e.target.value.length > 0) {
                setIconEnable(true)
            } else {
                setIconEnable(false)
            }
            const filterTable = unitClosureList.vendor_closure_units.filter(o =>
                Object.keys(o).some(k =>
                    String(o[k])
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                )
            );
            setBaseData(filterTable)
        }

    };

    const homescreen = () => {
        window.location.href = '/dashboard'
    }

    const [fullscreen, setfullscreen] = useState(false);
    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', handleKeyDown, false);
            document.addEventListener('webkitfullscreenchange', handleKeyDown, false);
            return () => {
                document.addEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('webkitfullscreenchange', handleKeyDown);
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode();
            setfullscreen(true);
        } else {
            fullscreenexit();
            setfullscreen(false);
        }
    }

    return (
        <Fragment>
            <div>
                <ScrollToTop smooth color="red" component={<Tooltip title="Back to top"><UpOutlined /></Tooltip>} />
                <div className="page-wrapper" id="page-wrapper">
                    <div className="page-titles pb-2 pt-2">
                        {/* <div className="row">
                            <div className="col-lg-5 col-md-6 col-12 align-self-center">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 d-flex align-items-center">
                                        <li className="breadcrumb-item">
                                            <Link className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            <span style={{ color: '#1890ff' }}>Master</span>
                                        </li>
                                        <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                            <span style={{ color: '#1890ff' }}>Vendor Closure</span>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                            <div className="col-lg-7" style={{ textAlign: 'end' }}>
                                <button onClick={(e) => {
                                    fullscreenMode();
                                }} className="bg-transparent text-black ms-1 full-mode fullscreenradius">
                                    <Tooltip placement="left" title="Full Screen Mode" >
                                        <FullscreenOutlined />
                                    </Tooltip>
                                </button>
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-md-6">
                                <div className="col-lg-8 col-md-6 col-12 align-self-center">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb mb-0 d-flex align-items-center">
                                            <li className="breadcrumb-item">
                                                <span className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                            </li>
                                            <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                                <span style={{ fontSize: '16px' }}>Master</span>
                                            </li>
                                            <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                                <span>Vendor Closure </span>
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                            <div className="col-lg-6" style={{ textAlign: 'end' }}>
                                <button onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black ms-1 full-mode fullscreenradius">
                                    <Tooltip placement="left" title="Full Screen Mode" >
                                        <FullscreenOutlined />
                                    </Tooltip>
                                </button>
                            </div>
                        </div>
                        <div className='row' style={{ "margin-top": "10px" }}>
                            <div className='col-5' >
                                <label style={{ "font-weight": 'bold' }}>Legal Entity : <span style={{ "color": "red" }}>*</span></label>
                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                    <div className="form-group" style={{ "margin-top": '-24px', "margin-left": '90px' }}>
                                        <Select
                                            allowClear={true}
                                            size="default"
                                            style={{ width: '100%', marginLeft: "20px" }}
                                            placeholder="Select Legal Entity"
                                            onChange={(data, value) => {
                                                setCurrentEntity(data)
                                            }}
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        >
                                            {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                return (
                                                    <Option key={item.le_id}>
                                                        {item.le_name}
                                                    </Option>
                                                );
                                            })}
                                        </Select>
                                        {formValidator1.current.message(
                                            'Legal Entity',
                                            entityid,
                                            'required',
                                            {
                                                className: `invalid-feedback ${FormSubmit ? 'show' : 'hide'}`,
                                                messages: {
                                                    required: 'Legal Entity Required',
                                                }
                                            })}
                                    </div> :
                                    <p style={{ width: '100%', marginTop: "5px" }}>{localStorage.getItem('SelectedEntity')}</p>

                                }
                            </div>
                            <div className="col-7">
                                <div className="search-box_vendorclosure" style={{ position: 'relative', float: 'right' }}>
                                    <input className="search-txt" value={clearText} type="text" autoComplete='off' onChange={searchRecords} placeholder="Type to Search" />
                                    <a className="search-btn1">
                                        {iconEnable == true ?
                                            <i className="fas fa-close" onClick={(e) => { searchRecords('clear') }}></i>
                                            : <i className="fas fa-search"></i>}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container-fluid pt-1">
                        <div className={localStorage.getItem("currentTheme") + ' card-body1-statuatory'}>
                            <Collapse className='report-collapse' defaultActiveKey={["1"]} >
                                <Panel header="Vendor Closure Statistics" key={baseData && baseData.length > 0 ? 1 : 0}>
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <Card className='rounded-3 unitClosure'>
                                                    <h5>Overall Statistics</h5>
                                                    <div className="service-provider-segment">
                                                        <Space direction="vertical">
                                                            <Segmented size='small'
                                                                onChange={(value) => {
                                                                    setType(value)

                                                                }}
                                                                options={[
                                                                    {
                                                                        label: (
                                                                            <div onChange={() => {
                                                                                console.log('hello');
                                                                            }}
                                                                                style={{
                                                                                    padding: 4,
                                                                                }}
                                                                            >
                                                                                <Avatar
                                                                                    style={{
                                                                                        backgroundColor: '#87d068',
                                                                                        fontSize: '15px'
                                                                                    }}
                                                                                    icon={<i className="ri-book-mark-fill"></i>}
                                                                                />
                                                                                <div>All Data</div>
                                                                                <b>{baseData && baseData.length}</b>
                                                                            </div>
                                                                        ),
                                                                        value: 'active',
                                                                    },
                                                                    {
                                                                        label: (
                                                                            <div onChange={() => {
                                                                                console.log('inactive');
                                                                            }}
                                                                                style={{
                                                                                    padding: 4,
                                                                                }}
                                                                            >
                                                                                <Avatar
                                                                                    style={{
                                                                                        backgroundColor: '#F32013',
                                                                                        fontSize: '15px'
                                                                                    }}
                                                                                    icon={<i className="ri-user-unfollow-fill"></i>}
                                                                                />
                                                                                <div>Inactive</div>
                                                                                <b>{activeArray && activeArray.length}</b>


                                                                            </div>
                                                                        ),
                                                                        value: 'inactive',
                                                                    },
                                                                    {
                                                                        label: (
                                                                            <div onChange={() => {
                                                                                console.log('unblk');
                                                                            }}
                                                                                activeData style={{
                                                                                    padding: 4,
                                                                                }}
                                                                            >
                                                                                <Avatar
                                                                                    style={{
                                                                                        backgroundColor: '#87d068',
                                                                                        fontSize: '15px'
                                                                                    }}
                                                                                    icon={<i className="ri-user-follow-fill"></i>}
                                                                                />
                                                                                <div>Active</div>
                                                                                <b>{inActiveArray && inActiveArray.length}</b>



                                                                            </div>
                                                                        ),
                                                                        value: 'activeData',
                                                                    },
                                                                    {
                                                                        label: (
                                                                            <div onChange={() => {
                                                                                console.log('unblk');
                                                                            }}
                                                                                style={{
                                                                                    padding: 4,
                                                                                }}
                                                                            >
                                                                                <Avatar
                                                                                    style={{
                                                                                        backgroundColor: '#F32013',
                                                                                        fontSize: '15px'
                                                                                    }}
                                                                                    icon={<i className="fas fa-ban"></i>}
                                                                                />
                                                                                <div>Closed</div>
                                                                                <b>{unBlockedArray && unBlockedArray.length}</b>

                                                                            </div>
                                                                        ),
                                                                        value: 'closed',
                                                                    },
                                                                    {
                                                                        label: (
                                                                            <div onChange={() => {
                                                                                console.log('blocked');
                                                                            }}
                                                                                style={{
                                                                                    padding: 4,
                                                                                }}
                                                                            >
                                                                                <Avatar
                                                                                    style={{
                                                                                        backgroundColor: '#F32013',
                                                                                        fontSize: '15px'
                                                                                    }}
                                                                                    icon={<i className="fas fa-sync"></i>}
                                                                                />
                                                                                <div>Reactivate</div>
                                                                                <b>{reactiveArray && reactiveArray.length}</b>


                                                                            </div>
                                                                        ),
                                                                        value: 'reactivate',
                                                                    },
                                                                ]}
                                                            />
                                                        </Space>
                                                    </div>
                                                </Card>
                                            </div>
                                            <div className="col-md-6 userCard userCard1" style={{ height: '219px', width: '50%' }}>
                                                <Card className='rounded-3' style={{ minHeight: '172px' }}>
                                                    <h5>Action Needed</h5>
                                                    <div id='service-table-action' className='service-table'>
                                                        <Table className={`stats ${localStorage.getItem("currentTheme")}`}
                                                            columns={columns1}
                                                            dataSource={reactivatedArray}
                                                            pagination={false}
                                                        />
                                                    </div>
                                                </Card>
                                            </div>
                                        </div>
                                    </div>
                                </Panel>
                            </Collapse>
                            {/* <div className="col-12 m-2">
                            <div className="row">
                                <div className="col-6 d-flex align-items-center">
                                    <label htmlFor="" className='me-2'><b>Legal Entity :  </b></label>
                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                        <div  >
                                            <Select
                                                allowClear={false}
                                                size="default"
                                                style={{ width: '300px' }}
                                                placeholder="Select Legal Entity"
                                                onChange={(data, value) => {
                                                    if (data == undefined) {
                                                        setCurrentEntity("")
                                                    }
                                                    else {
                                                        setCurrentEntity(data)
                                                        setBaseData([])
                                                        setSortedInfo({})
                                                        setFilteredInfo({})
                                                        setCloseddata('0')
                                                        setSearchText({
                                                            data: '',
                                                            index: 0
                                                        })
                                                    }
                                                }}
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                            >
                                                {sessionArr && sessionArr.length > 0 && sessionArr.map((item, i) => {
                                                    return (
                                                        <Option key={item.le_id}>
                                                            {item.le_name}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        </div> :
                                        <p style={{ width: '100%', marginTop: "5px" }}>{localStorage.getItem('SelectedEntity')}</p>}
                                </div>
                                <div className="col-6">
                                    <div className="search-box1" style={{ position: 'relative', float: 'right' }}>
                                        <input className="search-txt" value={clearText} type="text" autoComplete='off' onChange={searchRecords} placeholder="Type to Search" />
                                        <a className="search-btn1">
                                            {iconEnable == true ?
                                                <i className="fas fa-close" onClick={(e) => { searchRecords('clear') }}></i>
                                                : <i className="fas fa-search"></i>}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                            <div className="row mt-2">
                                <div className="col-12">
                                    <div className="card-body p-0 pt-2">
                                        <Table
                                            key={type === 'active' ? baseData : filteredArray}
                                            id={'unitclosuretableview'}
                                            sortDirections={["ascend", "descend", "ascend"]}
                                            columns={columns}
                                            className={'tablecolorchanges ' + localStorage.getItem("currentTheme")}
                                            size={'small'}
                                            dataSource={type === 'active' ? baseData : filteredArray}
                                            onChange={tablehandleChange}
                                            bordered
                                            pagination={false}
                                            // scroll={{ x: 1000 }}
                                            showSorterTooltip={false}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="container-fluid pt-1">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body" style={{ padding: '0px' }}>
                                        <Table
                                            columns={columns}
                                            // className='userprivclass'
                                            id={'unitclosuretableview'}
                                            className={'tablecolorchanges ' + localStorage.getItem("currentTheme")}
                                            dataSource={baseData}
                                            bordered
                                            // scroll={{ x: 1200 }}
                                            pagination={baseData.length > 10 ? {
                                                defaultPageSize: dataTableProperties.pagesize,
                                                showSizeChanger: dataTableProperties.sizechanger
                                            } : false}
                                        // onChange={handleChange} 
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    </div>
                </div >

            </div>
            {/* <Modal visible={modalVisible} footer={null} className='usr_modal_class'
                onCancel={passwordcancel}>
                <div className="col-md-12">
                    <div className="form-group">
                        <label htmlFor=""><b>Password:</b> <span style={{ "color": "red" }}>*</span></label>
                        <input
                            type="password"
                            id="password"
                            name='password'
                            className="form-control"
                            placeholder="Enter password"
                            value={modalData.password}
                            onChange={(e) => {
                                setModalData({
                                    ...modalData,
                                    password: e.target.value
                                })
                            }} />
                        {formValidator.current.message(
                            'password',
                            modalData.password,
                            'required',
                            {
                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                messages: {
                                    required: 'Password Required',
                                }
                            })}
                    </div>
                </div>
                <br />
                <div className="col-md-12">
                    <label htmlFor=""><b>Remarks:</b> <span style={{ "color": "red" }}>*</span></label>

                    <textarea className='form-control'
                        placeholder="Enter Reason"
                        row='1' cols='4' 
                        onChange={(e) => {
                            setRemarks(e.target.value)
                        }}
                        value={remarks}

                    ></textarea>
                    <span className="btn btn-sm btn-light-info text-info btn-circle d-flex 
                                align-items-center justify-content-center" style={{ "float": "right" }} onClick={() => { showRemarksModal() }}>
                        <PlusOutlined />
                    </span>

                    {formValidator.current.message(
                        'reason',
                        remarks,
                        'required',
                        {
                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                            messages: {
                                required: 'Reason Required',
                            }
                        })}

                </div>
                <br />

                <div className="form-actions">
                    <Button type="primary" shape="round" className='addbutton'
                        style={{ background: "#198754", borderColor: "#198754", marginLeft: '33%' }}
                        icon={<PlayCircleOutlined />} size='default'
                        onClick={OnSubmited}
                    >
                        Submit
                    </Button>
                </div>
            </Modal> */}

            <Modal title='Enter Password and Reason' visible={modalVisible} footer={null} className={'usr_modal_class add-service-prv modal-service-priv ' + localStorage.getItem('currentTheme')}
                onCancel={passwordcancel1}>
                <div className="col-md-12">
                    <div className="form-group">
                        <label htmlFor=""><b>Password:</b> <span style={{ "color": "red" }}>*</span></label>
                        <input
                            type="password"
                            id="password"
                            name='password'
                            className="form-control"
                            placeholder="Enter password"
                            style={{ width: '95%', marginTop: '5px' }}
                            defaultValue={modalData1.password}
                            key={modalData1?.password ? modalData1?.password : ''}
                            onChange={(e) => {
                                setModalData1({
                                    ...modalData1,
                                    password: e.target.value
                                })
                                // setPassword(e.target.value)
                            }} />
                        {formValidator.current.message(
                            'password',
                            modalData1.password,
                            'required',
                            {
                                className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                messages: {
                                    required: 'Password Required',
                                }
                            })}
                    </div>
                </div>
                <div className="col-md-12" style={{ marginTop: '10px' }}>
                    <label htmlFor=""><b>Reason:</b> <span style={{ "color": "red" }}>*</span></label>
                    <div style={{ marginTop: '5px' }} className='d-flex align-items-end'>
                        <textarea className='form-control'
                            placeholder="Enter Reason"
                            rows={'1'} cols='4'
                            onChange={(e) => {
                                setRemarks(e.target.value)
                            }}
                            value={remarks}
                        ></textarea>
                        {/* <div className="btn btn-sm btn-light-info text-info btn-circle d-flex 
                                align-items-center justify-content-center" style={{ "float": "right" }} onClick={() => { showRemarksModal() }}>
                        <PlusOutlined />
                    </div> */}
                        <span className="text-info ms-1 d-flex 
                                align-items-center justify-content-center" style={{ marginTop: '30px' }} onClick={() => { showRemarksModal() }}>
                            <PlusCircleOutlined />
                        </span>
                    </div>
                    {formValidator.current.message(
                        'reason',
                        remarks,
                        'required',
                        {
                            className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                            messages: {
                                required: 'Reason Required',
                            }
                        })}

                </div>
                <div className="col-md-12" style={{ marginTop: '10px' }}>
                    <label><b> Date: </b> <span style={{ color: "red" }}>*</span></label>
                    <div style={{ marginTop: '5px' }} className="form-group">
                        {/* <DatePicker format={'DD-MMM-YYYY'} placeholder='DD-MMM-YYYY' style={{ width: '100%', marginTop: '5px' }}
                            value={moment(modalData1.date)}


                            onChange={(date, dateString) => {
                                setModalData1({
                                    ...modalData1,
                                    date: dateString
                                })
                            }}
                        /> */}
                        <DatePicker format={'DD-MMM-YYYY'} placeholder='DD-MMM-YYYY'
                            style={{ width: '95%' }}
                            allowClear={true}
                            value={modalData1.date ? moment(modalData1.date) : ''}
                            // value={consolidatedData.from_date}
                            onChange={(date, dateString) => {
                                setModalData1({
                                    ...modalData1,
                                    date: dateString
                                })

                            }}

                        />
                    </div>
                    {formValidator.current.message(
                        'date',
                        modalData1.date,
                        'required',
                        {
                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                            messages: {
                                required: 'Date Required',
                            }
                        })}
                </div>
                {/* <div className="form-actions"> */}
                {/* <Button type="primary" shape="round" className='addbutton'
                        style={{ background: "#198754", borderColor: "#198754", marginLeft: '33%', marginTop: '20px' }}
                        icon={<PlayCircleOutlined />} size='default'
                        onClick={OnSubmited}
                    >
                        Submit
                    </Button> */}
                <div className="form-actions popupbtncolour text-center" style={{ marginTop: '12px' }}>
                    <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')}
                        style={{ background: "#198754", borderColor: "#198754", marginLeft: '-4%' }}
                        icon={<PlayCircleOutlined />} size='default'
                        onClick={OnSubmited}
                    >
                        Submit
                    </Button>
                </div>
                {/* </div> */}

            </Modal>


            <Modal title='Enter Password and Reason' visible={modalVisible1} footer={null} className={'usr_modal_class add-service-prv modal-service-priv ' + localStorage.getItem('currentTheme')}
                onCancel={passwordcancel1}>
                <div className="col-md-12">
                    <div className="form-group">
                        <label htmlFor=""><b>Password:</b> <span style={{ "color": "red" }}>*</span></label>
                        <input
                            type="password"
                            id="password"
                            name='password'
                            className="form-control"
                            style={{ width: '95%', marginTop: '5px' }}
                            placeholder="Enter password"
                            value={modalData1.password}
                            onChange={(e) => {
                                setModalData1({
                                    ...modalData1,
                                    password: e.target.value
                                })
                            }} />
                        {formValidator2.current.message(
                            'password',
                            modalData1.password,
                            'required',
                            {
                                className: `invalid-feedback ${AddFormSubmit2 ? 'show' : 'hide'}`,
                                messages: {
                                    required: 'Password Required',
                                }
                            })}
                    </div>
                </div>
                <div className="col-md-12" style={{ marginTop: '10px' }}>
                    <label htmlFor=""><b>Reason:</b> <span style={{ "color": "red" }}>*</span></label>
                    <div style={{ marginTop: '5px' }} className='d-flex align-items-end'>
                        <textarea className='form-control'
                            placeholder="Enter Reason"
                            rows={'1'} cols='4'
                            onChange={(e) => {
                                setRemarks(e.target.value)
                            }}
                            value={remarks}

                        ></textarea>
                        <span className="text-info ms-1 d-flex 
                                align-items-center justify-content-center" style={{ marginTop: '30px' }} onClick={() => { showRemarksModal() }}>
                            <PlusCircleOutlined />
                        </span>
                    </div>
                    {formValidator2.current.message(
                        'reason',
                        remarks,
                        'required',
                        {
                            className: `invalid-feedback ${AddFormSubmit2 ? 'show' : 'hide'}`,
                            messages: {
                                required: 'Reason Required',
                            }
                        })}
                </div>
                <div className="form-actions popupbtncolour text-center" style={{ marginTop: '12px' }}>
                    <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')}
                        style={{ background: "#198754", borderColor: "#198754", marginLeft: '-4%' }}
                        icon={<PlayCircleOutlined />} size='default'
                        onClick={OnSubmittemp}
                    >
                        Submit
                    </Button>
                </div>
            </Modal>


            <Modal title="Remarks List" visible={remarksModal}
                onOk={handleOk}
                onCancel={handleCancel} className="remarksClass" footer={false}>
                <Table
                    className='userprivclass'
                    columns={RemarksColumn}
                    dataSource={remarksvalue && remarksvalue.length > 0 && remarksvalue}
                    bordered
                    pagination={{
                        pageSizeOptions: ['10', '30', '50'],
                        defaultPageSize: dataTableProperties.pagesize,
                        showSizeChanger: dataTableProperties.sizechanger,
                        hideOnSinglePage: true
                    }}

                />
            </Modal>
        </Fragment >
    )
}
export default connect(mapStateToProps, {
    getVendorClosureList,
    GetRemarksData,
    saveVendorClosureData,
    saveVendorTempClosureData,
})(VendorClosure);