import { Collapse, Select, Card, Drawer, Button, DatePicker, Tooltip, Pagination, Modal } from 'antd';
import { useState, useEffect, useRef } from 'react';
import { useLocation, Link } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import { MonthReportData, MonthReportTable, MonthReportExport } from "../../../Store/Action/Report/CV_MonthWiseStatusReport"
import { connect, useDispatch } from 'react-redux';
import { EyeOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { ExclamationCircleTwoTone, ExclamationCircleOutlined, FilterOutlined, FullscreenOutlined, UpOutlined, ExportOutlined } from '@ant-design/icons';
import moment from 'moment';
import { CV_MONTH_WISE_REPORT_EXPORT_RESET } from './../../../Store/types/index'
import ScrollToTop from "react-scroll-to-top";
import { fullscreenmode, fullscreenexit, handleKeyDown } from './../../../Libs/fullscreenmode';

const mapStateToProps = (state) => ({
    reportlist: state.CV_MonthWiseStatusReport.monthwisestatusreport
})
const MonthWiseStatusReport = ({
    MonthReportData,
    reportlist,
    MonthReportTable,
    MonthReportExport
}) => {
    console.log(reportlist, 'reportlist');
    const { Panel } = Collapse;
    const { Option } = Select;
    const location = useLocation();
    const paramid = location.pathname;
    const _ = require("lodash");
    const sessionValue = localStorage.getItem('sessionValue')
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const sessionParsedValue = JSON.parse(sessionValue)
    const [current, setCurrent] = useState(1);
    const [pageState, setpageState] = useState(false);
    const [domain, setDomain] = useState([])
    const [unit, setUnit] = useState([])
    const [check, setCheck] = useState([])
    const [currentYear, setCurrentYear] = useState(moment().format('YYYY'))
    const [categorylist, setCategoryList] = useState([])
    const [division, setDivision] = useState([])
    const [pageSize, setPageSize] = useState(25)
    const [paginationArray, setPaginationArray] = useState([])
    const sessionArr = sessionParsedValue.entity_info
    const validator = useRef(new SimpleReactValidator());
    const [open, setOpen] = useState(false);
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [tableshow, setTableShow] = useState(false)
    const [key, setKey] = useState("1")
    const [countrydata, setCountryData] = useState(null)
    const dispatch = useDispatch();
    const [ids, setIds] = useState({
        d_id: null,
        c_id: null
    })
    const [titledata, Settitledata] = useState(undefined)
    let [index, setindex] = useState(0)
    const [filteredArray, setFilteredArray] = useState([])
    const [filteredArrayState, setfilteredArrayState] = useState([])
    const [getreportlist, setGetReportList] = useState([])
    const [tabledata, setTableData] = useState([])
    const [totaldata, setTotalData] = useState({
        domainlist: [],
        divisionlist: [],
        categorylist: [],
        unitlist: []
    })
    const [monthwisereport, setMonthWiseReport] = useState({
        country: "",
        legel_entity: "",
        domain: "",
        division: "",
        category: "",
        unit: "",
        period: "",
        domain_id: null,
        division_id: null,
        category_id: null,
        unit_id: null,
        from_month: '',
        to_month: ''
    })
    const [monthlyArray, setMonthlyArray] = useState([])
    const [exitCollapse, setExitCollapse] = useState(false);
    const [filterTaskAcc, setFilterTaskAcc] = useState(true);
    const [filterModalVisible, setFilterModalVisible] = useState(false);

    useEffect(() => {
        if (reportlist.export && reportlist.export.link) {
            window.open('https://13.234.100.64/' + reportlist.export.link);
            dispatch({
                type: CV_MONTH_WISE_REPORT_EXPORT_RESET
            });
        }

    }, [reportlist.export])

    useEffect(() => {
        validator.current.showMessages()
    }, [])

    useEffect(() => {
        if (reportlist.tabledata) {
            setTableData(reportlist && reportlist.tabledata && reportlist.tabledata.monthly_compliances)
        }
    }, [reportlist.tabledata])

    useEffect(() => {
        if (reportlist && reportlist.tabledata && reportlist.tabledata.monthly_compliances) {
            var array = reportlist && reportlist.tabledata && reportlist.tabledata.monthly_compliances.slice((current - 1) * pageSize, (current * pageSize))
            setPaginationArray(array)
        }

    }, [reportlist && reportlist.tabledata && reportlist.tabledata.monthly_compliances, pageSize, current])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntityid') != null) {
            setCurrentEntity(localStorage.getItem('SelectedEntityid'))
            const country = sessionParsedValue.country_info
            setCountryData(country)
        }
    }, [localStorage.getItem('SelectedEntityid')])

    useEffect(() => {
        if (monthwisereport.division_id) {
            const divid = totaldata.divisionlist.filter((item) => {
                return item.div_id === monthwisereport.division_id
            })
        }
    }, [monthwisereport])

    useEffect(() => {
        if (isAuth && entityid != "null") {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetComplianceWiseReportFilters",
                        {
                            "country_id": countrydata && countrydata[0].c_id,
                            "legal_entity_id": entityid
                        }
                    ]
                }
            ]
            MonthReportData({
                payload: payload,
                paramid: paramid
            })
        }
    }, [isAuth, authtoken, paramid, entityid, countrydata])

    useEffect(() => {
        if (reportlist.compliancewisereport) {
            setGetReportList(reportlist && reportlist.compliancewisereport)
            let Domaindata = _.filter(reportlist && reportlist.compliancewisereport.domains, { le_id: entityid })
            setTotalData({
                ...totaldata,
                domainlist: Domaindata
            })
        }
        if (localStorage.getItem('SelectedEntity') !== "All Legal Entity") {
            setMonthWiseReport({
                ...monthwisereport,
                country: countrydata && countrydata[0].c_id,
                legel_entity: localStorage.getItem('SelectedEntity')
            })
        }
        if (monthwisereport.period === "") {
            setMonthWiseReport({
                ...monthwisereport,
                period: "All",
            })
        }
    }, [reportlist && reportlist.compliancewisereport, entityid, countrydata])

    useEffect(() => {
        if (totaldata) {
            setCategoryList(reportlist && reportlist.compliancewisereport.categories_list)
            setDivision(reportlist && reportlist.compliancewisereport.divisions_list)
            setDomain(reportlist && reportlist.compliancewisereport.domains)
            setUnit(reportlist && reportlist.compliancewisereport.unit_legal_entity)
        }
    }, [totaldata])

    useEffect(() => {
        // setTempeditdata(editdata)
        let array = [];
        let finalarray = [];
        let datavalue = [];
        if (tabledata) {
            const ab = paginationArray && paginationArray.map(function (item) {
                if ((array.indexOf(item.statutory_mapping) < 0))
                    array.push(item.statutory_mapping)
            })
            array.map((data, i) => {
                let temp = [];
                let entityArray = [];
                let datavalue = [];
                if (data) {
                    paginationArray && paginationArray.map((child, childIndex) => {
                        if (data === child.statutory_mapping) {
                            entityArray = {
                                "Criticality": child.Criticality,
                                "ac_id": child.ac_id,
                                "category_name": child.category_name,
                                "compliance_description": child.compliance_description,
                                "compliance_task": child.compliance_task,
                                "division_name": child.division_name,
                                "due_date": child.due_date,
                                "months": child.months,
                                "statutory_mapping": child.statutory_mapping,
                                "task_status": child.task_status,
                                "tz_name": child.tz_name,
                                "unit_address": child.unit_address,
                                "unit_id": child.unit_id,
                                "unit_name": child.unit_name,
                                'childId': childIndex,
                                'parentId': i
                            }
                            temp.push(entityArray)
                        }
                    })
                }

                datavalue = {
                    index: i,
                    parent: data,
                    child: temp
                }
                finalarray.push(datavalue)
            })
            Settitledata(finalarray)

        }

    }, [paginationArray])

    useEffect(() => {
        if (tabledata) {
            let uniqueObjArray = [...new Map(tabledata && tabledata.map((item) => [item["unit_id"], item])).values()];
            const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                return item.unit_id
            })

            let tempArr = []
            for (let i in uniqueIds) {
                let filteredArr = _.filter(tabledata && tabledata, { unit_id: uniqueIds[i] })

                tempArr.push(filteredArr)
            }
            setFilteredArray([...filteredArray, tempArr])

        }

    }, [tabledata])

    useEffect(() => {
        if (filteredArray && filteredArray.length > 0) {
            filteredArray && filteredArray.map((item, i) => {
                return (
                    setfilteredArrayState(item)
                )
            })
        }
    }, [filteredArray])

    useEffect(() => {
        let monthArray = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        if (moment().format('MMM') == "Jan") {
            const index = monthArray.findIndex(element => {
                if (element == moment().format('MMM')) {
                    return true;
                }
            })
            let currentYear = moment().format('YYYY') - 1
            monthArray = monthArray.filter((month, idx) => idx < index)
            let tempMonthArray = [];
            for (let i in monthArray) {
                tempMonthArray.push(monthArray[i] + '-' + currentYear)
            }
            setMonthlyArray(tempMonthArray)
        } else {
            let temp = false
            let temparray = []
            for (let i in monthArray) {
                if (temp) {
                    temparray.push(monthArray[i])
                } else {
                    if (monthArray[i] == moment(monthwisereport.from_month).format('MMM')) {
                        temparray.push(monthArray[i])
                        temp = true
                    }
                }
            }
            let check = []
            const index = temparray.findIndex(element => {
                check.push(element)
                // if (element == moment(monthwisereport.from_month).format('MMM')) {
                if (element == moment(monthwisereport.to_month).format('MMM')) {
                    return true;
                }
                // }
            })
            setCheck(check)
            // console.log(check,'check');
            let currentYear = moment().format('YYYY');
            temparray = temparray.filter((month, idx) => idx <= index)
            let tempMonthArray = [];
            for (let i in temparray) {
                tempMonthArray.push(temparray[i] + '-' + currentYear)
            }
            setMonthlyArray(tempMonthArray)
            // let check = tempMonthArray.split('-')
            // console.log(check[0],'check[0]');
        }

    }, [monthwisereport.to_month])

    useEffect(() => {
        if (pageState === true) {
            setindex(pageSize * (current - 1))
        }
    }, [pageState, pageSize, current])

    const [fullscreen, setfullscreen] = useState(false);

    const filterHandle = () => {
        setFilterTaskAcc(true);
        setFilterModalVisible(true);
    };
    const setcancelFilter = () => {
        setFilterModalVisible(false);
        setFilterTaskAcc(false);
    };

    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', handleKeyDown, false);
            document.addEventListener('webkitfullscreenchange', handleKeyDown, false);
            return () => {
                document.addEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('webkitfullscreenchange', handleKeyDown);
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode();
            setfullscreen(true);
        } else {
            fullscreenexit();
            setfullscreen(false);
        }
    }

    const columns = [
        {
            title: 'Compliance Ttask',
            dataIndex: 'compliance_task',
            key: 'compliance_task',
            width: "300px",
            ellipsis: true,
        },
        {
            title: 'Jan-2022',
            dataIndex: 'comp_name',
            key: 'comp_name',
            width: "80px",
            ellipsis: true,
            render: (text, record) => {
                return (
                    <>
                        <p>{record && record.months === 'January' ? record && record.task_status : "-"}</p>
                    </>
                )
            }
            // render: (record) => record && record.months === 'January' ? record &&record.task_status : "-"
        },
        {
            title: 'Feb-2022',
            dataIndex: 'comp_name',
            key: 'comp_name',
            width: "80px",
            ellipsis: true,
            // render: (record) => record && record.months === 'February' ? record && record.task_status : "-"
            render: (text, record) => {
                return (
                    <>
                        <p>{record && record.months === 'February' ? record && record.task_status : "-"}</p>
                    </>
                )
            }
        },
        {
            title: 'Mar-2022',
            dataIndex: 'comp_name',
            key: 'comp_name',
            width: "80px",
            ellipsis: true,
            // render: (record) => record && record.months === 'March' ? record && record.task_status : "-"
            render: (text, record) => {
                return (
                    <>
                        <p>{record && record.months === 'March' ? record && record.task_status : "-"}</p>
                    </>
                )
            }
        },
        {
            title: 'Apr-2022',
            dataIndex: 'comp_name',
            key: 'comp_name',
            width: "80px",
            ellipsis: true,
            // render: (record) => record && record.months === 'April' ? record && record.task_status : "-"
            render: (text, record) => {
                return (
                    <>
                        <p>{record && record.months === 'April' ? record && record.task_status : "-"}</p>
                    </>
                )
            }

        },
        {
            title: 'May-2022',
            dataIndex: 'comp_name',
            key: 'comp_name',
            width: "80px",
            ellipsis: true,
            // render: (record) => record && record.months === 'May' ? record && record.task_status : "-"
            render: (text, record) => {
                return (
                    <>
                        <p>{record && record.months === 'May' ? record && record.task_status : "-"}</p>
                    </>
                )
            }
        },
        {
            title: 'Jun-2022',
            dataIndex: 'comp_name',
            key: 'comp_name',
            width: "80px",
            ellipsis: true,
            render: (text, record) => {
                return (
                    <>
                        <p>{record && record.months === 'June' ? record && record.task_status : "-"}</p>
                    </>
                )
            }
        },
        {
            title: 'Jul-2022',
            dataIndex: 'comp_name',
            key: 'comp_name',
            width: "80px",
            ellipsis: true,
            // render: (record) => record && record.months === 'July' ? record && record.task_status : "-"
            render: (text, record) => {
                return (
                    <>
                        <p>{record && record.months === 'July' ? record && record.task_status : "-"}</p>
                    </>
                )
            }

        },
        {
            title: 'Aug-2022',
            dataIndex: 'comp_name',
            key: 'comp_name',
            width: "80px",
            ellipsis: true,
            // render: (record) => record && record.months === 'August' ? record && record.task_status : "-"
            render: (text, record) => {
                return (
                    <>
                        <p>{record && record.months === 'August' ? record && record.task_status : "-"}</p>
                    </>
                )
            }
        },
        {
            title: 'Sep-2022',
            dataIndex: 'comp_name',
            key: 'comp_name',
            width: "80px",
            ellipsis: true,
            // render: (record) => record && record.months === 'September' ? record && record.task_status : "-"
            render: (text, record) => {
                return (
                    <>
                        <p>{record && record.months === 'September' ? record && record.task_status : "-"}</p>
                    </>
                )
            }
        },
        {
            title: 'Next Due Date',
            dataIndex: 'due_date',
            key: 'due_date',
            width: "100px",
            ellipsis: true,
        }
    ]

    console.log(index, 'indexindex');

    const onChange = (key) => {
        // console.log(key);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onshow = () => {
        setAddFormSubmit(true)
        setFilterModalVisible(false);
        if (validator.current.allValid()) {
            setExitCollapse(true);
            setFilterTaskAcc(false);

            setTableShow(true)
            setKey(0)
            const payload1 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetMonthlyComplianceWiseReport",
                        {
                            "country_id": countrydata && countrydata[0].c_id,
                            "legal_entity_id": entityid,
                            "domain_id": Number(monthwisereport.domain_id),
                            "division_id": monthwisereport.division_id ? Number(monthwisereport.division_id) : 0,
                            "category_id": monthwisereport.category_id ? Number(monthwisereport.category_id) : 0,
                            "unit_id": monthwisereport.unit_id ? Number(monthwisereport.unit_id) : 0,
                            // "months": monthwisereport.period,
                            "from_date": moment(monthwisereport.from_month).format("MMMM-YYYY"),
                            "to_date": moment(monthwisereport.to_month).format("MMMM-YYYY"),
                            "csv": false,
                            "from_count": 0,
                            "page_count": 0
                        }
                    ]
                }
            ]
            MonthReportTable({
                payload: payload1,
                paramid: paramid
            })
        }
    }

    const onexport = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            setTableShow(false)
            setKey(0)
            const payload2 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetMonthlyComplianceWiseReport",
                        {
                            "country_id": countrydata && countrydata[0].c_id,
                            "legal_entity_id": entityid,
                            "domain_id": Number(monthwisereport.domain_id),
                            "division_id": monthwisereport.division_id ? Number(monthwisereport.division_id) : 0,
                            "category_id": monthwisereport.category_id ? Number(monthwisereport.category_id) : 0,
                            "unit_id": monthwisereport.unit_id ? Number(monthwisereport.unit_id) : 0,
                            // "months": monthwisereport.period,
                            "from_date": moment(monthwisereport.from_month).format("MMMM-YYYY"),
                            "to_date": moment(monthwisereport.to_month).format("MMMM-YYYY"),
                            "csv": true,
                            "from_count": 0,
                            "page_count": 0
                        }
                    ]

                }
            ]
            MonthReportExport({
                payload: payload2,
                paramid: paramid
            })
        }
    }

    const genextra = () => {
        return <div onClick={(event) => {
            // If you don't want click extra trigger collapse, you can prevent this:
            event.stopPropagation();
        }}>
            {/* <Button type='primary' onClick={() => {
                setOpen(true)
            }}> */}
            {/* <EyeOutlined size='default' onClick={() => {
                setOpen(true)
            }} /> */}
            {/* </Button> */}
        </div>
    }

    const homescreen = () => {
        window.location.href = '/dashboard'
    }

    return (
        <>
            <ScrollToTop smooth color="red" component={<Tooltip title="Back to top"><UpOutlined /></Tooltip>} />
            <div id="page-wrapper" className="page-wrapper">
                <div className="page-titles pb-0 pt-1">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12 align-self-center">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 d-flex align-items-center">
                                    <li className="breadcrumb-item">
                                        <span className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        <span style={{ fontSize: '14px', fontWeight: "bold" }}>Report</span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: '15px', fontWeight: 'bold' }}>
                                        <span style={{ fontSize: "15px", fontWeight: "bold" }}>Compliance Reports</span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                        <span >Month Wise Status Report</span>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-lg-6 text-end">
                            <Button
                                className="mx-1"
                                type="primary"
                                shape="round"
                                style={{
                                    display: filterTaskAcc ? "none" : "initial",
                                }}
                                onClick={filterHandle}
                            >
                                <FilterOutlined /> Filter
                            </Button>
                            <button onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black ms-2 full-mode fullscreenradius" >
                                <Tooltip placement="left" title="Full Screen Mode"><FullscreenOutlined /></Tooltip>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="container-fluid pt-1">
                    <div className="row">
                        <div className="col-12">
                            <div className={localStorage.getItem("currentTheme") + ' card-body1-statuatory'} style={{ position: 'relative', padding: "10px 3px" }}>
                                {/* <div className="card-body" style={{ padding: 0 }}> */}
                                <Collapse defaultActiveKey={["1"]} onChange={onChange} className='report-collapse' style={{ display: exitCollapse ? "none" : "block" }}  >
                                    <Panel header="Month Wise Status Report" key={key} extra={tableshow == true ? genextra() : false}>
                                        <div className='col-md-12'>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <label><b>Country : </b> <span style={{ color: "red" }}>*</span></label>
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                        <Select
                                                            placeholder="Enter Country"
                                                            onChange={(value) => {
                                                                setMonthWiseReport({
                                                                    ...monthwisereport,
                                                                    country: value
                                                                })
                                                            }}
                                                            style={{ width: '100%', marginTop: '5px' }}

                                                            value={monthwisereport.country || undefined}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                                                        >
                                                            {countrydata && countrydata.length && countrydata.map((item, i) => {
                                                                return (
                                                                    <Option key={item.c_id}>
                                                                        {item.c_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        : <p>{sessionArr[0].c_name}</p>}
                                                    {validator.current.message(
                                                        'country',
                                                        monthwisereport.country,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Country Required',
                                                            }
                                                        })}

                                                </div>

                                                <div className='col-md-4'>
                                                    <label><b>Legal Entity  : </b><span style={{ color: "red" }}>*</span></label>
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                        <Select
                                                            placeholder='Enter Legal Entity'
                                                            onChange={(value, data) => {
                                                                setCurrentEntity(value)
                                                                setMonthWiseReport({
                                                                    ...monthwisereport,
                                                                    legel_entity: data.children,
                                                                    domain: "",
                                                                    division: "",
                                                                    category: "",
                                                                    unit: '',
                                                                    domain_id: null,
                                                                    division_id: null,
                                                                    category_id: null,
                                                                    unit_id: null,
                                                                    from_month: '',
                                                                    to_month: ''
                                                                })
                                                            }}
                                                            style={{ width: '100%', marginTop: '5px' }}
                                                            value={monthwisereport.legel_entity || undefined}
                                                            disabled={monthwisereport.country == "" ? true : false}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        >
                                                            {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                                return (
                                                                    <Option key={item.le_id}>
                                                                        {item.le_name}
                                                                    </Option>
                                                                );
                                                            })}</Select>
                                                        : <p>{localStorage.getItem('SelectedEntity')}</p>}

                                                    {validator.current.message(
                                                        'legalentity',
                                                        monthwisereport.legel_entity,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Legal Entiy Required',
                                                            }
                                                        })}
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Domain : </b> <span style={{ color: "red" }}>*</span></label>
                                                    <Select
                                                        onChange={(value, data) => {
                                                            let division = _.filter(reportlist && reportlist.compliancewisereport.divisions_list, { le_id: entityid })
                                                            setDivision(division)
                                                            setMonthWiseReport({
                                                                ...monthwisereport,
                                                                domain_id: value,
                                                                domain: data.children
                                                            })
                                                        }}
                                                        placeholder="Enter Domain Name"
                                                        style={{ width: '100%', marginTop: '5px' }}
                                                        value={monthwisereport.domain || undefined}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        disabled={((localStorage.getItem('SelectedEntity') === "All Legal Entity") && monthwisereport.legel_entity == '') ? true : false}
                                                    >
                                                        {domain && domain.length > 0 && domain.map((item, i) => {
                                                            return (
                                                                // data.unit ?
                                                                <Option key={item.d_id}>
                                                                    {item.d_name}
                                                                </Option>
                                                                // :""
                                                            );
                                                        })}
                                                    </Select>
                                                    {validator.current.message(
                                                        'domain',
                                                        monthwisereport.domain,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Domain Required',
                                                            }
                                                        })}
                                                </div>


                                            </div>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: "10px" }}>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <label style={{ "fontWeight": "bold" }}>From Month: <span style={{ color: "red" }} >*</span></label>
                                                    <DatePicker style={{ width: '100%', marginTop: '5px' }}
                                                        disabled={monthwisereport.domain == "" ? true : false}
                                                        allowClear={true} picker="month" format={'MMM-YYYY'}
                                                        // disabledDate={(current) => {
                                                        //     return moment(unitdata.from_date) >= current || current > Date.now();
                                                        // }}
                                                        // disabledDate={(current) => {
                                                        //     console.log(moment(current).format('YYYY'),'currentcurrent');
                                                        //     return (current && current < moment(currentYear, 'YYYY')) || (current && current > moment(currentYear, 'YYYY'))
                                                        //     return moment().format('YYYY') <= current 
                                                        //     ||
                                                        //     currentYear.add(1, 'year') <= current;
                                                        // }}
                                                        value={monthwisereport.from_month ? moment(monthwisereport.from_month) : ''}
                                                        onChange={(date, dateString) => {
                                                            setMonthWiseReport({
                                                                ...monthwisereport,
                                                                from_month: dateString,
                                                                to_month: moment(dateString).add(2, 'months')
                                                            })
                                                        }}
                                                    />
                                                    {validator.current.message(
                                                        'from_month',
                                                        monthwisereport.from_month,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'From Month Required',
                                                            }
                                                        })}
                                                </div>
                                                <div className='col-md-4'>
                                                    <label style={{ "fontWeight": "bold" }}>To Month: <span style={{ color: "red" }} >*</span></label>
                                                    <DatePicker style={{ width: '100%', marginTop: '5px' }}
                                                        disabled={monthwisereport.domain == "" ? true : false}
                                                        allowClear={true} picker="month" format={'MMM-YYYY'}
                                                        value={monthwisereport.to_month ? moment(monthwisereport.to_month) : ''}
                                                        onChange={(date, dateString) => {
                                                            setMonthWiseReport({
                                                                ...monthwisereport,
                                                                to_month: dateString
                                                            })
                                                        }}
                                                    />
                                                    {validator.current.message(
                                                        'to_month',
                                                        monthwisereport.to_month,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'To Month Required',
                                                            }
                                                        })}
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Division : </b></label>
                                                    <Select
                                                        disabled={monthwisereport.domain == "" ? true : false}
                                                        onChange={(value, data) => {
                                                            let category = _.filter(reportlist && reportlist.compliancewisereport.categories_list, { div_id: Number(value) })
                                                            setCategoryList(category)
                                                            let unit = _.filter(reportlist && reportlist.compliancewisereport.unit_legal_entity, { division_id: Number(value) })
                                                            setUnit(unit)
                                                            setMonthWiseReport({
                                                                ...monthwisereport,
                                                                division_id: value,
                                                                division: data.children,
                                                                category_id: null,
                                                                category: '',
                                                                unit: '',
                                                                unit_id: null
                                                            })
                                                        }}
                                                        placeholder="Enter Division Name"
                                                        style={{ width: '100%', marginTop: '5px' }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        value={monthwisereport.division || undefined}
                                                    >
                                                        {division && division.length > 0 && division.map((item, i) => {
                                                            return (
                                                                <Option key={item.div_id}>
                                                                    {item.div_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>


                                            </div>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: "10px" }}>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <label><b>Category : </b></label>
                                                    <Select
                                                        disabled={monthwisereport.domain == "" ? true : false}
                                                        onChange={(value, data) => {
                                                            let unit = _.filter(reportlist && reportlist.compliancewisereport.unit_legal_entity, { category_id: Number(value) })
                                                            setUnit(unit)
                                                            setMonthWiseReport({
                                                                ...monthwisereport,
                                                                category_id: value,
                                                                category: data.children,
                                                                unit: '',
                                                                unit_id: null
                                                            })
                                                        }}
                                                        placeholder="Enter Category Name"
                                                        style={{ width: '100%', marginTop: '5px' }}
                                                        value={monthwisereport.category || undefined}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    >
                                                        {categorylist && categorylist.length > 0 && categorylist.map((item, i) => {
                                                            return (
                                                                <Option key={item.cat_id}>
                                                                    {item.cat_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Vendor : </b></label>
                                                    <Select
                                                        disabled={monthwisereport.domain == "" ? true : false}
                                                        onChange={(value, data) => {
                                                            setMonthWiseReport({
                                                                ...monthwisereport,
                                                                unit_id: value,
                                                                unit: `${data.children[0]}-${data.children[2]}`
                                                            })
                                                        }}
                                                        placeholder="Enter Vendor"
                                                        style={{ width: '100%', marginTop: '5px' }}
                                                        value={monthwisereport.unit || undefined}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    >
                                                        {unit && unit.length > 0 && unit.map((item, i) => {
                                                            return (
                                                                <Option key={item.unit_id}>
                                                                    {item.unit_code} - {item.unit_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                                {/* <div className='col-md-4'>
                                                    <label><b>Period : </b></label>
                                                    <Select
                                                        defaultValue="All"
                                                        onChange={(value) => {
                                                            setMonthWiseReport({
                                                                ...monthwisereport,
                                                                period: value
                                                            })
                                                        }}
                                                        style={{ width: '100%', marginTop: '5px' }}

                                                        value={monthwisereport.period || undefined}
                                                    >
                                                        <Option key="All">All</Option>
                                                        {monthlyArray && monthlyArray.length > 0 && monthlyArray.map((item) => {
                                                            return (
                                                                <Option key={item}>{item}</Option>
                                                            )
                                                        })}
                                                    </Select>
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: "10px" }}>
                                            <div className='row'>
                                                <div className='col-md-4'></div>
                                                <div className='col-md-4 popupbtncolour'>
                                                    <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')} style={{ background: "#0096FF", borderColor: "#0096FF", marginLeft: "65px", marginRight: "10px", marginTop: '5px' }} onClick={onshow}
                                                        icon={<EyeOutlined />}  >
                                                        Show
                                                    </Button>
                                                    <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')} style={{ background: "#32a852", borderColor: "#32a852", marginTop: '5px' }} onClick={onexport}
                                                        icon={<ExportOutlined />} >
                                                        Export
                                                    </Button>
                                                    {/* <button className="btn btn-secondary" style={{ marginLeft: "59px", marginRight: "10px", width: "100px" }} onClick={onshow}>Show</button>
                                                        <button className="btn btn-success" style={{ width: "100px" }} >Export</button> */}
                                                </div>
                                                <div className='col-md-4'></div>
                                            </div>
                                        </div>
                                    </Panel>
                                </Collapse>
                                <Modal
                                    onCancel={setcancelFilter}
                                    footer={false}
                                    title="Month Wise Status Report"
                                    className={
                                        "add-service-prv cons-report " +
                                        localStorage.getItem("currentTheme")
                                    }
                                    visible={filterModalVisible}
                                >
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Country : </b> <span style={{ color: "red" }}>*</span></label>
                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                    <Select
                                                        placeholder="Enter Country"
                                                        onChange={(value) => {
                                                            setMonthWiseReport({
                                                                ...monthwisereport,
                                                                country: value
                                                            })
                                                        }}
                                                        style={{ width: '100%', marginTop: '5px' }}

                                                        value={monthwisereport.country || undefined}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                                                    >
                                                        {countrydata && countrydata.length && countrydata.map((item, i) => {
                                                            return (
                                                                <Option key={item.c_id}>
                                                                    {item.c_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                    : <p>{sessionArr[0].c_name}</p>}
                                                {validator.current.message(
                                                    'country',
                                                    monthwisereport.country,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Country Required',
                                                        }
                                                    })}

                                            </div>

                                            <div className='col-md-4'>
                                                <label><b>Legal Entity  : </b><span style={{ color: "red" }}>*</span></label>
                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                    <Select
                                                        placeholder='Enter Legal Entity'
                                                        onChange={(value, data) => {
                                                            setCurrentEntity(value)
                                                            setMonthWiseReport({
                                                                ...monthwisereport,
                                                                legel_entity: data.children,
                                                                domain: "",
                                                                division: "",
                                                                category: "",
                                                                unit: '',
                                                                domain_id: null,
                                                                division_id: null,
                                                                category_id: null,
                                                                unit_id: null,
                                                                from_month: '',
                                                                to_month: ''
                                                            })
                                                        }}
                                                        style={{ width: '100%', marginTop: '5px' }}
                                                        value={monthwisereport.legel_entity || undefined}
                                                        disabled={monthwisereport.country == "" ? true : false}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    >
                                                        {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                            return (
                                                                <Option key={item.le_id}>
                                                                    {item.le_name}
                                                                </Option>
                                                            );
                                                        })}</Select>
                                                    : <p>{localStorage.getItem('SelectedEntity')}</p>}

                                                {validator.current.message(
                                                    'legalentity',
                                                    monthwisereport.legel_entity,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Legal Entiy Required',
                                                        }
                                                    })}
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Domain : </b> <span style={{ color: "red" }}>*</span></label>
                                                <Select
                                                    onChange={(value, data) => {
                                                        let division = _.filter(reportlist && reportlist.compliancewisereport.divisions_list, { le_id: entityid })
                                                        setDivision(division)
                                                        setMonthWiseReport({
                                                            ...monthwisereport,
                                                            domain_id: value,
                                                            domain: data.children
                                                        })
                                                    }}
                                                    placeholder="Enter Domain Name"
                                                    style={{ width: '100%', marginTop: '5px' }}
                                                    value={monthwisereport.domain || undefined}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    disabled={((localStorage.getItem('SelectedEntity') === "All Legal Entity") && monthwisereport.legel_entity == '') ? true : false}
                                                >
                                                    {domain && domain.length > 0 && domain.map((item, i) => {
                                                        return (
                                                            // data.unit ?
                                                            <Option key={item.d_id}>
                                                                {item.d_name}
                                                            </Option>
                                                            // :""
                                                        );
                                                    })}
                                                </Select>
                                                {validator.current.message(
                                                    'domain',
                                                    monthwisereport.domain,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Domain Required',
                                                        }
                                                    })}
                                            </div>


                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label style={{ "fontWeight": "bold" }}>From Month: <span style={{ color: "red" }} >*</span></label>
                                                <DatePicker style={{ width: '100%', marginTop: '5px' }}
                                                    disabled={monthwisereport.domain == "" ? true : false}
                                                    allowClear={true} picker="month" format={'MMM YYYY'}
                                                    // disabledDate={(current) => {
                                                    //     return moment(unitdata.from_date) >= current || current > Date.now();
                                                    // }}
                                                    // disabledDate={(current) => {
                                                    //     console.log(moment(current).format('YYYY'),'currentcurrent');
                                                    //     return (current && current < moment(currentYear, 'YYYY')) || (current && current > moment(currentYear, 'YYYY'))
                                                    //     return moment().format('YYYY') <= current 
                                                    //     ||
                                                    //     currentYear.add(1, 'year') <= current;
                                                    // }}
                                                    value={monthwisereport.from_month ? moment(monthwisereport.from_month) : ''}
                                                    onChange={(date, dateString) => {
                                                        setMonthWiseReport({
                                                            ...monthwisereport,
                                                            from_month: dateString,
                                                            to_month: moment(dateString).add(2, 'months')
                                                        })
                                                    }}
                                                />
                                                {validator.current.message(
                                                    'from_month',
                                                    monthwisereport.from_month,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'From Month Required',
                                                        }
                                                    })}
                                            </div>
                                            <div className='col-md-4'>
                                                <label style={{ "fontWeight": "bold" }}>To Month: <span style={{ color: "red" }} >*</span></label>
                                                <DatePicker style={{ width: '100%', marginTop: '5px' }}
                                                    disabled={monthwisereport.domain == "" ? true : false}
                                                    allowClear={true} picker="month" format={'MMM YYYY'}
                                                    value={monthwisereport.to_month ? moment(monthwisereport.to_month) : ''}
                                                    onChange={(date, dateString) => {
                                                        setMonthWiseReport({
                                                            ...monthwisereport,
                                                            to_month: dateString
                                                        })
                                                    }}
                                                />
                                                {validator.current.message(
                                                    'to_month',
                                                    monthwisereport.to_month,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'To Month Required',
                                                        }
                                                    })}
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Division : </b></label>
                                                <Select
                                                    disabled={monthwisereport.domain == "" ? true : false}
                                                    onChange={(value, data) => {
                                                        let category = _.filter(reportlist && reportlist.compliancewisereport.categories_list, { div_id: Number(value) })
                                                        setCategoryList(category)
                                                        let unit = _.filter(reportlist && reportlist.compliancewisereport.unit_legal_entity, { division_id: Number(value) })
                                                        setUnit(unit)
                                                        setMonthWiseReport({
                                                            ...monthwisereport,
                                                            division_id: value,
                                                            division: data.children,
                                                            category_id: null,
                                                            category: '',
                                                            unit: '',
                                                            unit_id: null
                                                        })
                                                    }}
                                                    placeholder="Enter Division Name"
                                                    style={{ width: '100%', marginTop: '5px' }}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    value={monthwisereport.division || undefined}
                                                >
                                                    {division && division.length > 0 && division.map((item, i) => {
                                                        return (
                                                            <Option key={item.div_id}>
                                                                {item.div_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>


                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Category : </b></label>
                                                <Select
                                                    disabled={monthwisereport.domain == "" ? true : false}
                                                    onChange={(value, data) => {
                                                        let unit = _.filter(reportlist && reportlist.compliancewisereport.unit_legal_entity, { category_id: Number(value) })
                                                        setUnit(unit)
                                                        setMonthWiseReport({
                                                            ...monthwisereport,
                                                            category_id: value,
                                                            category: data.children,
                                                            unit: '',
                                                            unit_id: null
                                                        })
                                                    }}
                                                    placeholder="Enter Category Name"
                                                    style={{ width: '100%', marginTop: '5px' }}
                                                    value={monthwisereport.category || undefined}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                >
                                                    {categorylist && categorylist.length > 0 && categorylist.map((item, i) => {
                                                        return (
                                                            <Option key={item.cat_id}>
                                                                {item.cat_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Vendor : </b></label>
                                                <Select
                                                    disabled={monthwisereport.domain == "" ? true : false}
                                                    onChange={(value, data) => {
                                                        setMonthWiseReport({
                                                            ...monthwisereport,
                                                            unit_id: value,
                                                            unit: `${data.children[0]}-${data.children[2]}`
                                                        })
                                                    }}
                                                    placeholder="Enter Vendor"
                                                    style={{ width: '100%', marginTop: '5px' }}
                                                    value={monthwisereport.unit || undefined}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                >
                                                    {unit && unit.length > 0 && unit.map((item, i) => {
                                                        return (
                                                            <Option key={item.unit_id}>
                                                                {item.unit_code} - {item.unit_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                            {/* <div className='col-md-4'>
                                                    <label><b>Period : </b></label>
                                                    <Select
                                                        defaultValue="All"
                                                        onChange={(value) => {
                                                            setMonthWiseReport({
                                                                ...monthwisereport,
                                                                period: value
                                                            })
                                                        }}
                                                        style={{ width: '100%', marginTop: '5px' }}

                                                        value={monthwisereport.period || undefined}
                                                    >
                                                        <Option key="All">All</Option>
                                                        {monthlyArray && monthlyArray.length > 0 && monthlyArray.map((item) => {
                                                            return (
                                                                <Option key={item}>{item}</Option>
                                                            )
                                                        })}
                                                    </Select>
                                                </div> */}
                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'></div>
                                            <div className='col-md-4 popupbtncolour'>
                                                <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')} style={{ background: "#0096FF", borderColor: "#0096FF", marginLeft: "65px", marginRight: "10px", marginTop: '5px' }} onClick={onshow}
                                                    icon={<EyeOutlined />}  >
                                                    Show
                                                </Button>
                                                <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')} style={{ background: "#32a852", borderColor: "#32a852", marginTop: '5px' }} onClick={onexport}
                                                    icon={<ExportOutlined />} >
                                                    Export
                                                </Button>
                                                {/* <button className="btn btn-secondary" style={{ marginLeft: "59px", marginRight: "10px", width: "100px" }} onClick={onshow}>Show</button>
                                                        <button className="btn btn-success" style={{ width: "100px" }} >Export</button> */}
                                            </div>
                                            <div className='col-md-4'></div>
                                        </div>
                                    </div>
                                </Modal>
                                <Drawer title="Month Wise Status Report" width={400} placement="right" visible={open} onClose={onClose}>
                                    <Card style={{ padding: 0 }}>
                                        <div className='col-md-12 p-0'>
                                            <div className='row'>
                                                <div className='col-md-12 p-0'>
                                                    <label>Country : <span style={{ fontWeight: 'bold' }}> {monthwisereport.country}</span> </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12 p-0'>
                                            <div className='row'>
                                                <div className='col-md-12 p-0'>
                                                    <label>Legal Entity : <span style={{ fontWeight: 'bold' }}>{monthwisereport.legel_entity}</span></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12 p-0'>
                                            <div className='row'>
                                                <div className='col-md-12 p-0'>
                                                    <label>Domain : <span style={{ fontWeight: 'bold' }}> {monthwisereport.domain}</span> </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12 p-0'>
                                            <div className='row'>
                                                <div className='col-md-12 p-0'>
                                                    <label>Division : <span style={{ fontWeight: 'bold' }}>{monthwisereport.division}</span></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12 p-0' >
                                            <div className='row'>
                                                <div className='col-md-12 p-0'>
                                                    <label>Category : <span style={{ fontWeight: 'bold' }}> {monthwisereport.category}</span> </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12 p-0'>
                                            <div className='row'>
                                                <div className='col-md-12 p-0'>
                                                    <label>Vendor : <span style={{ fontWeight: 'bold' }}>{monthwisereport.unit}</span></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12 p-0'>
                                            <div className='row'>
                                                <div className='col-md-12 p-0'>
                                                    <label>Period : <span style={{ fontWeight: 'bold' }}> {monthwisereport.period}</span> </label>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                </Drawer>
                                {tableshow == true ?

                                    filteredArrayState.length > 0 ?
                                        // <Card>
                                        <>
                                            <>
                                                {/* <h2 style={{ textAlign: 'center' }}>Month Wise Status Report</h2>
                                                <div className='col-md-12' style={{ marginTop: "30px", marginBottom: "15px" }}>
                                                    <div className='row'>
                                                        <div className='col-md-4'>
                                                            <label>Legal Entity : <span style={{ fontWeight: 'bold' }}>{monthwisereport.legel_entity}</span></label>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <label>Country : <span style={{ fontWeight: 'bold' }}>{monthwisereport.country}</span></label>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <label>Domain : <span style={{ fontWeight: 'bold' }}>{monthwisereport.domain}</span></label>
                                                        </div>
                                                    </div>
                                                </div> */}

                                                {/* <Table
                                                    columns={columns}
                                                    scroll={{ x: 1500, Y:400 }}
                                                    dataSource={tabledata}
                                                    pagination
                                                /> */}
                                                <Card className='rounded-3'>
                                                    <>
                                                        <div className='col-md-12  mt-2 mb-2'>
                                                            <div className='row'>
                                                                <div className='col-md-5'>
                                                                    <label ><b>Legal Entity : </b><span>{localStorage.getItem('SelectedEntity') === 'All Legal Entity' ? monthwisereport.legel_entity : localStorage.getItem('SelectedEntity')}</span></label>
                                                                </div>
                                                                <div className='col-md-3'>
                                                                    <label style={{ marginLeft: '10%' }}><b>Country : </b><span>{monthwisereport.countryName ? monthwisereport.countryName : sessionArr[0].c_name}</span></label>
                                                                </div>
                                                                <div className='col-md-4'>
                                                                    <label><b>Domain : </b><span>{monthwisereport.domain}</span></label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* <Table
                                                    columns={columns}
                                                    scroll={{ x: 1500, Y:400 }}
                                                    dataSource={tabledata}
                                                    pagination
                                                /> */}

                                                        <div className={localStorage.getItem("currentTheme") + ' ant-table-wrapper'}>
                                                            <div className='ant-table ant-table-bordered ant-table-fixed-header'>
                                                                <div className='ant-table-container'>
                                                                    <div className='ant-table-content  userprivclass1'>
                                                                        <table>
                                                                            <colgroup>
                                                                                <col style={{ width: '10px' }}></col>
                                                                            </colgroup>
                                                                            <thead className="ant-table-thead antable-head" >
                                                                                <tr>
                                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '10px' }}> # </th>
                                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: "120px" }}>Compliance Task</th>
                                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: "50px" }}><center>Criticality</center></th>
                                                                                    {monthlyArray && monthlyArray.length > 0 && monthlyArray.map((item) => {
                                                                                        return (
                                                                                            <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: "90px" }}><center>{item}</center></th>
                                                                                        )

                                                                                    })}
                                                                                    {/* <th className='ant-table-cell' style={{ width: "90px" }}><center>Jan-2022</center></th>
                                                                            <th className='ant-table-cell' style={{ width: "90px" }}><center>Feb-2022</center></th>
                                                                            <th className='ant-table-cell' style={{ width: "90px" }}><center>Mar-2022</center></th>
                                                                            <th className='ant-table-cell' style={{ width: "90px" }}><center>Apr-2022</center></th>
                                                                            <th className='ant-table-cell' style={{ width: "90px" }}><center>May-2022</center></th>
                                                                           <th className='ant-table-cell' style={{ width: "90px" }}><center>Jun-2022</center></th>
                                                                            <th className='ant-table-cell' style={{ width: "90px" }} ><center>Jul-2022</center></th>
                                                                            <th className='ant-table-cell' style={{ width: "90px" }}><center>Aug-2022</center></th>
                                                                            <th className='ant-table-cell' style={{ width: "90px" }}><center>Sep-2022</center></th> */}
                                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: "100px" }}><center>Next Due Date</center></th>
                                                                                </tr>
                                                                            </thead>
                                                                            {filteredArrayState && filteredArrayState.length > 0 ?
                                                                                filteredArrayState.map((item, i) => {
                                                                                    console.log(item, "itemitem");
                                                                                    return (
                                                                                        <tbody className='ant-table-tbody' style={{ padding: 0 }} key={i}>
                                                                                            <tr className="bg-lightdark">
                                                                                                <td colSpan="100%" style={{ padding: 0 }}>
                                                                                                    <div className='bg-white shadow-sm border-radius-reports'>
                                                                                                        <div className="col-md-12">
                                                                                                            <div className='row m-2'>
                                                                                                                <div className='col-md-3' >
                                                                                                                    <label><b>Unit :</b> {item[0].unit_name}</label>
                                                                                                                </div>
                                                                                                                <div className='col-md-3' >
                                                                                                                    <label><b>Category :</b> {item[0].category_name}</label>
                                                                                                                </div>
                                                                                                                <div className='col-md-3' >
                                                                                                                    <label><b>Division :</b> {item[0].division_name}</label>
                                                                                                                </div>
                                                                                                                <div className='col-md-3' >
                                                                                                                    <label><b>Time Zone :</b> {item[0].tz_name}</label>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </td>
                                                                                            </tr>
                                                                                            {titledata && titledata.map((itemdata, i) => {
                                                                                                let data = _.find(itemdata.child, { unit_id: Number(item[0].unit_id) })
                                                                                                if (data != undefined) {
                                                                                                    if (data.statutory_mapping == itemdata.parent) {
                                                                                                        let temparray = []

                                                                                                        return <>
                                                                                                            <tr className='ant-table-row ant-table-row-level-0'>
                                                                                                                <td colSpan={12} className='ant-table-cell'><b style={{ backgroundColor: "lightyellow" }}>{itemdata.parent}</b></td>
                                                                                                            </tr>
                                                                                                            {itemdata.child.map((items, i) => {
                                                                                                                if (Number(item[0].unit_id) == items.unit_id) {
                                                                                                                    let monthData = items && items.months.split(',')
                                                                                                                    let taskStatus = items && items.task_status.split(',')
                                                                                                                    var newArray = monthData.map((e, i) => [e, taskStatus[i]]);
                                                                                                                    let result = Object.fromEntries(newArray)
                                                                                                                    return <tr>
                                                                                                                        <td className='ant-table-cell'><center>{index + 1}</center></td>
                                                                                                                        <td className='ant-table-cell ellipsis'><Tooltip title={items.compliance_description} >
                                                                                                                            <ExclamationCircleTwoTone /> <span style={{ marginLeft: "10px" }}>{items.compliance_task} </span>
                                                                                                                        </Tooltip> &nbsp;</td>
                                                                                                                        <td className='ant-table-cell'><center>{items.Criticality}</center></td>
                                                                                                                        {result.hasOwnProperty("January") ?
                                                                                                                            <td className='ant-table-cell'><center>{result.hasOwnProperty("January") ? result.January : "-"}</center></td>
                                                                                                                            : ''}
                                                                                                                        {result.hasOwnProperty("February") ?
                                                                                                                            <td className='ant-table-cell'><center>{result.hasOwnProperty("February") ? result.February : "-"}</center></td>
                                                                                                                            : ''}
                                                                                                                        {result.hasOwnProperty("March") ?
                                                                                                                            <td className='ant-table-cell'><center>{result.hasOwnProperty("March") ? result.March : "-"}</center></td>
                                                                                                                            : ''}
                                                                                                                        {result.hasOwnProperty("April") ?
                                                                                                                            <td className='ant-table-cell'><center>{result.hasOwnProperty("April") ? result.April : "-"}</center></td>
                                                                                                                            : ''}
                                                                                                                        {result.hasOwnProperty("May") ?
                                                                                                                            <td className='ant-table-cell'><center>{result.hasOwnProperty("May") ? result.May : "-"}</center></td>
                                                                                                                            : ''}
                                                                                                                        {result.hasOwnProperty("June") ?
                                                                                                                            <td className='ant-table-cell'><center>{result.hasOwnProperty("June") ? result.June : "-"}</center></td>
                                                                                                                            : ''}
                                                                                                                        {result.hasOwnProperty("July") ?
                                                                                                                            <td className='ant-table-cell'><center>{result.hasOwnProperty("July") ? result.July : "-"}</center></td>
                                                                                                                            : ''}
                                                                                                                        {result.hasOwnProperty("August") ?
                                                                                                                            <td className='ant-table-cell'><center>{result.hasOwnProperty("August") ? result.August : "-"}</center></td>
                                                                                                                            : ''}
                                                                                                                        {result.hasOwnProperty("September") ?
                                                                                                                            <td className='ant-table-cell'><center>{result.hasOwnProperty("September") ? result.September : "-"}</center></td>
                                                                                                                            : ''}
                                                                                                                        {result.hasOwnProperty("October") ?
                                                                                                                            <td className='ant-table-cell'><center>{result.hasOwnProperty("October") ? result.October : "-"}</center></td>
                                                                                                                            : ''}
                                                                                                                        <td className='ant-table-cell'><center>{items.due_date}</center></td>
                                                                                                                        <span hidden>{index = index + 1}</span>
                                                                                                                        {/* <td ><center>-</center></td>
                                                                                                        <td ><center>-</center></td> */}

                                                                                                                        {/* </tr> */}

                                                                                                                    </tr>
                                                                                                                    // }




                                                                                                                }

                                                                                                            })

                                                                                                            }
                                                                                                        </>
                                                                                                    }
                                                                                                }
                                                                                            })
                                                                                            }
                                                                                        </tbody>
                                                                                    )
                                                                                }) :
                                                                                <tr>

                                                                                    <td colSpan="100%" style={{ paddingTop: '15px', textAlign: 'center', fontSize: '14px', fontWeight: 'bold', paddingBottom: '15px' }}>No Records Found</td>

                                                                                </tr>
                                                                            }
                                                                        </table>
                                                                        {/* <br /> */}

                                                                        <div>
                                                                            {/* <button
                                                    className="btn btn-light-success rounded-pill px-4 text-success"
                                                    onClick={submitrec}>Submit</button> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </>
                                                    {/* <Pagination
                                                showTotal={(total) => `Showing ${reportlist && reportlist.tabledata && reportlist.tabledata.total_count} entries`}
                                                defaultPageSize={25}
                                                defaultCurrent={1}
                                            /> */}
                                                    {/* {index != 0 ?
                                            <div className='col-md-12 mt-2' style={{ position: 'absolute', bottom: '-25px', left: 0 }}>
                                                <label style={{ marginLeft: "28px" }}>Showing {pageSize * (current - 1) + 1} to {index} of {reportlist && reportlist.tabledata && reportlist.tabledata.total_count} entries</label>
                                            </div> : ''}
                                        <div style={{ position: 'absolute', bottom: '-25px', right: 0 }}>
                                            <Pagination
                                                current={current}
                                                showSizeChanger={reportlist && reportlist.tabledata && reportlist.tabledata.total_count > 25 ? false : true}
                                                // showQuickJumper={true}
                                                onShowSizeChange={() => {
                                                    setCurrent(1)
                                                    // setpageState(true)
                                                }}
                                                pageSizeOptions={[25, 50, 100]}
                                                hideOnSinglePage={true}
                                                defaultPageSize={25}
                                                onChange={(page, pageSizes) => {
                                                    setpageState(true)
                                                    setCurrent(pageSize !== pageSizes ? 1 : page);
                                                    setPageSize(pageSizes)
                                                }}
                                                total={reportlist && reportlist.tabledata && reportlist.tabledata.total_count}
                                            // showTotal={(total) => `Showing 1 to ${index} of ${reportlist && reportlist.tabledata && reportlist.tabledata.total_count} entries`}
                                            />
                                        </div> */}

                                                    <div className="row mt-3">
                                                        {index != 0 ?
                                                            <div className='col-md-6' >
                                                                <label>Showing {pageSize * (current - 1) + 1} to {index} of {reportlist && reportlist.tabledata && reportlist.tabledata.total_count} entries</label>
                                                            </div> : ''}

                                                        <div className='col-md-6 text-right'>
                                                            <Pagination
                                                                current={current}
                                                                showSizeChanger={true}
                                                                // showQuickJumper={true}
                                                                onShowSizeChange={() => {
                                                                    setCurrent(1)
                                                                    // setpageState(true)
                                                                }}
                                                                pageSizeOptions={[25, 50, 100]} hideOnSinglePage={reportlist && reportlist.tabledata && reportlist.tabledata.total_count > 25 ? false : true} defaultPageSize={25}
                                                                onChange={(page, pageSizes) => {
                                                                    setpageState(true)
                                                                    setCurrent(pageSize !== pageSizes ? 1 : page);
                                                                    setPageSize(pageSizes)
                                                                }} total={reportlist && reportlist.tabledata && reportlist.tabledata.total_count}
                                                            // showTotal={(total) => `Showing 1 to ${index} of ${tableData.total_count} entries`}
                                                            />
                                                        </div>
                                                    </div>

                                                    {/* <div className='col-md-12' style={{ marginTop: "20px" }}>
                                                <div className='row'>

                                                    <div className='col-md-3' >
                                                        <label style={{ marginLeft: "28px" }}><b>Showing  </b><a href='javascript:;' style={{ fontSize: "18px" }}>{reportlist && reportlist.tabledata && reportlist.tabledata.total_count}</a>  entries</label>
                                                    </div>
                                                    <div className='col-md-3'></div>
                                                    <div className='col-md-3'></div>
                                                    <div className='col-md-3'>
                                                    </div>

                                                </div>
                                            </div> */}
                                                </Card>

                                            </>




                                        </>
                                        // </Card>


                                        : '' : ""}
                            </div>
                            {/* </div> */}
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}
export default connect(mapStateToProps, {
    MonthReportData,
    MonthReportTable,
    MonthReportExport
})(MonthWiseStatusReport);