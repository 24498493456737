import React, { Fragment } from 'react'
import { connect } from 'react-redux';
import { Collapse, Select, Input, DatePicker, Card, Modal, Button, Table, Pagination, Tooltip } from 'antd';
import moment from 'moment';
import { useState, useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import { ExclamationCircleTwoTone, ExclamationCircleOutlined, PlayCircleOutlined, FullscreenOutlined, UpOutlined, FilterOutlined, EyeOutlined, ExportOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { UnitWiseReportFilters, UnitWiseReport, UnitExportData } from './../../../Store/Action/Report/CV_VendorReport';
import { fullscreenmode, fullscreenexit, handleKeyDown } from './../../../../src/Libs/fullscreenmode';
import ScrollToTop from "react-scroll-to-top";


const mapStateToProps = (state) => ({
    unitreports: state.UnitReport.unitwisereport,
})

const VendorReports = ({
    UnitWiseReportFilters,
    UnitWiseReport,
    UnitExportData,
    unitreports: { unitreport, unitwisereports }
}) => {
    const location = useLocation();
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    // const entityid = localStorage.getItem('SelectedEntityid');
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const [current, setCurrent] = useState(1);
    const [pageState, setpageState] = useState(false);
    const [pageSize, setPageSize] = useState(25)
    const [paginationArray, setPaginationArray] = useState([])
    const sessionArr = sessionParsedValue.entity_info
    const country_info = sessionParsedValue.country_info
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid').replaceAll('"', ''));
    const paramid = location.pathname;
    const _ = require("lodash");
    console.log(unitwisereports, 'unitwisereports');
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [tableshow, setTableShow] = useState(false)
    const [categoryList, setCategoryList] = useState([])
    const [act, setAct] = useState([])
    const [disableButton, setDisableButton] = useState(false)
    const [frequency, setFrequency] = useState([])
    const [Compliancetask, setCompliancetask] = useState([])
    const [usertype, setUsertype] = useState([])
    const [division, setDivision] = useState([])
    const [domain, setDomain] = useState([])
    const [unitcode, setUnitcode] = useState([])
    const [users, setUsers] = useState([])
    const [unit, setUnit] = useState([])
    const [filteredArray, setFilteredArray] = useState([])
    const [filteredArrayState, setfilteredArrayState] = useState([])
    const [titledata, Settitledata] = useState([])
    let [count, setcount] = useState(0)
    const [checkboxValue, setCheckBoxValue] = useState([1, 2, 7, 8, 9, 10, 11, 30, 33])
    const [key, setKey] = useState("1")
    const [legalstate, Setlegalstate] = useState(false)
    const [unitdata, setUnitData] = useState({
        country: '',
        countryName: '',
        domain: 0,
        domain_name: '',
        user: 0,
        user_name: '',
        // legel_entity: entityid,
        legal_entity_id: "",
        legal_entity_name: localStorage.getItem('SelectedEntity') === "All Legal Entity" ? '' : localStorage.getItem('SelectedEntity'),
        act: 0,
        act_name: '',
        from_date: "",
        division: 0,
        division_name: '',
        compliance_task: null,
        to_date: "",
        category: 0,
        category_name: '',
        compliance_frequency: 0,
        compliance_frequency_name: 'All',
        compliance_task_status: '',
        compliance_task_status_name: 'All',
        unit: '',
        unit_name: '',
        user_type: '',
        user_type_name: 'All'

        // task_category: "",
        // task_sub_category: "",
    })
    const validator = useRef(new SimpleReactValidator());
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { Panel } = Collapse;
    const { Option } = Select;
    const [filterTaskAcc, setFilterTaskAcc] = useState(true);
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const [exitCollapse, setExitCollapse] = useState(false);

    const columns = [
        {
            title: "Compliance Task",
            dataIndex: false,
            key: false,
            width: '270px',
            render: (record) => {
                return (
                    <>
                        <Tooltip title={record.compliance_description}>
                            <ExclamationCircleTwoTone />
                        </Tooltip> &nbsp;
                        {record.compliance_task}
                    </>
                )
            },
        },
        {
            title: "Frequency",
            dataIndex: 'frequency_name',
            width: '50px',
        },
        {
            title: "Due Date",
            dataIndex: 'due_date',
            width: '120px',
        },
        {
            title: "Compliance Task Status",
            dataIndex: 'task_status',
            width: '120px',
            // ellipsis: true
        },
        {
            title: "User Name",
            dataIndex: 'assignee_name',
            width: '170px',
        },
        {
            title: "Activity Status",
            dataIndex: 'activity_status',
            width: '120px',
        },
        {
            title: "Activity Date",
            dataIndex: 'activity_date',
            width: '120px',
        },
        {
            title: "Uploaded Document",
            // dataIndex: 'activity_date',
            width: '150px',
        },
        {
            title: "Task Completion Date/Document issued Date",
            // dataIndex: 'activity_date',
            width: '130px',
        },
    ]

    useEffect(() => {
        if (isAuth) {
            if (legalstate === true) {
                if (entityid != "null") {
                    UnitWiseReportFilters({
                        payload: [
                            authtoken,
                            {
                                "session_token": authtoken,
                                "request": [
                                    "GetUnitWiseReportFilters",
                                    {
                                        "country_id": Number(unitdata.country),
                                        "legal_entity_id": entityid
                                    }
                                ]
                            }
                        ],
                        paramid: paramid
                    })
                }
                Setlegalstate(false)
            }
        }
    }, [isAuth, entityid, legalstate])

    useEffect(() => {
        if (unitreport) {
            // setaskCategory(filterValue && filterValue.Data && filterValue.Data.task_category_list)
            setAct(unitreport && unitreport.act_legal_entity)
            setCategoryList(unitreport && unitreport.categories_list)
            setFrequency(unitreport && unitreport.compliance_frequency_list)
            setCompliancetask(unitreport && unitreport.compliance_task_status)
            setUsertype(unitreport && unitreport.compliance_user_type)
            setUsers(unitreport && unitreport.compliance_users)
            setDivision(unitreport && unitreport.divisions_list)
            setDomain(unitreport && unitreport.domains)
            setUnitcode(unitreport && unitreport.unit_code_label_list)
            setUnit(unitreport && unitreport.unit_legal_entity)
        }
    }, [unitreport])
    useEffect(() => {
        if (unitwisereports && unitwisereports.unit_compliances && unitwisereports.unit_compliances.length > 0) {
            var array = unitwisereports && unitwisereports.unit_compliances.slice((current - 1) * pageSize, (current * pageSize))
            setPaginationArray(array)
        }

    }, [unitwisereports && unitwisereports.unit_compliances, pageSize, current])

    useEffect(() => {
        validator.current.showMessages()
    }, [])

    useEffect(() => {
        if (unitwisereports && unitwisereports.unit_compliances && unitwisereports.unit_compliances.length > 0) {
            let uniqueObjArray = [...new Map(unitwisereports && unitwisereports.unit_compliances && unitwisereports.unit_compliances.length && unitwisereports.unit_compliances.map((item) => [item["domain_id"], item])).values()];
            const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                return item.unit_id
            })
            let tempArr = []
            for (let i in uniqueIds) {
                let filteredArr = _.filter(unitwisereports && unitwisereports.unit_compliances && unitwisereports.unit_compliances.length && unitwisereports.unit_compliances, { unit_id: uniqueIds[i] })
                tempArr.push(filteredArr)
            }
            setFilteredArray([...filteredArray, tempArr])
        }
    }, [unitwisereports && unitwisereports.unit_compliances])

    useEffect(() => {
        if (filteredArray.length) {
            // let temp = []
            filteredArray && filteredArray.map((item, i) => {
                return (
                    setfilteredArrayState(item)
                )
            })
            // for (let i in filteredArray) {
            //     temp = filteredArray[i]
            // }
            // setfilteredArrayState([...filteredArrayState, ...temp])
        }
    }, [filteredArray])

    useEffect(() => {
        let array = [];
        let finalarray = [];
        let datavalue = [];
        if (paginationArray && paginationArray.length) {
            const ab = paginationArray && paginationArray.length && paginationArray.map(function (item) {
                if ((array.indexOf(item.statutory_mapping) < 0))
                    array.push(item.statutory_mapping)
            })
            array.map((data, i) => {
                let temp = [];
                let entityArray = [];
                let datavalue = [];
                if (data) {
                    paginationArray && paginationArray.length && paginationArray.map((child, childIndex) => {
                        if (data === child.statutory_mapping) {
                            entityArray = {
                                'activity_date': child.activity_date,
                                'activity_status': child.activity_status,
                                'assignee_name': child.assignee_name,
                                'category_name': child.category_name,
                                'completion_date': child.completion_date,
                                'compliance_activity_id': child.compliance_activity_id,
                                'compliance_description': child.compliance_description,
                                'compliance_history_id': child.compliance_history_id,
                                'compliance_id': child.compliance_id,
                                'compliance_task': child.compliance_task,
                                'country_id': child.country_id,
                                'division_name': child.division_name,
                                'doc_ref_num': child.doc_ref_num,
                                'document_name': child.document_name,
                                'domain_id': child.domain_id,
                                'domain_name': child.domain_name,
                                'due_date': child.due_date,
                                'extend_due_date': child.extend_due_date,
                                'frequency_name': child.frequency_name,
                                'history_count': child.history_count,
                                'interim_count': child.interim_count,
                                'legal_entity_id': child.legal_entity_id,
                                'logo_url': child.logo_url,
                                'remarks_count': child.remarks_count,
                                'start_date': child.start_date,
                                'statutory_mapping': child.statutory_mapping,
                                'task_status': child.task_status,
                                'tz_name': child.tz_name,
                                'unit_address': child.unit_address,
                                'unit_id': child.unit_id,
                                'unit_name': child.unit_name,
                                'url': child.url,
                                // 'collapseId': i,
                                'childId': childIndex,
                                'parentId': i
                            }
                            temp.push(entityArray)
                        }
                    })
                }

                datavalue = {
                    index: i,
                    parent: data,
                    child: temp
                }
                finalarray.push(datavalue)
            })
            Settitledata(finalarray)
        }
    }, [paginationArray && paginationArray.length && paginationArray])

    useEffect(() => {
        if (entityid != null) {
            setUnitData({
                ...unitdata,
                country: sessionArr[0].c_id
            })
        }
    }, [entityid != null])

    // useEffect(() => {
    //     // setTempeditdata(editdata)
    //     let array = [];
    //     let finalarray = [];
    //     // let datavalue = [];
    //     if (unitwisereports.unit_compliances) {
    //         const ab = unitwisereports.unit_compliances.map(function (item) {
    //             if ((array.indexOf(item.statutory_mapping) < 0))
    //                 array.push(item.statutory_mapping)
    //         })

    //         array.map((data, i) => {
    //             let temp = [];
    //             let entityArray = [];
    //             let datavalue = [];
    //             if (data) {
    //                 unitwisereports.unit_compliances.map((child, childIndex) => {
    //                     if (data === child.statutory_mapping) {
    //                         entityArray = {
    //                             'activity_date': child.activity_date,
    //                             'activity_status': child.activity_status,
    //                             'assignee_name': child.assignee_name,
    //                             'category_name': child.category_name,
    //                             'completion_date': child.completion_date,
    //                             'compliance_activity_id': child.compliance_activity_id,
    //                             'compliance_description': child.compliance_description,
    //                             'compliance_history_id': child.compliance_history_id,
    //                             'compliance_id': child.compliance_id,
    //                             'compliance_task': child.compliance_task,
    //                             'country_id': child.country_id,
    //                             'division_name': child.division_name,
    //                             'doc_ref_num': child.doc_ref_num,
    //                             'document_name': child.document_name,
    //                             'domain_id': child.domain_id,
    //                             'domain_name': child.domain_name,
    //                             'due_date': child.due_date,
    //                             'extend_due_date': child.extend_due_date,
    //                             'frequency_name': child.frequency_name,
    //                             'history_count': child.history_count,
    //                             'interim_count': child.interim_count,
    //                             'legal_entity_id': child.legal_entity_id,
    //                             'logo_url': child.logo_url,
    //                             'remarks_count': child.remarks_count,
    //                             'start_date': child.start_date,
    //                             'statutory_mapping': child.statutory_mapping,
    //                             'task_status': child.task_status,
    //                             'tz_name': child.tz_name,
    //                             'unit_address': child.unit_address,
    //                             'unit_id': child.unit_id,
    //                             'unit_name': child.unit_name,
    //                             'url': child.url,
    //                             // 'collapseId': i,
    //                             'childId': childIndex,
    //                             'parentId': i
    //                         }
    //                         temp.push(entityArray)
    //                     }
    //                 })
    //             }

    //             datavalue = {
    //                 index: i,
    //                 parent: data,
    //                 child: temp
    //             }
    //             finalarray.push(datavalue)
    //         })
    //         Settitledata(finalarray)
    //     }

    // }, [unitwisereports.unit_compliances])

    useEffect(() => {
        if (unitdata.legal_entity_name == localStorage.getItem('SelectedEntity')) {
            Setlegalstate(true)
        }
    }, [unitdata.legal_entity_name])

    useEffect(() => {
        if (pageState === true) {
            setcount(pageSize * (current - 1))
        }
    }, [pageState, pageSize, current])

    const onshow = () => {
        setAddFormSubmit(true)
        setfilteredArrayState([])
        setFilterModalVisible(false);
        if (validator.current.allValid()) {
            setExitCollapse(true);
            setFilterTaskAcc(false);
            setTableShow(true)
            setKey(0)
            UnitWiseReport({
                payload: [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetUnitWiseReport",
                            {
                                "country_id": Number(unitdata.country),
                                "legal_entity_id": entityid,
                                "division_id": Number(unitdata.division),
                                "category_id": Number(unitdata.category),
                                "unit_id": Number(unitdata.unit),
                                "d_id_optional": Number(unitdata.domain),
                                "parent_id": Number(unitdata.act),
                                "compliance_task": unitdata.compliance_task,
                                "frequency_id": Number(unitdata.compliance_frequency),
                                "user_type": unitdata.user_type_name,
                                "user_id": Number(unitdata.user),
                                "due_from_date": moment(new Date(unitdata.from_date)).format('DD-MMM-YYYY'),
                                "due_to_date": moment(new Date(unitdata.to_date)).format('DD-MMM-YYYY'),
                                "task_status": unitdata.compliance_task_status_name,
                                "csv": false,
                                "from_count": 0,
                                "page_count": 0,
                                "list_check": 0
                            }
                        ]
                    }
                ],
                paramid: paramid
            })

        }
    }

    const selectAllCheckBox = (checked) => {
        var ele = document.getElementsByName('chk');
        let tempAllIds = []
        if (checked == true) {
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox') {
                    ele[i].checked = true;
                    tempAllIds.push(Number(ele[i].value))
                }
            }
            setCheckBoxValue(tempAllIds)
        }
        else {
            var ele = document.getElementsByName('chk');
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox')
                    ele[i].checked = false;

            }
            setCheckBoxValue([1, 2, 7, 8, 9, 10, 11, 30, 33])
        }
    }

    const checkBoxOnChange = (e) => {
        let tempIds = []
        let temp = checkboxValue
        if (temp.includes(Number(e.target.value)) == true) {
            for (let i in temp) {
                if (temp[i] == Number(e.target.value)) {
                    temp.splice(i, 1)
                }
            }
            setCheckBoxValue([...temp])
        } else {
            tempIds.push(Number(e.target.value))
            setCheckBoxValue([...checkboxValue, ...tempIds])
        }
    }

    const showModal = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            setIsModalOpen(true);
        }
    };

    const handleOk = () => {
        setIsModalOpen(false);
        setAddFormSubmit(true)
        setTableShow(true)
        setKey(0)
        UnitExportData({
            payload: [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetUnitWiseReport",
                        {
                            "country_id": Number(unitdata.country),
                            "legal_entity_id": entityid,
                            "division_id": Number(unitdata.division),
                            "category_id": Number(unitdata.category),
                            "unit_id": Number(unitdata.unit),
                            "d_id_optional": Number(unitdata.domain),
                            "parent_id": Number(unitdata.act),
                            "compliance_task": unitdata.compliance_task,
                            "frequency_id": Number(unitdata.compliance_frequency),
                            "user_type": unitdata.user_type,
                            "user_id": Number(unitdata.user),
                            "due_from_date": moment(new Date(unitdata.from_date)).format('DD-MMM-YYYY'),
                            "due_to_date": moment(new Date(unitdata.to_date)).format('DD-MMM-YYYY'),
                            "task_status": unitdata.compliance_task_status,
                            "csv": true,
                            "from_count": 0,
                            "page_count": 0,
                            "list_check": checkboxValue
                        }
                    ]
                }
            ],
            paramid: paramid
        })
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const item = [
        {
            compliance: "manoj"
        }
    ]
    const onChange = (key) => {
        // console.log(key);
    };

    const homescreen = () => {
        window.location.href = '/dashboard'
    }

    const disablecondition = (e) => {
        var currentToDate = moment(unitdata.from_date).add(3, 'months')
        if (currentToDate < moment(e)) {
            setDisableButton(true)
        } else {
            setDisableButton(false)
        }
    }

    const filterHandle = () => {
        setFilterTaskAcc(true);
        setFilterModalVisible(true);
    };

    const setcancelFilter = () => {
        setFilterModalVisible(false);
        setFilterTaskAcc(false);
    };

    //  Full screen mode //

    const [fullscreen, setfullscreen] = useState(false);
    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', handleKeyDown, false);
            document.addEventListener('webkitfullscreenchange', handleKeyDown, false);
            return () => {
                document.addEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('webkitfullscreenchange', handleKeyDown);
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode();
            setfullscreen(true);
        } else {
            fullscreenexit();
            setfullscreen(false);
        }
    }

    return (
        <div id='page-wrapper' className="page-wrapper">
            <ScrollToTop smooth color="red" component={<Tooltip title="Back to top"><UpOutlined /></Tooltip>} />

            <div className="page-titles pb-2 pt-2">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <span className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <span style={{ fontSize: '14px', fontWeight: "bold" }}>Report</span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: '15px', fontWeight: 'bold' }}>
                                    <span style={{ fontSize: "15px", fontWeight: "bold" }}>Compliance Reports</span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                    <span>Vendor Report</span>
                                </li>
                                <li className="mx-1 mb-2">
                                    {/* <Popover
                                        style={{ borderRadius: "10px" }}
                                        placement="right"
                                        content={content}
                                        title='Overall Statistics'
                                    >
                                        {" "}
                                        <ExclamationCircleTwoTone
                                            style={{ fontSize: "12px" }}
                                        />{" "}
                                    </Popover> */}
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div className="col-lg-6 text-end">
                        <div style={{ display: "initial" }}>
                            <Button className="mx-1" shape="round" disabled>
                                Total Vendors : {filteredArrayState?.length}
                            </Button>
                            <Button className="mx-1 fw-2" shape="round" disabled>
                                Total Acts : {titledata ? titledata?.length : '0'}
                            </Button>
                        </div>
                        <Button
                            type="primary"
                            shape="round"
                            style={{
                                display: filterTaskAcc ? "none" : "initial",
                            }}
                            onClick={filterHandle}
                        >
                            <FilterOutlined /> Filter
                        </Button>
                        <button onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black ms-2 full-mode fullscreenradius" >
                            <Tooltip placement="left" title="Full Screen Mode"><FullscreenOutlined /></Tooltip>
                        </button>
                    </div>
                </div>
            </div>
            <Modal title="Select Fields" className={
                localStorage.getItem("currentTheme") + " add-service-prv"
            }
                visible={isModalOpen}
                onOk={handleOk}
                footer={null}
                onCancel={handleCancel}
                width={100} >
                <div className="row">
                    <div className="col-lg-3">
                        <input
                            id="select_all"
                            width="100%"
                            type="checkbox"
                            autocomplete="off"
                            checked={checkboxValue.length === 35 ? true : false}
                            onClick={(e) => {
                                let checked = e.target.checked;
                                selectAllCheckBox(checked);
                            }}
                        />{" "}
                        Select All
                    </div>
                    <div className="col-lg-3">
                        <input
                            id="column_1"
                            name="chk"
                            type="checkbox"
                            disabled="disabled"
                            value="1"
                            checked
                            onChange={(e) => (e) => checkBoxOnChange(e)}
                        />{" "}
                        Vendor Code
                    </div>
                    <div className="col-lg-3">
                        <input
                            id="column_2"
                            name="chk"
                            type="checkbox"
                            disabled="disabled"
                            value="2"
                            checked
                            onChange={(e) => checkBoxOnChange(e)}
                        />{" "}
                        Vendor Name
                    </div>
                    <div className="col-lg-3">
                        <input
                            id="column_3"
                            name="chk"
                            type="checkbox"
                            value="7"
                            disabled="disabled"
                            checked
                            onChange={(e) => checkBoxOnChange(e)}
                        />{" "}
                        Vendor code 1
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-lg-3">
                        <input
                            id="column_4"
                            name="chk"
                            type="checkbox"
                            value="7"
                            disabled="disabled"
                            checked
                            onChange={(e) => checkBoxOnChange(e)}
                        />{" "}
                        Vendor code 2
                    </div>
                    <div className="col-lg-3">
                        <input
                            id="column_5"
                            name="chk"
                            type="checkbox"
                            disabled="disabled"
                            value="1"
                            checked
                            onChange={(e) => (e) => checkBoxOnChange(e)}
                        />{" "}
                        Vendor code 3
                    </div>
                    <div className="col-lg-3">
                        <input
                            id="column_5"
                            name="chk"
                            type="checkbox"
                            disabled="disabled"
                            value="2"
                            checked
                            onChange={(e) => checkBoxOnChange(e)}
                        />{" "}
                        Vendor code 4
                    </div>
                    <div className="col-lg-3">
                        <input
                            id="column_6"
                            name="chk"
                            type="checkbox"
                            value="7"
                            disabled="disabled"
                            checked
                            onChange={(e) => checkBoxOnChange(e)}
                        />{" "}
                        Location
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-lg-3">
                        <input
                            id="column_7"
                            name="chk"
                            type="checkbox"
                            value="7"
                            disabled="disabled"
                            checked
                            onChange={(e) => checkBoxOnChange(e)}
                        />{" "}
                        Domain name
                    </div>
                    <div className="col-lg-3">
                        <input
                            id="column_8"
                            name="chk"
                            type="checkbox"
                            disabled="disabled"
                            value="1"
                            checked
                            onChange={(e) => (e) => checkBoxOnChange(e)}
                        />{" "}
                        Act / Rules
                    </div>
                    <div className="col-lg-3">
                        <input
                            id="column_9"
                            name="chk"
                            type="checkbox"
                            disabled="disabled"
                            value="2"
                            checked
                            onChange={(e) => checkBoxOnChange(e)}
                        />{" "}
                        Compliance Task
                    </div>
                    <div className="col-lg-3">
                        <input
                            id="column_10"
                            name="chk"
                            type="checkbox"
                            value="7"
                            disabled="disabled"
                            checked
                            onChange={(e) => checkBoxOnChange(e)}
                        />{" "}
                        Frequency
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-lg-3">
                        <input
                            id="column_11"
                            name="chk"
                            type="checkbox"
                            value="7"
                            disabled="disabled"
                            checked
                            onChange={(e) => checkBoxOnChange(e)}
                        />{" "}
                        Periodicity
                    </div>
                    <div className="col-lg-3">
                        <input
                            id="column_12"
                            name="chk"
                            type="checkbox"
                            disabled="disabled"
                            value="1"
                            checked
                            onChange={(e) => (e) => checkBoxOnChange(e)}
                        />{" "}
                        Division
                    </div>
                    <div className="col-lg-3">
                        <input
                            id="column_13"
                            name="chk"
                            type="checkbox"
                            disabled="disabled"
                            value="2"
                            checked
                            onChange={(e) => checkBoxOnChange(e)}
                        />{" "}
                        Category
                    </div>
                    <div className="col-lg-3">
                        <input
                            id="column_14"
                            name="chk"
                            type="checkbox"
                            value="7"
                            disabled="disabled"
                            checked
                            onChange={(e) => checkBoxOnChange(e)}
                        />{" "}
                        Assigned By
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-lg-3">
                        <input
                            id="column_15"
                            name="chk"
                            type="checkbox"
                            value="7"
                            disabled="disabled"
                            checked
                            onChange={(e) => checkBoxOnChange(e)}
                        />{" "}
                        Assigned To
                    </div>
                    <div className="col-lg-3">
                        <input
                            id="column_16"
                            name="chk"
                            type="checkbox"
                            disabled="disabled"
                            value="1"
                            checked
                            onChange={(e) => (e) => checkBoxOnChange(e)}
                        />{" "}
                        Assigned Date
                    </div>
                    <div className="col-lg-3">
                        <input
                            id="column_17"
                            name="chk"
                            type="checkbox"
                            disabled="disabled"
                            value="2"
                            checked
                            onChange={(e) => checkBoxOnChange(e)}
                        />{" "}
                        Assignee
                    </div>
                    <div className="col-lg-3">
                        <input
                            id="column_18"
                            name="chk"
                            type="checkbox"
                            value="7"
                            disabled="disabled"
                            checked
                            onChange={(e) => checkBoxOnChange(e)}
                        />{" "}
                        Completed On
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-lg-3">
                        <input
                            id="column_19"
                            name="chk"
                            type="checkbox"
                            value="7"
                            disabled="disabled"
                            checked
                            onChange={(e) => checkBoxOnChange(e)}
                        />{" "}
                        Concurrer
                    </div>
                    <div className="col-lg-3">
                        <input
                            id="column_20"
                            name="chk"
                            type="checkbox"
                            disabled="disabled"
                            value="1"
                            checked
                            onChange={(e) => (e) => checkBoxOnChange(e)}
                        />{" "}
                        Concurred On
                    </div>
                    <div className="col-lg-3">
                        <input
                            id="column_21"
                            name="chk"
                            type="checkbox"
                            disabled="disabled"
                            value="2"
                            checked
                            onChange={(e) => checkBoxOnChange(e)}
                        />{" "}
                        Approver
                    </div>
                    <div className="col-lg-3">
                        <input
                            id="column_22"
                            name="chk"
                            type="checkbox"
                            value="7"
                            disabled="disabled"
                            checked
                            onChange={(e) => checkBoxOnChange(e)}
                        />{" "}
                        Approved On
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-lg-3">
                        <input
                            id="column_23"
                            name="chk"
                            type="checkbox"
                            value="7"
                            disabled="disabled"
                            checked
                            onChange={(e) => checkBoxOnChange(e)}
                        />{" "}
                        Start Date
                    </div>
                    <div className="col-lg-3">
                        <input
                            id="column_24"
                            name="chk"
                            type="checkbox"
                            disabled="disabled"
                            value="1"
                            checked
                            onChange={(e) => (e) => checkBoxOnChange(e)}
                        />{" "}
                        Due Date
                    </div>
                    <div className="col-lg-3">
                        <input
                            id="column_25"
                            name="chk"
                            type="checkbox"
                            disabled="disabled"
                            value="2"
                            checked
                            onChange={(e) => checkBoxOnChange(e)}
                        />{" "}
                        Extend Due Date
                    </div>
                    <div className="col-lg-3">
                        <input
                            id="column_26"
                            name="chk"
                            type="checkbox"
                            value="7"
                            disabled="disabled"
                            checked
                            onChange={(e) => checkBoxOnChange(e)}
                        />{" "}
                        Task Completion Date / Document Issued Date
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-lg-3">
                        <input
                            id="column_27"
                            name="chk"
                            type="checkbox"
                            value="7"
                            disabled="disabled"
                            checked
                            onChange={(e) => checkBoxOnChange(e)}
                        />{" "}
                        Month
                    </div>
                    <div className="col-lg-3">
                        <input
                            id="column_28"
                            name="chk"
                            type="checkbox"
                            disabled="disabled"
                            value="1"
                            checked
                            onChange={(e) => (e) => checkBoxOnChange(e)}
                        />{" "}
                        Validity Date
                    </div>
                    <div className="col-lg-3">
                        <input
                            id="column_29"
                            name="chk"
                            type="checkbox"
                            disabled="disabled"
                            value="2"
                            checked
                            onChange={(e) => checkBoxOnChange(e)}
                        />{" "}
                        Compliance task status
                    </div>
                    <div className="col-lg-3">
                        <input
                            id="column_30"
                            name="chk"
                            type="checkbox"
                            value="7"
                            disabled="disabled"
                            checked
                            onChange={(e) => checkBoxOnChange(e)}
                        />{" "}
                        Duration
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-lg-3">
                        <input
                            id="column_31"
                            name="chk"
                            type="checkbox"
                            value="7"
                            disabled="disabled"
                            checked
                            onChange={(e) => checkBoxOnChange(e)}
                        />{" "}
                        Document Reference Number
                    </div>
                    <div className="col-lg-3">
                        <input
                            id="column_32"
                            name="chk"
                            type="checkbox"
                            disabled="disabled"
                            value="1"
                            checked
                            onChange={(e) => (e) => checkBoxOnChange(e)}
                        />{" "}
                        Remarks
                    </div>
                </div>
                <br />
                <div className="form-actions popupbtncolour">
                    <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')}
                        style={{ marginLeft: '40%' }}
                        icon={<PlayCircleOutlined />} size='default' onClick={handleOk}> Submit
                    </Button>
                </div>
            </Modal>
            <div className="container-fluid pt-1">
                <div className="row">
                    <div className="col-12">
                        <div className="card-body" style={{ position: 'relative', padding: "0px 5px" }}>
                            <div className={localStorage.getItem('currentTheme')}>
                                <Collapse defaultActiveKey={["1"]} onChange={onChange} style={{ display: exitCollapse ? "none" : "block" }} >
                                    <Panel header="Vendor Report" key={key}>
                                        <div className='col-md-12'>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <label><b>Country</b> <span style={{ color: "red" }}>*</span></label>
                                                    <div className="form-group">
                                                        {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                            <div className="form-group">
                                                                <Select
                                                                    placeholder='Enter Country'
                                                                    allowClear={true}
                                                                    size="default"
                                                                    onChange={(value, data) => {
                                                                        setUnitData({
                                                                            ...unitdata,
                                                                            country: value,
                                                                            countryName: data.children
                                                                        })
                                                                    }}
                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                    //value={Settings.le_id}
                                                                    style={{ width: '100%', marginTop: '5px' }}

                                                                    value={unitdata.countryName || undefined}
                                                                >
                                                                    {country_info && country_info.length && country_info.map((item, i) => {
                                                                        return (
                                                                            <Option key={item.c_id}>
                                                                                {item.c_name}
                                                                            </Option>
                                                                        );
                                                                    })}
                                                                </Select>
                                                                {validator.current.message(
                                                                    'countryName',
                                                                    unitdata.countryName,
                                                                    'required',
                                                                    {
                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                        messages: {
                                                                            required: 'Country Required',
                                                                        }
                                                                    })}
                                                            </div> :
                                                            <p>{sessionArr[0].c_name}</p>
                                                        }
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label style={{ "fontWeight": "bold" }}>Legal Entity <span style={{ color: "red" }}>*</span></label>
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                placeholder="Enter Legal Entity"
                                                                onChange={(data, value) => {
                                                                    Setlegalstate(true)
                                                                    setCurrentEntity(data)
                                                                    setUnitData({
                                                                        ...unitdata,
                                                                        legal_entity_id: data,
                                                                        legal_entity_name: value.children
                                                                    })
                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                style={{ width: '100%', marginTop: '5px' }}

                                                                value={unitdata.legal_entity_name || undefined}
                                                                disabled={unitdata.countryName == '' ? true : false}
                                                            >
                                                                {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.le_id}>
                                                                            {item.le_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                            {validator.current.message(
                                                                'legal_entity_id',
                                                                unitdata.legal_entity_name,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Legal Entity Required ',
                                                                    }
                                                                })}
                                                        </div> :
                                                        <p>{localStorage.getItem('SelectedEntity')}</p>
                                                    }
                                                </div>
                                                <div className='col-md-4'>
                                                    <label style={{ "fontWeight": "bold" }}>Vendor <span style={{ color: "red" }}>*</span></label>
                                                    {/* <Select
                                                        allowClear={false}
                                                        disabled={data1.domain != "" && data1.timezone == "" ? false : true}
                                                        size="default"
                                                        placeholder="Enter TimeZone"
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        value={data1.domain ? unitlist && unitlist.timezone && unitlist.timezone[0] && unitlist.timezone[0].tz_name : ""}
                                                        style={{ width: '300px' }}
                                                    >

                                                    </Select> */}
                                                    <Select
                                                        allowClear={true}
                                                        value={unitdata.unit_name || undefined}
                                                        onChange={(value, data) => {
                                                            let user = _.filter(unitreport.compliance_users, { unit_id: Number(value) })
                                                            setUsers(user)
                                                            setUnitData({
                                                                ...unitdata,
                                                                unit: value,
                                                                unit_name: data.children

                                                            })
                                                        }}
                                                        placeholder="Enter Vendor"
                                                        disabled={entityid == '' ? true : false}
                                                        style={{ width: '100%', marginTop: '5px' }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                                                    >
                                                        {unit && unit.length > 0 && unit.map((item, i) => {
                                                            return (
                                                                <Option key={item.unit_id}>
                                                                    {`${item.unit_code}-${item.unit_name}`}
                                                                </Option>
                                                            )
                                                        })}
                                                    </Select>
                                                    {validator.current.message(
                                                        'unit',
                                                        unitdata.unit,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Vendor Required',
                                                            }
                                                        })}
                                                </div>

                                            </div>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: "10px" }}>
                                            <div className='row'>

                                                <div className='col-md-4'>
                                                    <label><b>From Date :  </b> <span style={{ color: "red" }}>*</span></label>
                                                    <div className="form-group">
                                                        <DatePicker allowClear={true} format={'DD-MMM-YYYY'} placeholder='DD-MMM-YYYY' style={{ width: '100%', marginTop: '5px' }}
                                                            value={unitdata.from_date ? moment(unitdata.from_date) : ''}
                                                            onChange={(date, dateString) => {
                                                                setUnitData({
                                                                    ...unitdata,
                                                                    from_date: dateString,
                                                                    to_date: moment(dateString).add(3, 'months')
                                                                })
                                                            }}
                                                        // disabled={consolidateddata.legel_entity == '' ? true : false}



                                                        />
                                                    </div>
                                                    {validator.current.message(
                                                        'from_date',
                                                        unitdata.from_date,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'From Date Required',
                                                            }
                                                        })}
                                                </div>

                                                <div className='col-md-4'>
                                                    <label><b>To Date : </b>  <span style={{ color: "red" }}>*</span></label>
                                                    <div className="form-group">
                                                        <DatePicker allowClear={true} format={'DD-MMM-YYYY'} placeholder='DD-MMM-YYYY' style={{ width: '100%', marginTop: '5px' }}
                                                            value={unitdata.to_date || undefined}
                                                            onChange={(date, dateString) => {
                                                                disablecondition(dateString)
                                                                setUnitData({
                                                                    ...unitdata,
                                                                    to_date: dateString._i
                                                                })
                                                            }}
                                                            disabledDate={(current) => {
                                                                return moment(unitdata.from_date).add(1, 'days') >= current ||
                                                                    moment(unitdata.from_date).add(1, 'year') < current;
                                                            }}
                                                        // disabled={consolidateddata.legel_entity == '' ? true : false}


                                                        />
                                                    </div>
                                                    {validator.current.message(
                                                        'to_date',
                                                        unitdata.to_date,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'To Date Required ',
                                                            }
                                                        })}
                                                </div>
                                                <div className='col-md-4'>
                                                    <label style={{ "fontWeight": "bold" }}>Act</label>
                                                    <Select
                                                        value={unitdata.act_name || undefined}
                                                        disabled={unitdata.unit != "" ? false : true}
                                                        allowClear={true}
                                                        // onClick={() => {
                                                        //     if (unitdata.unit == '') {
                                                        //         toast.warning("Domain Required")
                                                        //     }
                                                        // }}
                                                        onChange={(value, data) => {
                                                            setUnitData({
                                                                ...unitdata,
                                                                act: value,
                                                                act_name: data.children
                                                            })
                                                        }}
                                                        placeholder="Enter Act"
                                                        style={{ width: '100%', marginTop: '5px' }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                                                    >
                                                        {act && act.length > 0 && act.map((item, i) => {
                                                            return (
                                                                <Option key={item.parent_id}>
                                                                    {item.statutory_mapping}
                                                                </Option>
                                                            )
                                                        })}
                                                    </Select>
                                                </div>

                                            </div>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: "10px" }}>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <label style={{ "fontWeight": "bold" }}>Domain</label>
                                                    <Select
                                                        value={unitdata.domain_name || undefined}
                                                        allowClear={true}
                                                        disabled={unitdata.unit != "" ? false : true}
                                                        onChange={(value, data) => {
                                                            let act = _.filter(unitreport.act_legal_entity, { domain_id: Number(value) })
                                                            setAct(act)
                                                            setUnitData({
                                                                ...unitdata,
                                                                domain: value,
                                                                domain_name: data.children
                                                            })
                                                        }}
                                                        placeholder="Enter Domain Name"
                                                        style={{ width: '100%', marginTop: '5px' }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                                                    >
                                                        {domain && domain.length > 0 && domain.map((item, i) => {
                                                            return (
                                                                <Option key={item.d_id}>
                                                                    {item.d_name}
                                                                </Option>
                                                            )
                                                        })}
                                                    </Select>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label style={{ "fontWeight": "bold" }}>Division</label>
                                                    <Select
                                                        value={unitdata.division_name || undefined}
                                                        allowClear={true}
                                                        onChange={(value, data) => {
                                                            let category = _.filter(unitreport.categories_list, { div_id: Number(value) })
                                                            let unit = _.filter(unitreport.unit_legal_entity, { division_id: Number(value) })
                                                            setCategoryList(category)
                                                            setUnit(unit)
                                                            setUnitData({
                                                                ...unitdata,
                                                                division: value,
                                                                division_name: data.children
                                                            })
                                                        }}
                                                        placeholder="Enter Division Name"
                                                        style={{ width: '100%', marginTop: '5px' }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    >
                                                        {unitreport && unitreport.divisions_list && unitreport.divisions_list.map((item, i) => {
                                                            return (
                                                                <Option key={item.div_id}>
                                                                    {item.div_name}
                                                                </Option>
                                                            )
                                                        })}
                                                    </Select>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label style={{ "fontWeight": "bold" }}>Compliance Task</label>

                                                    <Input placeholder="Enter Compliance Task"
                                                        style={{ width: '100%', marginTop: '5px' }}

                                                        value={unitdata.compliance_task || undefined}
                                                        onChange={(e) => {
                                                            setUnitData({
                                                                ...unitdata,
                                                                compliance_task: e.target.value
                                                            })
                                                        }}
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: "10px" }}>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <label style={{ "fontWeight": "bold" }}>Category</label>
                                                    <Select
                                                        allowClear={true}
                                                        value={unitdata.category_name || undefined}
                                                        onChange={(value, data) => {
                                                            let unit = _.filter(unitreport.unit_legal_entity, { category_id: Number(value) })
                                                            setUnit(unit)
                                                            setUnitData({
                                                                ...unitdata,
                                                                category: value,
                                                                category_name: data.children
                                                            })
                                                        }}
                                                        placeholder="Enter Category Name"
                                                        style={{ width: '100%', marginTop: '5px' }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                                                    >
                                                        {categoryList && categoryList.length > 0 && categoryList.map((item, i) => {
                                                            return (
                                                                <Option key={item.cat_id}>
                                                                    {item.cat_name}
                                                                </Option>
                                                            )
                                                        })}
                                                    </Select>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label style={{ "fontWeight": "bold" }}>Compliance Frequency</label>
                                                    <Select
                                                        allowClear={true}
                                                        value={unitdata.compliance_frequency_name || undefined}
                                                        defaultValue={"All"}
                                                        onChange={(value, data) => {
                                                            setUnitData({
                                                                ...unitdata,
                                                                compliance_frequency: value,
                                                                compliance_frequency_name: data.children
                                                            })
                                                        }}
                                                        // placeholder="Enter Domain Name"
                                                        style={{ width: '100%', marginTop: '5px' }}

                                                    >
                                                        <Option key={0}>All</Option>
                                                        {unitreport && unitreport.compliance_frequency_list && unitreport.compliance_frequency_list.map((item, i) => {
                                                            return (
                                                                <Option key={item.frequency_id}>
                                                                    {item.frequency_name}
                                                                </Option>
                                                            )
                                                        })}
                                                    </Select>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label style={{ "fontWeight": "bold" }}>Compliance Task Status</label>
                                                    <Select
                                                        allowClear={true}
                                                        value={unitdata.compliance_task_status_name || undefined}
                                                        defaultValue={"All"}
                                                        onChange={(value, data) => {
                                                            setUnitData({
                                                                ...unitdata,
                                                                compliance_task_status: value,
                                                                compliance_task_status_name: data.children
                                                            })
                                                        }}
                                                        // placeholder="Enter Domain Name"
                                                        style={{ width: '100%', marginTop: '5px' }}

                                                    >
                                                        <Option key='All'>All</Option>
                                                        {unitreport && unitreport.compliance_task_status && unitreport.compliance_task_status.map((item, i) => {
                                                            return (
                                                                <Option key={item.task_status_id}>
                                                                    {item.task_status}
                                                                </Option>
                                                            )
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: "10px" }}>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <label style={{ "fontWeight": "bold" }}>User</label>
                                                    <Select
                                                        allowClear={true}
                                                        value={unitdata.user_name || undefined}
                                                        disabled={unitdata.unit != "" ? false : true}
                                                        onChange={(value, data) => {
                                                            setUnitData({
                                                                ...unitdata,
                                                                user: value,
                                                                user_name: data.children
                                                            })
                                                        }}
                                                        placeholder="Enter User"
                                                        style={{ width: '100%', marginTop: '5px' }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                                                    >
                                                        {users && users.length > 0 && users.map((item, i) => {
                                                            return (
                                                                <Option key={item.cat_id}>
                                                                    {item.assignee_name}
                                                                </Option>
                                                            )
                                                        })}
                                                    </Select>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label style={{ "fontWeight": "bold" }}>User Type</label>
                                                    <Select
                                                        allowClear={true}
                                                        value={unitdata.user_type_name || undefined}
                                                        defaultValue={"All"}
                                                        onChange={(value, data) => {
                                                            setUnitData({
                                                                ...unitdata,
                                                                user_type: value,
                                                                user_type_name: data.children
                                                            })
                                                        }}
                                                        style={{ width: '100%', marginTop: '5px' }}

                                                    >
                                                        <Option key='All'>All</Option>
                                                        {usertype && usertype.map((item, i) => {
                                                            return (
                                                                <Option key={item.user_type_id}>
                                                                    {item.user_type}
                                                                </Option>
                                                            )
                                                        })}
                                                    </Select>
                                                </div>
                                                <div className='col-md-4'>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: "10px" }}>
                                            <div className='row'>
                                                <div className='col-md-4'></div>
                                                <div className='col-md-4 text-center popupbtncolour'>
                                                    <Button type="primary" shape="round" icon={<EyeOutlined />} className={'addbutton ' + localStorage.getItem('currentTheme')}
                                                        style={{ margin: '2px' }}
                                                        onClick={onshow} disabled={disableButton}
                                                    >
                                                        Show
                                                    </Button>
                                                    <Button type="primary" shape="round" icon={<ExportOutlined />} className={'addbutton ' + localStorage.getItem('currentTheme')}
                                                        style={{ margin: '2px' }}
                                                        onClick={showModal}
                                                    >
                                                        Export
                                                    </Button>
                                                    {/* <button className="btn btn-secondary" style={{ marginLeft: "59px", marginRight: "10px", width: "100px" }} onClick={onshow}>Show</button>
                                                        <button className="btn btn-success" style={{ width: "100px" }} >Export</button> */}
                                                </div>
                                                <div className='col-md-4'></div>
                                            </div>
                                        </div>
                                    </Panel>
                                </Collapse>
                                <Modal
                                    onCancel={setcancelFilter}
                                    footer={false}
                                    title="Vendor Report"
                                    className={
                                        "add-service-prv cons-report " +
                                        localStorage.getItem("currentTheme")
                                    }
                                    visible={filterModalVisible}
                                >
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Country</b> <span style={{ color: "red" }}>*</span></label>
                                                <div className="form-group">
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                        <div className="form-group">
                                                            <Select
                                                                placeholder='Enter Country'
                                                                allowClear={true}
                                                                size="default"
                                                                onChange={(value, data) => {
                                                                    setUnitData({
                                                                        ...unitdata,
                                                                        country: value,
                                                                        countryName: data.children
                                                                    })
                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                //value={Settings.le_id}
                                                                style={{ width: '100%', marginTop: '5px' }}

                                                                value={unitdata.countryName || undefined}
                                                            >
                                                                {country_info && country_info.length && country_info.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.c_id}>
                                                                            {item.c_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                            {validator.current.message(
                                                                'countryName',
                                                                unitdata.countryName,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Country Required',
                                                                    }
                                                                })}
                                                        </div> :
                                                        <p>{sessionArr[0].c_name}</p>
                                                    }
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <label style={{ "fontWeight": "bold" }}>Legal Entity <span style={{ color: "red" }}>*</span></label>
                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Enter Legal Entity"
                                                            onChange={(data, value) => {
                                                                Setlegalstate(true)
                                                                setCurrentEntity(data)
                                                                setUnitData({
                                                                    ...unitdata,
                                                                    legal_entity_id: data,
                                                                    legal_entity_name: value.children
                                                                })
                                                            }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%', marginTop: '5px' }}

                                                            value={unitdata.legal_entity_name || undefined}
                                                            disabled={unitdata.countryName == '' ? true : false}
                                                        >
                                                            {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                                return (
                                                                    <Option key={item.le_id}>
                                                                        {item.le_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {validator.current.message(
                                                            'legal_entity_id',
                                                            unitdata.legal_entity_name,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Legal Entity Required ',
                                                                }
                                                            })}
                                                    </div> :
                                                    <p>{localStorage.getItem('SelectedEntity')}</p>
                                                }
                                            </div>
                                            <div className='col-md-4'>
                                                <label style={{ "fontWeight": "bold" }}>Vendor <span style={{ color: "red" }}>*</span></label>
                                                {/* <Select
                                                        allowClear={false}
                                                        disabled={data1.domain != "" && data1.timezone == "" ? false : true}
                                                        size="default"
                                                        placeholder="Enter TimeZone"
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        value={data1.domain ? unitlist && unitlist.timezone && unitlist.timezone[0] && unitlist.timezone[0].tz_name : ""}
                                                        style={{ width: '300px' }}
                                                    >

                                                    </Select> */}
                                                <Select
                                                    allowClear={true}
                                                    value={unitdata.unit_name || undefined}
                                                    onChange={(value, data) => {
                                                        let user = _.filter(unitreport.compliance_users, { unit_id: Number(value) })
                                                        setUsers(user)
                                                        setUnitData({
                                                            ...unitdata,
                                                            unit: value,
                                                            unit_name: data.children

                                                        })
                                                    }}
                                                    placeholder="Enter Vendor"
                                                    disabled={entityid == '' ? true : false}
                                                    style={{ width: '100%', marginTop: '5px' }}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                                                >
                                                    {unit && unit.length > 0 && unit.map((item, i) => {
                                                        return (
                                                            <Option key={item.unit_id}>
                                                                {`${item.unit_code}-${item.unit_name}`}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                                {validator.current.message(
                                                    'unit',
                                                    unitdata.unit,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Vendor Required',
                                                        }
                                                    })}
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>

                                            <div className='col-md-4'>
                                                <label><b>From Date :  </b> <span style={{ color: "red" }}>*</span></label>
                                                <div className="form-group">
                                                    <DatePicker allowClear={true} format={'DD-MMM-YYYY'} placeholder='DD-MMM-YYYY' style={{ width: '100%', marginTop: '5px' }}
                                                        value={unitdata.from_date ? moment(unitdata.from_date) : ''}
                                                        onChange={(date, dateString) => {
                                                            setUnitData({
                                                                ...unitdata,
                                                                from_date: dateString,
                                                                to_date: moment(dateString).add(3, 'months')
                                                            })
                                                        }}
                                                    // disabled={consolidateddata.legel_entity == '' ? true : false}



                                                    />
                                                </div>
                                                {validator.current.message(
                                                    'from_date',
                                                    unitdata.from_date,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'From Date Required',
                                                        }
                                                    })}
                                            </div>

                                            <div className='col-md-4'>
                                                <label><b>To Date : </b>  <span style={{ color: "red" }}>*</span></label>
                                                <div className="form-group">
                                                    <DatePicker allowClear={true} format={'DD-MMM-YYYY'} placeholder='DD-MMM-YYYY' style={{ width: '100%', marginTop: '5px' }}
                                                        value={unitdata.to_date || undefined}
                                                        onChange={(date, dateString) => {
                                                            disablecondition(dateString)
                                                            setUnitData({
                                                                ...unitdata,
                                                                to_date: dateString._i
                                                            })
                                                        }}
                                                        disabledDate={(current) => {
                                                            return moment(unitdata.from_date).add(1, 'days') >= current ||
                                                                moment(unitdata.from_date).add(1, 'year') < current;
                                                        }}
                                                    // disabled={consolidateddata.legel_entity == '' ? true : false}


                                                    />
                                                </div>
                                                {validator.current.message(
                                                    'to_date',
                                                    unitdata.to_date,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'To Date Required ',
                                                        }
                                                    })}
                                            </div>
                                            <div className='col-md-4'>
                                                <label style={{ "fontWeight": "bold" }}>Act</label>
                                                <Select
                                                    value={unitdata.act_name || undefined}
                                                    disabled={unitdata.unit != "" ? false : true}
                                                    allowClear={true}
                                                    // onClick={() => {
                                                    //     if (unitdata.unit == '') {
                                                    //         toast.warning("Domain Required")
                                                    //     }
                                                    // }}
                                                    onChange={(value, data) => {
                                                        setUnitData({
                                                            ...unitdata,
                                                            act: value,
                                                            act_name: data.children
                                                        })
                                                    }}
                                                    placeholder="Enter Act"
                                                    style={{ width: '100%', marginTop: '5px' }}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                                                >
                                                    {act && act.length > 0 && act.map((item, i) => {
                                                        return (
                                                            <Option key={item.parent_id}>
                                                                {item.statutory_mapping}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label style={{ "fontWeight": "bold" }}>Domain</label>
                                                <Select
                                                    value={unitdata.domain_name || undefined}
                                                    allowClear={true}
                                                    disabled={unitdata.unit != "" ? false : true}
                                                    onChange={(value, data) => {
                                                        let act = _.filter(unitreport.act_legal_entity, { domain_id: Number(value) })
                                                        setAct(act)
                                                        setUnitData({
                                                            ...unitdata,
                                                            domain: value,
                                                            domain_name: data.children
                                                        })
                                                    }}
                                                    placeholder="Enter Domain Name"
                                                    style={{ width: '100%', marginTop: '5px' }}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                                                >
                                                    {domain && domain.length > 0 && domain.map((item, i) => {
                                                        return (
                                                            <Option key={item.d_id}>
                                                                {item.d_name}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                            <div className='col-md-4'>
                                                <label style={{ "fontWeight": "bold" }}>Division</label>
                                                <Select
                                                    value={unitdata.division_name || undefined}
                                                    allowClear={true}
                                                    onChange={(value, data) => {
                                                        let category = _.filter(unitreport.categories_list, { div_id: Number(value) })
                                                        let unit = _.filter(unitreport.unit_legal_entity, { division_id: Number(value) })
                                                        setCategoryList(category)
                                                        setUnit(unit)
                                                        setUnitData({
                                                            ...unitdata,
                                                            division: value,
                                                            division_name: data.children
                                                        })
                                                    }}
                                                    placeholder="Enter Division Name"
                                                    style={{ width: '100%', marginTop: '5px' }}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                >
                                                    {unitreport && unitreport.divisions_list && unitreport.divisions_list.map((item, i) => {
                                                        return (
                                                            <Option key={item.div_id}>
                                                                {item.div_name}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                            <div className='col-md-4'>
                                                <label style={{ "fontWeight": "bold" }}>Compliance Task</label>

                                                <Input placeholder="Enter Compliance Task"
                                                    style={{ width: '100%', marginTop: '5px' }}

                                                    value={unitdata.compliance_task || undefined}
                                                    onChange={(e) => {
                                                        setUnitData({
                                                            ...unitdata,
                                                            compliance_task: e.target.value
                                                        })
                                                    }}
                                                />
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label style={{ "fontWeight": "bold" }}>Category</label>
                                                <Select
                                                    allowClear={true}
                                                    value={unitdata.category_name || undefined}
                                                    onChange={(value, data) => {
                                                        let unit = _.filter(unitreport.unit_legal_entity, { category_id: Number(value) })
                                                        setUnit(unit)
                                                        setUnitData({
                                                            ...unitdata,
                                                            category: value,
                                                            category_name: data.children
                                                        })
                                                    }}
                                                    placeholder="Enter Category Name"
                                                    style={{ width: '100%', marginTop: '5px' }}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                                                >
                                                    {categoryList && categoryList.length > 0 && categoryList.map((item, i) => {
                                                        return (
                                                            <Option key={item.cat_id}>
                                                                {item.cat_name}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                            <div className='col-md-4'>
                                                <label style={{ "fontWeight": "bold" }}>Compliance Frequency</label>
                                                <Select
                                                    allowClear={true}
                                                    value={unitdata.compliance_frequency_name || undefined}
                                                    defaultValue={"All"}
                                                    onChange={(value, data) => {
                                                        setUnitData({
                                                            ...unitdata,
                                                            compliance_frequency: value,
                                                            compliance_frequency_name: data.children
                                                        })
                                                    }}
                                                    // placeholder="Enter Domain Name"
                                                    style={{ width: '100%', marginTop: '5px' }}

                                                >
                                                    <Option key={0}>All</Option>
                                                    {unitreport && unitreport.compliance_frequency_list && unitreport.compliance_frequency_list.map((item, i) => {
                                                        return (
                                                            <Option key={item.frequency_id}>
                                                                {item.frequency_name}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                            <div className='col-md-4'>
                                                <label style={{ "fontWeight": "bold" }}>Compliance Task Status</label>
                                                <Select
                                                    allowClear={true}
                                                    value={unitdata.compliance_task_status_name || undefined}
                                                    defaultValue={"All"}
                                                    onChange={(value, data) => {
                                                        setUnitData({
                                                            ...unitdata,
                                                            compliance_task_status: value,
                                                            compliance_task_status_name: data.children
                                                        })
                                                    }}
                                                    // placeholder="Enter Domain Name"
                                                    style={{ width: '100%', marginTop: '5px' }}

                                                >
                                                    <Option key='All'>All</Option>
                                                    {unitreport && unitreport.compliance_task_status && unitreport.compliance_task_status.map((item, i) => {
                                                        return (
                                                            <Option key={item.task_status_id}>
                                                                {item.task_status}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label style={{ "fontWeight": "bold" }}>User</label>
                                                <Select
                                                    allowClear={true}
                                                    value={unitdata.user_name || undefined}
                                                    disabled={unitdata.unit != "" ? false : true}
                                                    onChange={(value, data) => {
                                                        setUnitData({
                                                            ...unitdata,
                                                            user: value,
                                                            user_name: data.children
                                                        })
                                                    }}
                                                    placeholder="Enter User"
                                                    style={{ width: '100%', marginTop: '5px' }}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                                                >
                                                    {users && users.length > 0 && users.map((item, i) => {
                                                        return (
                                                            <Option key={item.cat_id}>
                                                                {item.assignee_name}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                            <div className='col-md-4'>
                                                <label style={{ "fontWeight": "bold" }}>User Type</label>
                                                <Select
                                                    allowClear={true}
                                                    value={unitdata.user_type_name || undefined}
                                                    defaultValue={"All"}
                                                    onChange={(value, data) => {
                                                        setUnitData({
                                                            ...unitdata,
                                                            user_type: value,
                                                            user_type_name: data.children
                                                        })
                                                    }}
                                                    style={{ width: '100%', marginTop: '5px' }}

                                                >
                                                    <Option key='All'>All</Option>
                                                    {usertype && usertype.map((item, i) => {
                                                        return (
                                                            <Option key={item.user_type_id}>
                                                                {item.user_type}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                            <div className='col-md-4'>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'></div>
                                            <div className='col-md-4 text-center popupbtncolour '>
                                                <Button type="primary" shape="round" icon={<EyeOutlined />} className={'addbutton ' + localStorage.getItem('currentTheme')}
                                                    style={{ margin: '2px' }}
                                                    onClick={onshow} disabled={disableButton}
                                                >
                                                    Show
                                                </Button>
                                                <Button type="primary" shape="round" icon={<ExportOutlined />} className={'addbutton ' + localStorage.getItem('currentTheme')}
                                                    style={{ margin: '2px' }}
                                                    onClick={showModal}
                                                >
                                                    Export
                                                </Button>
                                                {/* <button className="btn btn-secondary" style={{ marginLeft: "59px", marginRight: "10px", width: "100px" }} onClick={onshow}>Show</button>
                                                        <button className="btn btn-success" style={{ width: "100px" }} >Export</button> */}
                                            </div>
                                            <div className='col-md-4'></div>
                                        </div>
                                    </div>




                                </Modal>
                            </div>

                            {tableshow == true ?
                                <Card className="rounded-3">

                                    {/* <div className='col-md-12 mt-3 mb-3' >

                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Legal Entity  : </b>{unitdata.legal_entity_name}</label>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Country  : </b>{unitdata.countryName ? unitdata.countryName : sessionArr[0].c_name}</label>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Vendor  : </b>{unitdata.unit_name}</label>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="col-md-12 mt-2 mb-2" style={{marginLeft:'3px'}}>
                                        <div className="row">
                                            <div className="col-md-5">
                                                <label>
                                                    <b>Legal Entity : </b>
                                                    {unitdata.legal_entity_name}
                                                </label>
                                            </div>
                                            <div className="col-md-3">
                                                <label>
                                                    <b>Country : </b>
                                                    {unitdata.countryName ? unitdata.countryName : sessionArr[0].c_name}
                                                </label>
                                            </div>
                                            <div className="col-md-4">
                                                <label>
                                                    <b>Domain : </b> {unitdata.unit_name}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={localStorage.getItem('currentTheme')}>
                                        <div className='ant-table-wrapper'>
                                            <div className='ant-table ant-table-bordered ant-table-fixed-header'>
                                                <div className='ant-table-container'>
                                                    <div className='ant-table-content audittrailtable '>
                                                        <table >
                                                            <colgroup>
                                                                <col style={{ width: '10px' }}></col>
                                                            </colgroup>
                                                            <thead className="ant-table-thead antable-head">
                                                                <tr>
                                                                    <th className={fullscreen == false ? 'ant-table-cell ' : 'fullscreenclass ant-table-cell'}> # </th>
                                                                    <th title='Compliance Task' className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '290px' }} >Compliance Task</th>
                                                                    {/* <th className='ant-table-cell custom-width1'>Criticality</th> */}
                                                                    <th className={fullscreen == false ? 'ant-table-cell ' : 'fullscreenclass '} style={{ width: '70px' }} >Frequency</th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell ' : 'fullscreenclass '} style={{ width: '170px' }} ><center>Due Date</center></th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass '} style={{ width: '120px' }} >Compliance Task Status</th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass '} style={{ width: '150px' }} ><center>User Name</center></th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell ' : 'fullscreenclass '} style={{ width: '125px' }} ><center>Activity Status</center></th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell ' : 'fullscreenclass '} style={{ width: '125px' }} ><center>Activity Date</center></th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell ' : 'fullscreenclass '} style={{ width: '150px' }}  ><center>Uploaded Document</center></th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell ' : 'fullscreenclass '} style={{ width: '150px' }} ><center><Tooltip title='Task Completion Date/Document issued Date'>Date</Tooltip></center></th>
                                                                </tr>
                                                            </thead>
                                                            {filteredArrayState && filteredArrayState.length ? filteredArrayState.map((item, i) => {
                                                                return (
                                                                    <tbody className='ant-table-tbody'>
                                                                        {/* <tr className="bg-lightdark">
                                                                            <td colSpan="100%">
                                                                                <table className='white' style={{ tableLayout: 'auto' }}>
                                                                                    <tbody className='ant-table-tbody'>
                                                                                        <tr className='ant-table-row ant-table-row-level-0'>
                                                                                            <td className='ant-table-cell w6'><b>Domain :</b></td>
                                                                                            <td className='ant-table-cell w15'>{item[0].domain_name}</td>

                                                                                            <td className='ant-table-cell w6'><b>Division :</b></td>
                                                                                            <td className='ant-table-cell w15' >{item[0].division_name}</td>
                                                                                        </tr>
                                                                                        <tr className='ant-table-row ant-table-row-level-0'>
                                                                                            <td className='ant-table-cell w6'><b>Category</b></td>
                                                                                            <td className='ant-table-cell w40'><span className="category-name">{item[0].category_name}</span></td>

                                                                                            <td className='ant-table-cell w6'><b>Time Zone</b></td>
                                                                                            <td className='ant-table-cell w40'><span className="category-name">{item[0].tz_name}</span></td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </td>
                                                                        </tr> */}
                                                                        <tr className="bg-lightdark">
                                                                            <td
                                                                                style={{
                                                                                    paddingLeft: "0 !important",
                                                                                    paddingRight: "0 !important",
                                                                                }}
                                                                                colSpan="100%"
                                                                            >
                                                                                <div className="bg-white shadow-sm border-radius-reports">
                                                                                    <div className="col-md-12">
                                                                                        <div className="row m-2">
                                                                                            <div className="col-md-3">
                                                                                                <label>
                                                                                                    <b>Domain :</b>{" "}
                                                                                                    {item[0].domain_name}
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="col-md-3">
                                                                                                <label>
                                                                                                    <b>Division :</b>{" "}
                                                                                                    {item[0].division_name}
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="col-md-3">
                                                                                                <label>
                                                                                                    <b>Category :</b>{" "}
                                                                                                    {item[0].category_name}
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="col-md-3">
                                                                                                <label>
                                                                                                    <b>Time Zone :</b>{" "}
                                                                                                    {item[0].tz_name}
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>

                                                                        {titledata && titledata.map((itemdata, i) => {
                                                                            let data = _.find(itemdata.child, { unit_id: item[0].unit_id })
                                                                            if (data != undefined) {
                                                                                if (data.statutory_mapping == itemdata.parent) {
                                                                                    return <Fragment>
                                                                                        <tr className='ant-table-row ant-table-row-level-0'>
                                                                                            <td colSpan={12} className='ant-table-cell'><b>{itemdata.parent}</b></td>
                                                                                        </tr>
                                                                                        {itemdata.child.map((items) => {
                                                                                            if (item[0].unit_id == items.unit_id) {
                                                                                                return <tr>
                                                                                                    <td className='ant-table-cell'>{count + 1}</td>
                                                                                                    <td title={items.compliance_task} className='ant-table-cell'><Tooltip title={items.compliance_description}>
                                                                                                        <ExclamationCircleTwoTone />
                                                                                                    </Tooltip> &nbsp;{items.compliance_task}</td>
                                                                                                    {/* <td className='ant-table-cell'>{items.Criticality}</td> */}
                                                                                                    <td className='ant-table-cell'>{items.frequency_name}</td>
                                                                                                    <td className='ant-table-cell'><center>{items.due_date}</center></td>
                                                                                                    <td className='ant-table-cell'><center>{items.task_status}</center></td>
                                                                                                    <td className='ant-table-cell'><center>{items.assignee_name}</center></td>
                                                                                                    <td className='ant-table-cell'><center>{items.activity_status}</center></td>
                                                                                                    <td className='ant-table-cell'><center>{items.activity_date}</center></td>
                                                                                                    <td ><center>-</center></td>
                                                                                                    <td ><center>-</center></td>
                                                                                                    {/* </tr> */}
                                                                                                    <span hidden>{count = count + 1}</span>
                                                                                                </tr>
                                                                                            }
                                                                                        })
                                                                                        }
                                                                                    </Fragment>
                                                                                }
                                                                            }
                                                                        })
                                                                        }

                                                                    </tbody>
                                                                )
                                                            }) :
                                                                <tr>
                                                                    <td colSpan="100%" style={{ paddingTop: '15px', textAlign: 'center', fontSize: '14px', fontWeight: 'bold', paddingBottom: '15px' }}>No Records Found</td>
                                                                </tr>
                                                            }
                                                        </table>

                                                        <div>
                                                            {/* <button
                                                className="btn btn-light-success rounded-pill px-4 text-success"
                                                onClick={submitrec}>Submit</button> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {count != 0 ?
                                        <div className='col-md-12 mt-2' style={{ position: 'absolute', bottom: '-25px', left: 0 }}>
                                            <label style={{ marginLeft: "15px" }}>Showing {pageSize * (current - 1) + 1} to {count} of {unitwisereports && unitwisereports.total_count} entries</label>

                                        </div> : ""}
                                    <div style={{ position: 'absolute', bottom: '-25px', right: 0 }}>
                                        <Pagination
                                            current={current}
                                            showSizeChanger={true}
                                            // showQuickJumper={true}
                                            onShowSizeChange={() => {
                                                setCurrent(1)
                                                // setpageState(true)
                                            }}
                                            pageSizeOptions={[25, 50, 100]}
                                            hideOnSinglePage={true}
                                            defaultPageSize={25}
                                            onChange={(page, pageSizes) => {
                                                setpageState(true)
                                                setCurrent(pageSize !== pageSizes ? 1 : page);
                                                setPageSize(pageSizes)
                                            }}
                                            total={unitwisereports && unitwisereports.total_count}
                                        />

                                    </div>
                                    <br />

                                </Card>
                                : false}
                        </div>
                    </div>
                </div>
            </div >
        </div >

    )
}
export default connect(mapStateToProps, {
    UnitWiseReportFilters,
    UnitWiseReport,
    UnitExportData
})(VendorReports);